import { IconButton } from '@mui/material';
import styled from 'styled-components';
import { Colors } from '../../styles/Colors';

export const DeckHeaderContainer = styled.div<{ isLinksSelectVisible: boolean }>`
  display: flex;
  justify-content: ${({ isLinksSelectVisible }) => (isLinksSelectVisible ? 'space-between' : 'end')};
  align-items: center;
  z-index: 99999;
  padding: 0 80px;
`;

export const ReturnButton = styled(IconButton)`
  &.MuiIconButton-root {
    background: ${Colors.primary.light};
    color: #ffffff;

    &:hover {
      background: ${Colors.primary.dark};
    }
  }
`;
