import { SubdirectoryArrowRight, WorkOutline } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { baseApi } from '../../../../../app/baseApi';
import { setCurrentCompany } from '../../../../../app/company/slice';
import { useAppDispatch } from '../../../../../app/hooks';
import { NotificationTypeMap } from '../../../../../constants';
import { formatDate } from '../../../../../helpers/date';
import { Notification, Company, NotificationTypeEnum } from '../../../../../types';
import { cutString } from '../../../../../utils/CutString';

interface INotificationItemProps extends Notification {
  companies: Company[];
}

export const NotificationItem = ({
  title,
  body,
  sent_time,
  notification_type,
  link,
  job_id,
  company_id,
  companies,
}: INotificationItemProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const notificationCompany = companies.find((company) => company.id === company_id);

  const clickNotificationHandler = () => {
    if (notificationCompany) {
      dispatch(setCurrentCompany(notificationCompany));
      notification_type === NotificationTypeEnum.Archive ? navigate(`${link!}?id=${job_id}`) : navigate(link!);
    }
  };

  return (
    <div className="nk-notification-item" style={{ cursor: 'pointer' }} onClick={clickNotificationHandler}>
      <div className="nk-notification-icon icon-circle bg-warning-dim">
        {NotificationTypeMap[notification_type!] || <SubdirectoryArrowRight fontSize="small" color="warning" />}
      </div>
      <div className="nk-notification-content">
        <div className="nk-notification-text">{title}</div>
        <div className="nk-notification-text">{body}</div>
        <div className="d-flex">
          {sent_time && (
            <div className="nk-notification-time me-1">
              {formatDate({ date: new Date(sent_time + 'Z'), dateFormat: 'MMM dd, yyyy, H:mm' })}
            </div>
          )}
          <div className="nk-notification-time">
            <WorkOutline fontSize="inherit" style={{ marginBottom: '2px' }} />{' '}
            {cutString(18, notificationCompany?.title)}
          </div>
        </div>
      </div>
    </div>
  );
};
