import React from 'react';
import { CompanyInner } from '../../components/CompanyInner';
import { Layout } from '../../components/Layout';
import Head from '../../layout/head/Head';

export const Company = () => {
  return (
    <Layout>
      <Head title="Company" />
      <CompanyInner />
    </Layout>
  );
};
