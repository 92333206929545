import { UUID } from '../../../../../../../types/types/UUID';
import { AddJobComment, JobCommentsList } from './components';

export const JobComments = ({ id }: { id: UUID }) => {
  return (
    <>
      <AddJobComment id={id} />
      <JobCommentsList id={id} />
    </>
  );
};
