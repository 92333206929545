import { Modal } from 'reactstrap';
import { useToggle } from '../../../../../../../hooks';
import { Job } from '../../../../../../../types';
import { Button } from '../../../../../../Component';
import { useConfirmWonStatus } from '../../hooks';
import { JobLostReasonModal } from '../JobLostReasonModal';

type JobStatusProps = {
  job: Job;
  jobs?: Record<string, Job[] | []>;
  setJobs?: React.Dispatch<React.SetStateAction<Record<string, Job[] | []>>>;
};
export const JobStatus = ({ job, jobs, setJobs }: JobStatusProps) => {
  const { handleWonStatus } = useConfirmWonStatus({ job, jobs, setJobs });
  const [isLostReasonModalOpen, toggleLostReasonModalOpen] = useToggle(false);

  return (
    <>
      <div className="d-flex justify-content-lg-end">
        <Button color="success" className="mx-1 mx-lg-2" type="button" onClick={handleWonStatus}>
          Won
        </Button>
        <Button color="danger" type="button" onClick={toggleLostReasonModalOpen}>
          Lost
        </Button>
      </div>
      <Modal size="md" isOpen={isLostReasonModalOpen} toggle={toggleLostReasonModalOpen}>
        <JobLostReasonModal toggle={toggleLostReasonModalOpen} job={job} jobs={jobs} setJobs={setJobs} />
      </Modal>
    </>
  );
};
