import { UUID } from '../types/types/UUID';

export const API_ENDPOINTS = {
  AUTH: {
    ACTIVE: '/user/activate',
    LOGIN: '/user/login/',
    REFRESH_TOKEN: '/user/login/refresh-token/',
    REGISTER: '/user/register',
    FORGOT_PASSWORD: '/user/start-reset-password',
    RESET_PASSWORD: '/user/finish-reset-password',
    LOGOUT: '/user/logout/',
  },
  JOBS: {
    JOBS: '/jobs/jobs',
    ADD_JOB: '/jobs/job_from_url/',
    LIST: (links: string) => `/jobs/list/${links}`,
    ID: (id: UUID) => `/jobs/jobs/${id}`,
  },
  LOST_REASON: {
    CREATE: 'jobs/lost-reasons',
    UPDATE: (id: UUID) => `jobs/lost-reasons/${id}`,
  },
  COMMENT: {
    CREATE: 'jobs/comments',
    ID: (id: UUID) => `jobs/${id}/comments`,
  },
  HISTORY: {
    ID: (id: UUID) => `jobs/history/${id}`,
  },
  USER: {
    USERS: '/user/',
    LIST: '/user/',
    PROFILE: '/user/my-profile',
    UPDATE: (id: UUID) => `user/${id}`,
    INVITE: '/user/invite',
    DELETE: (id: UUID) => `user/${id}`,
  },
  NOTIFICATION: {
    CREATE: '/fcm/devices/',
  },
  COMPANIES: {
    COMPANIES: '/companies/companies',
    ID: (id: string) => `/companies/companies/${id}`,
    LINKS: '/companies/rss_links',
    LINKS_ID: (id: string) => `/companies/rss_links/${id}`,
  },
  UPWORK: {
    LINK: '/upworkapi/auth-link/',
    CALLBACK: '/upworkapi/callback',
    DISCONNECT: '/upworkapi/disconnect',
    JOB_ID: (id: string) => `/upworkapi/job/${id}`,
  },
  OPENAI: {
    LETTER: (id: string) => `openai/generate_cover_letter/${id}`,
  },
  SUBSCRIPTION: {
    PLANS: 'subscription/plans',
    SUBSCRIPTIONS: 'subscription/subscriptions',
  },
  STATS: {
    LOST_REASON: 'stats/reports/lost-reason',
    LOST_REASON_BY_USER: 'stats/reports/lost-reason-by-user',
    MOST_WON_LOST: 'stats/reports/most-won-most-lost',
    STATUS_BY_USER: 'stats/reports/status-changes-by-user',
    WON_LOST: 'stats/reports/won-lost',
  },
};
