import { animated } from 'react-spring';
import { Button } from 'reactstrap';
import styled from 'styled-components';
import { Colors } from '../../styles/Colors';

export const StyledProButton = styled(Button)`
  position: relative;
  outline: none;
  border: none;
  color: ${Colors.black[800]};
  font-weight: bold;
  background-color: ${Colors.gold};
  padding: 10px 20px;

  &:hover {
    color: ${Colors.black[800]};
    background-color: ${Colors.gold};
  }
`;

export const Blink = styled(animated.div)`
  position: absolute;
  height: 120%;
  width: 40px;
  background: ${`linear-gradient(295deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.5) 43%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0.5) 58%, rgba(255, 255, 255, 0) 75%)`};
`;
