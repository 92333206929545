import React, { FC } from 'react';
import { Button } from 'reactstrap';
import styled from 'styled-components';

interface IUpworkButton extends React.ComponentPropsWithoutRef<'a'> {
  link: string;
}

const StyledButton = styled(Button)`
  background: #14a800;
  border-color: #14a800;
  white-space: nowrap;
`;

export const UpworkButton: FC<IUpworkButton> = ({ link }) => {
  return <StyledButton onClick={() => window.open(link, '_blank')}>Go to Upwork</StyledButton>;
};
