import classNames from 'classnames';
import React from 'react';

type IconProps = {
  name: string;
  id?: string;
  className?: string;
};

const Icon = ({ name, id, className }: IconProps) => {
  const iconClass = classNames({
    [`${className}`]: className,
    icon: true,
    ni: true,
    [`ni-${name}`]: true,
  });
  return <em className={iconClass} id={id} />;
};
export default Icon;
