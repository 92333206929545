import React from 'react';
import { Bar } from 'react-chartjs-2';
import { useGetLostReasonReportQuery } from '../../../app/stats/api';
import { BlockTitle } from '../../../theme-components/Component';
import { Loader } from '../../Loader';

export const LostReasonReport = () => {
  const { data, isLoading, isError } = useGetLostReasonReportQuery();

  const preparedData = {
    labels: data?.by_reason?.map(({ reason }) => reason),

    datasets: [
      {
        label: 'Jobs lost',
        data: data?.by_reason?.map(({ count }) => count),
        backgroundColor: '#7f8dff',
      },
    ],
  };

  let content;

  if (data && preparedData) {
    content = data.by_reason.some(({ count }) => !!count) ? (
      <Bar
        data={preparedData}
        options={{
          legend: {
            display: false,
            labels: {
              boxWidth: 30,
              padding: 20,
              fontColor: '#6783b8',
            },
          },
          tooltips: {
            enabled: true,
            backgroundColor: '#eff6ff',
            titleFontSize: 13,
            titleFontColor: '#6783b8',
            titleMarginBottom: 6,
            bodyFontColor: '#9eaecf',
            bodyFontSize: 12,
            bodySpacing: 4,
            yPadding: 10,
            xPadding: 10,
            footerMarginTop: 0,
            displayColors: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  reverse: false,
                  stepSize: 1,
                  min: 0,
                },
              },
            ],
          },
        }}
      />
    ) : (
      <div className="text-center my-2 mx-4">Sorry, there is not enough data for current company to report</div>
    );
  }

  if (isError) {
    content = (
      <div className="text-center mt-2 mb-2">The error occurred while loading report. Please refresh page later</div>
    );
  }

  if (isLoading) {
    content = (
      <div className="mt-2 mb-2">
        <Loader className="h-100" />;
      </div>
    );
  }

  return (
    <div className="card card-bordered card-preview mt-2">
      <div className="card-inner">
        <BlockTitle>Lost reason report</BlockTitle>
      </div>

      {content}
    </div>
  );
};
