import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useActivateProfileMutation } from '../../../../app/auth/api';

export const useProfileActivation = ({ setFeedbackMessage }) => {
  const [activateProfile, { isLoading }] = useActivateProfileMutation();
  const [params, setSearchParams] = useSearchParams();

  const handleProfileActivation = useCallback(
    async (token) => {
      try {
        const response = await activateProfile({ token });
        setSearchParams();
        if (response?.error?.status === 400) {
          throw response.error.data.code;
        }
        setFeedbackMessage({
          type: 'success',
          value: 'Your account was successfully activated!',
        });
      } catch (error) {
        const errorMessage = {
          invalid_token: 'Token is invalid',
          token_timeout: 'Token is invalid',
          user_is_active: 'User is active',
        }[error];
        setFeedbackMessage({
          type: 'error',
          value: errorMessage,
        });
      }
    },
    [activateProfile, setFeedbackMessage],
  );

  useEffect(() => {
    const token = params.get('token');
    if (token) {
      handleProfileActivation(token);
    }
  }, []);

  return {
    isLoadingActivateProfile: isLoading,
  };
};
