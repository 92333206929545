import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { isUserAuthenticatedSelector } from '../../app/auth/selectors';
import { ROUTES } from '../routes';

export const PrivateRoute = ({ element }: { element: JSX.Element }) => {
  const isUserAuthenticated = useSelector(isUserAuthenticatedSelector);
  return isUserAuthenticated ? element : <Navigate to={ROUTES.AUTH.LOGIN} />;
};
