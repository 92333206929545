export const ROUTE_CONSTRUCTOR = {
  AUTH: {
    index: 'auth',
    REGISTER: 'register',
    SUCCESS: 'success',
    LOGIN: 'login',
    FORGOT_PASSWORD: 'forgot-password',
    RESET_PASSWORD: 'reset-password',
  },
  PROFILE: {
    index: 'profile',
    PERSONAL_INFORMATION: 'personal-information',
    NOTIFICATIONS: 'notifications',
    SECURITY_SETTINGS: 'security-settings',
  },
};

export const ROUTES = {
  AUTH: {
    REGISTER: '/auth/register',
    SUCCESS: '/auth/success',
    LOGIN: '/auth/login',
    FORGOT_PASSWORD: '/auth/forgot-password',
  },
  KANBAN: '/kanban',
  SWIPER: '/swiper',
  PROFILE: {
    PERSONAL_INFORMATION: '/profile/personal-information',
    NOTIFICATIONS: '/profile/notifications',
    SECURITY_SETTINGS: '/profile/security-settings',
  },
  COMPANIES: '/company',
  ARCHIVE: '/archive',
  PRICING: '/pricing',
  STATISTICS: '/statistics',
};
