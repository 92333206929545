import { Menu, SubdirectoryArrowRight, WorkOutline } from '@mui/icons-material';
import { useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card } from 'reactstrap';
import { baseApi } from '../../../../app/baseApi';
import { useGetCompaniesQuery } from '../../../../app/company/api';
import { setCurrentCompany } from '../../../../app/company/slice';
import { notificationsSelector } from '../../../../app/notifications/selectors';
import { readAllNotifications } from '../../../../app/notifications/slice';
import { Loader, PaginationSettings } from '../../../../components';
import { NotificationTypeMap } from '../../../../constants';
import { formatDate } from '../../../../helpers/date';
import { usePagination } from '../../../../hooks';
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  PaginationComponent,
} from '../../../../theme-components/Component';
import { NotificationTypeEnum } from '../../../../types';
import { cutString } from '../../../../utils/CutString';
import { UserProfileContext } from '../../UserProfileContext';

export const UserNotifications = () => {
  const { isProfileSidebarOpen, toggleProfileSidebarOpen } = useContext(UserProfileContext);
  const { data: companies, isLoading } = useGetCompaniesQuery();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    itemsPerPage: notificationsPerPage,
    setItemsPerPage: setNotificationsPerPage,
    currentPage,
    paginate,
  } = usePagination({
    initialItemsCountPerPage: 10,
  });

  const notifications = useSelector(notificationsSelector);

  const offset = (currentPage - 1) * notificationsPerPage;

  const paginatedNotifications = useMemo(
    () => [...notifications].reverse().slice(offset, offset + notificationsPerPage),
    [notifications, offset, notificationsPerPage],
  );

  const handleMarkAllAsRead = () => {
    dispatch(readAllNotifications());
  };

  if (isLoading) return <Loader />;

  return (
    <>
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Notifications</BlockTitle>
            {notifications.length > 0 && (
              <Button color="primary" onClick={handleMarkAllAsRead}>
                Mark all as Read
              </Button>
            )}
          </BlockHeadContent>
          <BlockHeadContent className="align-self-start d-lg-none">
            <Button
              className={`toggle btn btn-icon btn-trigger mt-n1 ${isProfileSidebarOpen ? 'active' : ''}`}
              onClick={toggleProfileSidebarOpen}
            >
              <Menu fontSize="small" className="mx-1" />
            </Button>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      {notifications.length > 0 ? (
        <Block>
          <Card className="card-bordered card-full">
            <ul className="nk-activity">
              {paginatedNotifications.map(
                ({ title, body, sent_time, notification_type, link, job_id, company_id }, index) => {
                  const notificationCompany = companies?.find((company) => company.id === company_id);

                  return (
                    <li
                      className="nk-activity-item"
                      style={{ cursor: 'pointer' }}
                      key={index}
                      onClick={() => {
                        if (notification_type === NotificationTypeEnum.Letter) {
                          baseApi.util.invalidateTags([{ type: 'JobById', id: job_id }]);
                        }

                        if (notificationCompany) {
                          dispatch(setCurrentCompany(notificationCompany));
                          notification_type === NotificationTypeEnum.Archive
                            ? navigate(`${link!}?id=${job_id}`)
                            : navigate(link!);
                        }
                      }}
                    >
                      <div className="nk-notification-icon icon-circle bg-warning-dim">
                        {NotificationTypeMap[notification_type!] || (
                          <SubdirectoryArrowRight fontSize="small" color="warning" />
                        )}
                      </div>
                      <div className="nk-activity-data">
                        <div className="fw-bold">{title}</div>
                        <div className="label">{body}</div>
                        <div className="d-flex">
                          {sent_time && (
                            <div className="time me-1">
                              {formatDate({ date: new Date(sent_time + 'Z'), dateFormat: 'MMM dd, yyyy, H:mm' })}
                            </div>
                          )}
                          <div className="time">
                            <WorkOutline fontSize="inherit" style={{ marginBottom: '2px' }} />{' '}
                            {cutString(18, notificationCompany?.title)}
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                },
              )}
            </ul>

            {notifications.length > 0 && (
              <div className="card-inner d-flex flex-row ">
                <PaginationSettings
                  itemsCountOptions={[10, 20, 30]}
                  itemsPerPage={notificationsPerPage}
                  setItemsPerPage={setNotificationsPerPage}
                />
                <PaginationComponent
                  itemPerPage={notificationsPerPage}
                  totalItems={notifications.length}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              </div>
            )}
          </Card>
        </Block>
      ) : (
        <div>You don't have any notifications.</div>
      )}
    </>
  );
};
