import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useEditJobMutation } from '../../../../../../app/jobs/api';
import { ColumnEnum, Job } from '../../../../../../types';
import { useJobModal } from './../../../hooks/useJobModal';

// TODO: change mapping from a backend for snake-case fields
const updateJobSchema = yup
  .object({
    column: yup.mixed<ColumnEnum>().oneOf(Object.values(ColumnEnum)).required('This field is required'),
    cover_letter: yup.string().trim(),
    assignee: yup.string().nullable(true),
  })
  .required();

type UpdateJobSchema = yup.InferType<typeof updateJobSchema>;

export const useKanbanTaskForm = ({ jobData: { id, column, cover_letter, assignee } }: { jobData: Job }) => {
  const { handleCloseJobModal } = useJobModal();
  const [editJob, { isLoading: isEditingJobModal }] = useEditJobMutation();

  const isLoading = isEditingJobModal;

  const formMethods = useForm<UpdateJobSchema>({
    resolver: yupResolver(updateJobSchema),
    defaultValues: {
      column,
      cover_letter: cover_letter || '',
      assignee: assignee?.id || '',
    },
  });

  const handleJobUpdate: SubmitHandler<UpdateJobSchema> = async (updateJobFormData) => {
    const { ...updatedJobData } = updateJobFormData;
    const editedJobData = {
      ...updatedJobData,
      id,
      sourceColumn: updatedJobData.column !== column ? column : undefined,
      destinationColumn: updatedJobData.column !== column ? updatedJobData.column : undefined,
    };

    await editJob(editedJobData);

    handleCloseJobModal();
  };

  return {
    handleJobUpdate,
    isLoading,
    formMethods,
  };
};
