import { useEffect, useState } from 'react';

type UseLayoutViewParams = {
  onDefaultView?: () => void;
  onMobileView?: () => void;
};

export const useLayoutView = ({ onDefaultView, onMobileView }: UseLayoutViewParams) => {
  const [isMobileView, setMobileView] = useState(false);

  const handleViewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
      onDefaultView?.();
    } else {
      setMobileView(false);
      onMobileView?.();
    }
  };

  useEffect(() => {
    handleViewChange();
    window.addEventListener('load', handleViewChange);
    window.addEventListener('resize', handleViewChange);
    return () => {
      window.removeEventListener('resize', handleViewChange);
      window.removeEventListener('load', handleViewChange);
    };
  }, []);

  return isMobileView;
};
