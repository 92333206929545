import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { Company } from '../../types';
import { REDUCER_COMPANY_SLICE } from '../constants';
import { CompanyActionsEnum } from './actions';

interface ICompanySliceState {
  currentCompany: Company | null;
}

const companyPersistConfig = {
  key: REDUCER_COMPANY_SLICE,
  storage,
};

const initialState: ICompanySliceState = {
  currentCompany: null,
};

const companySlice = createSlice({
  name: REDUCER_COMPANY_SLICE,
  initialState,
  reducers: {
    [CompanyActionsEnum.setCurrentCompany]: (state, action: PayloadAction<Company>) => {
      state.currentCompany = action.payload;
    },
    [CompanyActionsEnum.clearCurrentCompany]: (state) => {
      state.currentCompany = null;
    },
  },
});

export const { setCurrentCompany, clearCurrentCompany } = companySlice.actions;

export default persistReducer(companyPersistConfig, companySlice.reducer);
