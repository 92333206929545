import { NotificationsNone } from '@mui/icons-material';
import React, { MouseEventHandler, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { DropdownToggle, DropdownMenu, UncontrolledDropdown } from 'reactstrap';
import { useGetCompaniesQuery } from '../../../../../app/company/api';
import { notificationsSelector } from '../../../../../app/notifications/selectors';
import { readAllNotifications } from '../../../../../app/notifications/slice';
import { Loader } from '../../../../../components';
import { useToggle } from '../../../../../hooks';
import { ROUTES } from '../../../../../router/routes';
import { LinkItem } from '../../../../../theme-components/Component';

import { NotificationItem } from '../NotificationItem';

// TODO: rework <a> tag

export const Notifications = () => {
  const notifications = useSelector(notificationsSelector);

  const dispatch = useDispatch();

  const [isDropdownOpen, toggleDropdownOpen] = useToggle(false);

  const handleMarkAllAsRead: MouseEventHandler = (event) => {
    event.preventDefault();
    dispatch(readAllNotifications());
  };

  const { data, isLoading } = useGetCompaniesQuery();

  if (isLoading) return <Loader />;

  if (data) {
    return (
      <UncontrolledDropdown
        isOpen={isDropdownOpen}
        className="user-dropdown"
        toggle={(e: React.KeyboardEvent | React.MouseEvent) => {
          e.stopPropagation();
          toggleDropdownOpen();
        }}
      >
        <DropdownToggle tag="a" className="dropdown-toggle nk-quick-nav-icon">
          {notifications.length ? (
            <div className={'icon-status icon-status-info'}>
              <NotificationsNone />
            </div>
          ) : (
            <NotificationsNone />
          )}
        </DropdownToggle>
        <DropdownMenu end className="dropdown-menu-xl dropdown-menu-s1">
          <div className="dropdown-head">
            <span className="sub-title nk-dropdown-title">Notifications</span>
            {!!notifications.length && (
              <a href="#markasread" onClick={handleMarkAllAsRead}>
                Mark All as Read
              </a>
            )}
          </div>
          <div className="dropdown-body">
            <div className="nk-notification">
              {notifications.length ? (
                [...notifications]
                  .reverse()
                  .slice(0, 10)
                  .map((notification, index) => {
                    return <NotificationItem key={index} {...notification} companies={data} />;
                  })
              ) : (
                <div className="nk-notification-item">You don't have any notifications.</div>
              )}
            </div>
          </div>
          <div className="dropdown-foot center">
            {!!notifications.length && <LinkItem link={ROUTES.PROFILE.NOTIFICATIONS}>View All</LinkItem>}
          </div>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }
};
