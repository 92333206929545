import { ThumbDown, ThumbUp } from '@mui/icons-material';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { useGetCompanyRssLinksQuery } from '../../app/company/api';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useChangeJobColumnMutation, useGetJobsListQuery, usePostJobMutation } from '../../app/jobs/api';
import { selectJobsDeck, selectJobsStash, selectRssLinks, selectTempJob } from '../../app/jobs/selectors';
import { addJobsToDeck, addJobToStash, clearStash, clearTempJob, returnJobFromStash } from '../../app/jobs/slice';
import { ROUTES } from '../../router/routes';

import { Block, BlockContent } from '../../theme-components/Component';

import { ColumnEnum, Job, UWJob, RssLink } from '../../types';

import { convertUWJobToJob } from '../../utils/ConvertUWJobToJob';
import { DeckHeader } from '../DeckHeader';
import { Loader } from '../Loader';

import { SwiperCard } from '../SwiperCard';
import { DeckContainer, DeckGrid, ThumbButton } from './Deck.style';

export const Deck = () => {
  const dispatch = useAppDispatch();

  const storedLinks = useAppSelector(selectRssLinks);
  const deck = useAppSelector(selectJobsDeck);
  const stash = useAppSelector(selectJobsStash);
  const tempJob = useAppSelector(selectTempJob);
  const [click, setClick] = useState('');

  const {
    data: jobs,
    refetch,
    isLoading,
    isFetching,
    isError,
  } = useGetJobsListQuery(
    storedLinks.length ? `?rss_links=${storedLinks.map((link) => link.value).join('&rss_links=')}` : '',
  );

  const { data: linksData, isLoading: linksLoading } = useGetCompanyRssLinksQuery({});

  useEffect(() => {
    refetch();
    dispatch(clearTempJob());
  }, []);

  useEffect(() => {
    refetch();
  }, [storedLinks.length]);

  const [
    postJob,
    { data: postResult, isLoading: postLoading, isSuccess: postSuccess, isError: postError, originalArgs: postArgs },
  ] = usePostJobMutation();
  const [
    changeJob,
    {
      data: changeResult,
      isLoading: changeLoading,
      isSuccess: changeSuccess,
      isError: changeError,
      originalArgs: changeArgs,
    },
  ] = useChangeJobColumnMutation();

  useEffect(() => {
    if (jobs?.length) {
      dispatch(addJobsToDeck({ jobs }));
    } else {
      dispatch(addJobsToDeck({ jobs: [] }));
    }
  }, [jobs]);

  useEffect(() => {
    if (isError) {
      dispatch(addJobsToDeck({ jobs: [] }));
    }
  }, [isError]);

  useEffect(() => {
    if (!deck.length && !isError) {
      refetch();
    }
  }, [deck]);

  useEffect(() => {
    if (postLoading || changeLoading) {
      setClick('');
    }
  }, [postLoading, changeLoading]);

  useEffect(() => {
    if (changeSuccess) {
      if (changeArgs?.destinationColumn === ColumnEnum.Dislike) {
        dispatch(addJobToStash(changeResult as Job));
      }
      dispatch(clearTempJob());
    }
  }, [changeSuccess]);

  useEffect(() => {
    if (postSuccess) {
      if (postArgs?.column === ColumnEnum.Dislike) {
        dispatch(addJobToStash(postResult as Job));
      }
      dispatch(clearTempJob());
    }
  }, [postSuccess]);

  useEffect(() => {
    if (postError || changeError) {
      if (tempJob) {
        dispatch(addJobsToDeck({ jobs: [...deck, tempJob] }));
        dispatch(clearTempJob());
      }
    }
  }, [postError, changeError]);

  const thumbClickHandler = (isLike: boolean) => {
    isLike ? setClick('like') : setClick('dislike');
  };

  const postJobHandler = (job: UWJob | Job, isLike: boolean) => {
    if ('id' in job) {
      changeJob({
        id: job.id,
        sourceColumn: job.column,
        destinationColumn: isLike ? ColumnEnum.Like : ColumnEnum.Dislike,
      });
      return;
    }

    const preparedJob = convertUWJobToJob(job);

    postJob({ ...preparedJob, column: isLike ? ColumnEnum.Like : ColumnEnum.Dislike });
  };

  const keyDownEventHandler = (e: KeyboardEvent) => {
    if (e.code === 'ArrowRight') {
      setClick('like');
    }

    if (e.code === 'ArrowLeft') {
      setClick('dislike');
    }

    if (e.code === 'ArrowUp' && stash.length && !isLoading && !postLoading && !changeLoading) {
      dispatch(returnJobFromStash());
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', keyDownEventHandler);

    return () => document.removeEventListener('keydown', keyDownEventHandler);
  }, [deck.length]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <DeckContainer>
      <DeckHeader
        fetchedLinks={linksData as RssLink[]}
        isLinksLoading={linksLoading}
        selectedLinks={storedLinks}
        disableReturn={!stash.length || isLoading || postLoading || changeLoading || !jobs?.length}
      />
      {isFetching ? (
        <Loader />
      ) : !deck.length ? (
        <Block className="nk-block-middle wide-xs mx-auto">
          <BlockContent className="nk-error-ld text-center">
            <p className="nk-error-text">
              {(linksData as RssLink[])?.length
                ? 'At present, there are no new jobs available. You have the option to wait for updates or get more output by adding additional RSS links to increase the chances of receiving new jobs.'
                : 'At present, there are no RSS links added to your company account. Please create some in order to receive new jobs.'}
            </p>
            <Link to={ROUTES.COMPANIES} state={{ tab: 'Settings' }}>
              <Button color="primary" size="lg" className="mt-2">
                Go to Company page
              </Button>
            </Link>
          </BlockContent>
        </Block>
      ) : (
        <DeckGrid>
          <ThumbButton onClick={() => thumbClickHandler(false)} disabled={!!click || postLoading || changeLoading}>
            <ThumbDown color="inherit" fontSize="inherit" />
          </ThumbButton>

          <div
            style={{
              position: 'relative',
            }}
          >
            {!tempJob &&
              deck.map((job, i) => (
                <SwiperCard
                  key={job.upwork_id}
                  job={job}
                  isLastJob={i === deck.length - 1}
                  isJobsFetching={isFetching}
                  click={click}
                  postJob={postJobHandler}
                />
              ))}
          </div>

          <ThumbButton
            isLike
            onClick={() => thumbClickHandler(true)}
            disabled={!!click || postLoading || changeLoading}
          >
            <ThumbUp color="inherit" fontSize="inherit" />
          </ThumbButton>
        </DeckGrid>
      )}
    </DeckContainer>
  );
};
