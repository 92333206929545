import { Delete, EditOutlined, SaveOutlined } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Col, Modal } from 'reactstrap';

import { useGetCompanyRssLinksQuery, useUpdateRssLinkMutation } from '../../../app/company/api';
import { useAppDispatch } from '../../../app/hooks';
import { editRssLink } from '../../../app/jobs/slice';
import { useToggle } from '../../../hooks';
import { useCustomTableStyle } from '../../../styles/hooks';
import { Button } from '../../../theme-components/Component';
import { Company, ResponseWithPagination, RssLink } from '../../../types';
import { useDeleteRSSLink } from './hooks';

import { RssLinkForm } from './RssLinkForm';

interface IRssLinksSettingsProps {
  company?: Company;
}

export const RssLinksSettings: FC<IRssLinksSettingsProps> = ({ company }) => {
  const dispatch = useAppDispatch();

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isCreateModalOpen, toggleCreateModalOpen] = useToggle(false);
  const { customStyles } = useCustomTableStyle();

  const [chosenLink, setChosenLink] = useState<RssLink | null>(null);

  const { data, isLoading } = useGetCompanyRssLinksQuery({
    limit: rowsPerPage,
    offset: (page - 1) * rowsPerPage,
  });

  const [updateLink, { data: updatedLink, isLoading: isUpdateLoading, isSuccess, isError }] =
    useUpdateRssLinkMutation();

  const { handleDeleteRSSLink, isDeleteRssLinkLoading, deleteRssLinkArgs } = useDeleteRSSLink();

  useEffect(() => {
    if (company) {
      setPage(1);
      setRowsPerPage(10);
    }
  }, [company]);

  useEffect(() => {
    if (isSuccess || isError) {
      setChosenLink(null);
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    if (isSuccess && updatedLink) {
      dispatch(editRssLink(updatedLink));
    }
  }, [isSuccess, updatedLink]);

  const updateHandler = () => {
    if (!chosenLink || !company) {
      return;
    }

    updateLink({
      id: chosenLink?.id,
      name: chosenLink?.name,
      link: chosenLink?.link,
      company: company?.id,
    });
  };

  const columns = [
    {
      name: 'Name',
      cell: (row: RssLink) => (
        <Col sm="12">
          <div className="form-group d-flex f-grow-1">
            <input
              type="text"
              className="form-control"
              disabled={!chosenLink || chosenLink?.id !== row.id || isUpdateLoading || isLoading}
              value={chosenLink && chosenLink?.id === row.id ? chosenLink.name : row.name}
              onChange={({ target: { value } }) => {
                chosenLink &&
                  setChosenLink((state) => {
                    if (state) {
                      return { ...state, name: value };
                    }
                    return null;
                  });
              }}
            />
            {chosenLink?.id === row.id && !chosenLink?.name && <span className="invalid">Name is required</span>}
          </div>
        </Col>
      ),
      grow: 3,
    },
    {
      name: 'Link',
      cell: (row: RssLink) => (
        <Col sm="12">
          <div className="form-group d-flex f-grow-1">
            <input
              type="text"
              className="form-control"
              disabled={!chosenLink || chosenLink?.id !== row.id || isUpdateLoading || isLoading}
              value={chosenLink && chosenLink?.id === row.id ? chosenLink.link : row.link}
              onChange={({ target: { value } }) => {
                chosenLink &&
                  setChosenLink((state) => {
                    if (state) {
                      return { ...state, link: value };
                    }
                    return null;
                  });
              }}
            />
            {chosenLink?.id === row.id && !chosenLink?.link && <span className="invalid">Link is required</span>}
          </div>
        </Col>
      ),
      grow: 7,
    },
    {
      cell: (row: RssLink) => {
        return (
          <Button
            color="primary"
            size="sm"
            className="btn-icon  btn-white btn-dim"
            disabled={
              (!!chosenLink && chosenLink.id !== row.id) || isDeleteRssLinkLoading || isUpdateLoading || isLoading
            }
            onClick={() => (chosenLink && chosenLink.id === row.id ? updateHandler() : setChosenLink(row))}
          >
            {isUpdateLoading && chosenLink && chosenLink.id === row.id ? (
              <CircularProgress color="inherit" size="20px" />
            ) : chosenLink && chosenLink.id === row.id ? (
              <SaveOutlined sx={{ minWidth: '30px', height: '24px', padding: '0 6px' }} />
            ) : (
              <EditOutlined sx={{ minWidth: '30px', height: '24px', padding: '0 6px' }} />
            )}
          </Button>
        );
      },
      center: true,
      width: '50px',
    },
    {
      cell: (row: RssLink) => {
        return (
          <Button
            color="danger"
            size="sm"
            className="btn-icon btn-white btn-dim"
            disabled={
              (!!chosenLink && chosenLink.id !== row.id) || isUpdateLoading || isDeleteRssLinkLoading || isLoading
            }
            onClick={() => handleDeleteRSSLink(row.id)}
          >
            {isDeleteRssLinkLoading && deleteRssLinkArgs?.id === row.id ? (
              <CircularProgress color="inherit" size="20px" />
            ) : (
              <Delete sx={{ minWidth: '30px', height: '24px', padding: '0 6px' }} />
            )}
          </Button>
        );
      },
      center: true,
      width: '50px',
    },
  ];

  return (
    <div className="mt-4">
      <div className="d-flex align-center justify-between">
        <h5 className="title mt-0 mb-0">RSS links</h5>

        <Button color="primary" disabled={isLoading} onClick={toggleCreateModalOpen}>
          Create link
        </Button>
      </div>

      <div className="mt-2">
        <DataTable
          columns={columns}
          data={(data as ResponseWithPagination<RssLink>)?.results || []}
          progressPending={isLoading}
          pagination
          paginationServer
          paginationTotalRows={(data as ResponseWithPagination<RssLink>)?.count || 0}
          onChangeRowsPerPage={setRowsPerPage}
          onChangePage={setPage}
          customStyles={customStyles}
        />
      </div>

      <Modal size="md" isOpen={isCreateModalOpen} toggle={toggleCreateModalOpen}>
        {company && <RssLinkForm company={company} close={toggleCreateModalOpen} />}
      </Modal>
    </div>
  );
};
