import { baseApi } from '../baseApi';
import { API_ENDPOINTS } from '../endpoints';
import { Plan, Subscription } from './../../types';

export const subscriptionApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getPricingPlans: build.query<Plan[], void>({
      query: () => ({
        url: API_ENDPOINTS.SUBSCRIPTION.PLANS,
      }),
    }),
    createSubscription: build.mutation<Subscription, Pick<Subscription, 'plan' | 'company'>>({
      query: (body) => ({
        url: API_ENDPOINTS.SUBSCRIPTION.SUBSCRIPTIONS,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useGetPricingPlansQuery, useCreateSubscriptionMutation } = subscriptionApi;
