import Gleap from 'gleap';
import { useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { baseApi } from '../app/baseApi';
import { selectCurrentCompany } from '../app/company/selctors';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { clearRssLinks } from '../app/jobs/slice';
import { userProfileSelector } from '../app/user/selectors';
import { useFirebaseMessaging } from '../hooks';
import {
  ForgotPassword,
  KanbanPage,
  Login,
  Register,
  RegisterSuccess,
  Swiper,
  UserProfile,
  Company,
  Archive,
  Pricing,
  Statistics,
} from '../pages';
import AuthPageLayout from '../pages/Auth/layout/AuthPage';
import { ResetPassword } from '../pages/Auth/ResetPassword';
import { SecuritySettings, UserPersonalInformation, UserNotifications } from '../pages/UserProfile/components';
import { CompanyOnlyRoute } from './CompanyOnlyRoute/CompanyOnlyRoute';
import { PrivateRoute } from './PrivateRoute';
import PublicOnlyRoute from './PublicOnlyRoute';
import { ROUTES, ROUTE_CONSTRUCTOR } from './routes';

export const AppRouter = () => {
  useFirebaseMessaging();

  const dispatch = useAppDispatch();
  const currentCompany = useAppSelector(selectCurrentCompany);
  const currentUser = useAppSelector(userProfileSelector);

  useEffect(() => {
    if (currentCompany) {
      dispatch(baseApi.util.invalidateTags(['User', 'Staff', 'JobsList', 'JobsColumn', 'CompanyLinks']));
      dispatch(clearRssLinks());
    }
  }, [currentCompany]);

  useEffect(() => {
    if (currentUser) {
      Gleap.identify(currentUser.id, {
        name: `${currentUser.first_name} ${currentUser.last_name}`,
        email: currentUser.email,
        customData: {
          companyId: currentCompany?.id,
          companyName: currentCompany?.title,
          userRole: currentUser.role,
          isCompanyOwner: currentUser.is_owner,
        },
      });
    }
  }, [currentUser]);

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Navigate to={ROUTES.AUTH.LOGIN} replace />} />
        <Route path={ROUTE_CONSTRUCTOR.AUTH.index} element={<AuthPageLayout />}>
          <Route index element={<Navigate to={ROUTES.AUTH.LOGIN} replace />} />
          <Route path={ROUTE_CONSTRUCTOR.AUTH.LOGIN} element={<PublicOnlyRoute element={<Login />} />} />
          <Route path={ROUTE_CONSTRUCTOR.AUTH.REGISTER} element={<PublicOnlyRoute element={<Register />} />} />
          <Route path={ROUTE_CONSTRUCTOR.AUTH.SUCCESS} element={<PublicOnlyRoute element={<RegisterSuccess />} />} />
          <Route
            path={ROUTE_CONSTRUCTOR.AUTH.FORGOT_PASSWORD}
            element={<PublicOnlyRoute element={<ForgotPassword />} />}
          />
          <Route
            path={ROUTE_CONSTRUCTOR.AUTH.RESET_PASSWORD}
            element={<PublicOnlyRoute element={<ResetPassword />} />}
          />
        </Route>
        <Route path={ROUTES.KANBAN} element={<CompanyOnlyRoute element={<KanbanPage />} />} />
        <Route path={ROUTES.SWIPER} element={<CompanyOnlyRoute element={<Swiper />} />} />
        <Route path={ROUTES.ARCHIVE} element={<CompanyOnlyRoute element={<Archive />} />} />
        <Route path={ROUTES.PRICING} element={<CompanyOnlyRoute element={<Pricing />} />} />
        <Route path={ROUTES.STATISTICS} element={<CompanyOnlyRoute element={<Statistics />} />} />
        <Route path={ROUTE_CONSTRUCTOR.PROFILE.index} element={<PrivateRoute element={<UserProfile />} />}>
          <Route index element={<Navigate to={ROUTES.PROFILE.PERSONAL_INFORMATION} replace />} />
          <Route path={ROUTE_CONSTRUCTOR.PROFILE.PERSONAL_INFORMATION} element={<UserPersonalInformation />} />
          <Route path={ROUTE_CONSTRUCTOR.PROFILE.SECURITY_SETTINGS} element={<SecuritySettings />} />
          <Route path={ROUTE_CONSTRUCTOR.PROFILE.NOTIFICATIONS} element={<UserNotifications />} />
        </Route>
        <Route path={ROUTES.COMPANIES} element={<PrivateRoute element={<Company />} />} />
      </Routes>
    </BrowserRouter>
  );
};
