import { CalendarMonth, Notes, Verified } from '@mui/icons-material';
import { Rating, Typography } from '@mui/material';
import React, { FC, useEffect } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { animated, useSpring } from 'react-spring';

import { useAppDispatch } from '../../app/hooks';
import { removeJobFromDeck, setTempJob } from '../../app/jobs/slice';

import { CurrencyMap, CurrencyNameMap } from '../../constants';
import { formatDate } from '../../helpers/date';

import { UpworkButton, CustomBadge } from '../../styles';
import { Colors } from '../../styles/Colors';

import { JobPrice } from '../../theme-components/_complex/kanban/components/JobPrice';

import { UWJob, Job } from '../../types';
import { getNormalizedCountriesList } from '../../utils/GetNormalizedCountriesList';

import {
  CardContainer,
  JobDescription,
  JobSkills,
  CardHeader,
  CardDivider,
  CardInfo,
  CardInfoItem,
  CardDetails,
} from './SwiperCard.style';

interface ISwiperCardProps {
  job: UWJob | Job;
  isLastJob: boolean;
  isJobsFetching: boolean;
  click: string;
  postJob(job: UWJob | Job, isLike: boolean): void;
}

export const SwiperCard: FC<ISwiperCardProps> = ({ job, isLastJob, isJobsFetching, click, postJob }) => {
  const dispatch = useAppDispatch();
  const normalizedCountriesList = getNormalizedCountriesList();

  const [props, api] = useSpring(() => ({
    from: {
      x: 0,
      y: '-200%',
      opacity: 0,
      zIndex: -1,
    },
    onRest: {
      x: ({ value }) => {
        if (value !== 0) {
          postJob(job, value > 0);
          dispatch(setTempJob({ job }));
          dispatch(removeJobFromDeck({ job }));
        }
      },
    },
    config: { tension: 150, mass: 1, friction: 30 },
  }));

  useEffect(() => {
    if (isLastJob && !isJobsFetching) {
      api.start({
        x: 0,
        y: '-50%',
        opacity: 1,
        zIndex: 1,
      });
    } else {
      api.start({
        x: 0,
        y: '-200%',
        opacity: 0,
        zIndex: -1,
      });
    }
  }, [isLastJob, isJobsFetching]);

  useEffect(() => {
    if (!click) return;

    if (click === 'like' && isLastJob) {
      api.start({
        x: 400,
        y: '-50%',
        opacity: 0,
      });
    }

    if (click === 'dislike' && isLastJob) {
      api.start({
        x: -400,
        y: '-50%',
        opacity: 0,
      });
    }
  }, [click]);

  const countryCode = job?.country ? normalizedCountriesList.getValue(job.country) : '';
  const preferredCountryCode =
    !('id' in job) && job?.details?.preferred_location
      ? normalizedCountriesList.getValue(job?.details?.preferred_location)
      : '';

  return (
    <CardContainer className="card card-preview" as={animated.div} style={props}>
      <div className="card-inner pt-0">
        <CardHeader>
          <div className="d-flex justify-between align-center position-sticky">
            <h4 className="title mb-0 text-start">{job.title}</h4>
            <UpworkButton style={{ marginLeft: '10px' }} link={job.link} />
          </div>

          <CardInfo>
            <CardInfoItem>
              <CalendarMonth fontSize="inherit" className="me-1" sx={{ marginBottom: '4px' }} />
              <span>{formatDate({ date: new Date(job.publication_date), dateFormat: 'MMMM dd, yyyy, H:mm' })}</span>
            </CardInfoItem>

            <CardInfoItem>
              <div className="d-flex align-center">
                <ReactCountryFlag countryCode={countryCode} svg />
                <Typography ml={1} fontSize={16}>
                  {job.country}
                </Typography>
              </div>
            </CardInfoItem>

            <CardInfoItem>
              {'id' in job ? (
                <JobPrice
                  fixedPrice={job.fixed_price}
                  fixedPriceCurrency={(job as Job).fixed_price_currency}
                  hourlyRangeFrom={job.hourly_range_from ? job.hourly_range_from.toString() : ''}
                  hourlyRangeToCurrency={(job as Job).hourly_range_to_currency}
                  hourlyRangeTo={job.hourly_range_to ? job.hourly_range_to.toString() : ''}
                  hourlyRangeFromCurrency={(job as Job).hourly_range_from_currency}
                />
              ) : (
                <JobPrice
                  fixedPrice={job.fixed_price}
                  fixedPriceCurrency={
                    (job as UWJob).currency_symbol === CurrencyMap.USD ? CurrencyNameMap.USD : CurrencyNameMap.EUR
                  }
                  hourlyRangeFrom={job.hourly_range_from ? job.hourly_range_from.toString() : ''}
                  hourlyRangeToCurrency={
                    (job as UWJob).currency_symbol === CurrencyMap.USD ? CurrencyNameMap.USD : CurrencyNameMap.EUR
                  }
                  hourlyRangeTo={job.hourly_range_to ? job.hourly_range_to.toString() : ''}
                  hourlyRangeFromCurrency={
                    (job as UWJob).currency_symbol === CurrencyMap.USD ? CurrencyNameMap.USD : CurrencyNameMap.EUR
                  }
                />
              )}
            </CardInfoItem>

            <CardInfoItem>
              <Notes fontSize="small" className="me-1" sx={{ marginBottom: '2px' }} />
              <span>{job.category}</span>
            </CardInfoItem>
          </CardInfo>

          <CardDivider />
        </CardHeader>

        {!('id' in job) &&
          job?.details &&
          (job?.details?.client_score ||
            job?.details?.preferred_location ||
            job?.details?.payment_verified ||
            job?.details?.total_candidates) && (
            <div className="mb-2">
              <h5 className="title text-start">Details</h5>

              <CardDetails>
                {!!job?.details?.client_score && (
                  <div className="d-flex align-center">
                    <div className="me-1">Client rating:</div>

                    <Rating value={job?.details?.client_score} sx={{ zIndex: -1 }} readOnly />
                  </div>
                )}

                {job?.details?.preferred_location && (
                  <div className="d-flex align-center">
                    <div className="me-1">Preferred employee location:</div>

                    <div className="d-flex align-center">
                      <ReactCountryFlag countryCode={preferredCountryCode} svg />
                      <Typography ml={1} fontSize={16}>
                        {job?.details?.preferred_location}
                      </Typography>
                    </div>
                  </div>
                )}

                {job?.details?.payment_verified && (
                  <div className="d-flex align-center">
                    <Verified fontSize="inherit" htmlColor={Colors.green.light} />
                    <div className="me-1">Payment verified</div>
                  </div>
                )}

                {job?.details?.total_candidates && (
                  <div className="d-flex align-center">{`Total candidates: ${job?.details?.total_candidates}`}</div>
                )}
              </CardDetails>
            </div>
          )}

        <div>
          <h5 className="title text-start">Description</h5>

          <JobDescription>{job.description}</JobDescription>
        </div>

        <div className="mt-2">
          <h5 className="title text-start">Skills</h5>

          <JobSkills className="kanban-item-tags">
            {job.skills?.map((skill) => (
              <li key={skill}>
                {!!skill && (
                  <CustomBadge>
                    <Typography fontSize={13} fontWeight={600}>
                      {skill}
                    </Typography>
                  </CustomBadge>
                )}
              </li>
            ))}
          </JobSkills>
        </div>
      </div>
    </CardContainer>
  );
};
