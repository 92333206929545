import { LocalAtm } from '@mui/icons-material';
import { CurrencyMap } from '../../../../../constants';

type JobPriceProps = {
  fixedPrice: string;
  fixedPriceCurrency: string;
  hourlyRangeFrom: string;
  hourlyRangeFromCurrency: string;
  hourlyRangeTo: string;
  hourlyRangeToCurrency: string;
};

export const JobPrice = ({
  fixedPrice,
  fixedPriceCurrency,
  hourlyRangeFrom,
  hourlyRangeToCurrency,
  hourlyRangeTo,
  hourlyRangeFromCurrency,
}: JobPriceProps) => {
  if (fixedPrice) {
    return (
      <div className="kanban-item-text d-flex align-items-center">
        <LocalAtm fontSize="small" className="me-1" /> {CurrencyMap[fixedPriceCurrency]}
        {fixedPrice}
      </div>
    );
  }

  if (hourlyRangeFrom && hourlyRangeTo) {
    return (
      <div className="kanban-item-text d-flex align-items-center">
        <LocalAtm fontSize="small" className="me-1" />
        {CurrencyMap[hourlyRangeFromCurrency]}
        {hourlyRangeFrom}-{CurrencyMap[hourlyRangeToCurrency]}
        {hourlyRangeTo}
      </div>
    );
  }
  return null;
};
