import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useGetMostWonAndLostReportQuery } from '../../../app/stats/api';
import { BlockTitle } from '../../../theme-components/Component';
import { Loader } from '../../Loader';

export const WonByCountryReport = () => {
  const { data, isLoading, isError } = useGetMostWonAndLostReportQuery();

  let preparedData = {};

  let content;

  if (data) {
    preparedData = {
      labels: data.won.map(({ country }) => country),
      datasets: [
        {
          label: 'Jobs',
          data: data.won.map(({ count }) => count),
          backgroundColor: data.won.map((_) => `#${Math.floor(Math.random() * 16777215).toString(16)}`),
        },
      ],
    };

    content = data.won.some(({ count }) => !!count) ? (
      <Doughnut
        data={preparedData}
        options={{
          layout: {
            padding: 20,
          },
          legend: {
            display: false,
          },
          tooltips: {
            enabled: true,
            backgroundColor: '#eff6ff',
            titleFontSize: 13,
            titleFontColor: '#6783b8',
            titleMarginBottom: 6,
            bodyFontColor: '#9eaecf',
            bodyFontSize: 12,
            bodySpacing: 4,
            yPadding: 10,
            xPadding: 10,
            footerMarginTop: 0,
            displayColors: true,
          },
        }}
      />
    ) : (
      <div className="text-center my-2 mx-4">Sorry, there is not enough data for current company to report</div>
    );
  }

  if (isError) {
    content = (
      <div className="text-center mt-2 mb-2">The error occurred while loading report. Please refresh page later</div>
    );
  }

  if (isLoading) {
    content = (
      <div className="mt-2 mb-2">
        <Loader className="h-100" />;
      </div>
    );
  }

  return (
    <div className="card card-bordered card-preview mt-2">
      <div className="card-inner">
        <BlockTitle>
          <div>Won by country report</div>
        </BlockTitle>
      </div>

      {content}
    </div>
  );
};
