export enum JobsActionsEnum {
  addJobsToDeck = 'addJobsToDeck',
  removeJobFromDeck = 'removeJobFromDeck',
  setTempJob = 'setTempJob',
  clearTempJob = 'clearTempJob',
  addJobToStash = 'addJobToStash',
  returnJobFromStash = 'returnJobFromStash',
  clearStash = 'clearStash',
  setRssLinks = 'setRssLinks',
  clearRssLinks = 'clearRssLinks',
  editRssLink = 'editRssLink',
  removeRssLink = 'removeRssLink',
  addAwaitingCoverLetterJob = 'addAwaitingCoverLetterJob',
  removeAwaitingCoverLetterJob = 'removeAwaitingCoverLetterJob',
  clearAwaitingCoverLetterJobs = 'clearAwaitingCoverLetterJobs',
}
