import countryList from 'react-select-country-list';

export const getNormalizedCountriesList = () => {
  const countriesToRename = {
    VN: 'Vietnam',
    BO: 'Plurinational State of Bolivia',
    CD: 'Democratic Republic of the Congo',
    IR: 'Islamic Republic of Iran',
    KP: 'North Korea',
    KR: 'South Korea',
    FM: 'Federated States of Micronesia',
    MD: 'Republic of Moldova',
    PS: 'State of Palestine',
    TW: 'Taiwan',
    TZ: 'United Republic of Tanzania',
    VE: 'Bolivarian Republic of Venezuela',
  };

  Object.entries(countriesToRename).map(([countryId, newName]) => countryList().setLabel(countryId, newName));

  const normalizedList = countryList();

  return normalizedList;
};
