import { Outlet } from 'react-router-dom';
import PageContainer from '../../../layout/page-container/PageContainer';
import AuthFooter from '../components/AuthFooter';

export default function AuthPageLayout(): JSX.Element {
  return (
    <PageContainer>
      <Outlet />
      <AuthFooter />
    </PageContainer>
  );
}
