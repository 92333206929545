import { yupResolver } from '@hookform/resolvers/yup';
import { Send } from '@mui/icons-material';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useAddCommentMutation } from '../../../../../../../../../app/jobs/api';
import { UUID } from '../../../../../../../../../types/types/UUID';
import { Button } from '../../../../../../../../Component';

const addCommentSchema = yup
  .object({
    comment: yup.string().trim().required('Comment is required'),
  })
  .required();

type AddCommentSchema = yup.InferType<typeof addCommentSchema>;

export const AddJobComment = ({ id }: { id: UUID }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<AddCommentSchema>({
    resolver: yupResolver(addCommentSchema),
    defaultValues: {
      comment: '',
    },
  });

  const [addComment] = useAddCommentMutation();

  const handleCommentSubmit: SubmitHandler<AddCommentSchema> = async ({ comment }) => {
    await addComment({ comment, job: id });
    reset();
  };

  return (
    <div className="nk-chat-editor ">
      <div className="nk-chat-editor-form">
        <div className="form-control-wrap">
          <textarea
            className="form-control  no-resize"
            rows={3}
            placeholder="Type your comment here..."
            {...register('comment')}
            onKeyDown={(e) => {
              if (e.code === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSubmit(handleCommentSubmit)();
              }
            }}
          />
          {errors?.comment && <span className="text-danger">{errors.comment.message}</span>}
        </div>
      </div>
      <ul className="nk-chat-editor-tools g-2">
        <li>
          <Button color="primary" onClick={handleSubmit(handleCommentSubmit)} className="btn-round btn-icon">
            <Send fontSize="medium" className="mx-1" />
          </Button>
        </li>
      </ul>
    </div>
  );
};
