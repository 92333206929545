import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session';
import { Notification } from './../../types';

import { REDUCER_NOTIFICATIONS_SLICE } from './../constants';

type NotificationSliceState = {
  notifications: Notification[];
};

const notificationsPersistConfig = {
  key: REDUCER_NOTIFICATIONS_SLICE,
  storage: storageSession,
};

const initialState: NotificationSliceState = {
  notifications: [],
};

const notificationsSlice = createSlice({
  name: REDUCER_NOTIFICATIONS_SLICE,
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<{ notification: Notification }>) => {
      state.notifications.push(action.payload.notification);
    },
    readAllNotifications: (state) => {
      state.notifications = [];
    },
  },
});

export const { addNotification, readAllNotifications } = notificationsSlice.actions;

export default persistReducer(notificationsPersistConfig, notificationsSlice.reducer);
