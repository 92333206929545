import Gleap from 'gleap';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';

import { persistor, store } from './app/store';
// eslint-disable-next-line import/order
import { AppContainer } from './App.style';

import { AppRouter } from './router';

import './assets/scss/dashlite.scss';

function App() {
  useEffect(() => {
    Gleap.initialize(process.env.REACT_APP_GLEAP_API_KEY);
  }, []);

  return (
    <>
      <AppContainer>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <AppRouter />
          </PersistGate>
        </Provider>
        <ToastContainer />
      </AppContainer>
    </>
  );
}

export default App;
