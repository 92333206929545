import { configureStore } from '@reduxjs/toolkit';

import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist/es/constants';
import persistStore from 'redux-persist/es/persistStore';

import authSlice from './auth/slice';
import { baseApi } from './baseApi';
import companySlice from './company/slice';
import {
  REDUCER_AUTH_SLICE,
  REDUCER_JOBS_SLICE,
  REDUCER_USER_SLICE,
  REDUCER_NOTIFICATIONS_SLICE,
  REDUCER_COMPANY_SLICE,
} from './constants';
import jobsSlice from './jobs/slice';
import notificationsSlice from './notifications/slice';
import userSlice from './user/slice';

export const store = configureStore({
  reducer: {
    [baseApi.reducerPath]: baseApi.reducer,
    [REDUCER_AUTH_SLICE]: authSlice,
    [REDUCER_JOBS_SLICE]: jobsSlice,
    [REDUCER_NOTIFICATIONS_SLICE]: notificationsSlice,
    [REDUCER_USER_SLICE]: userSlice,
    [REDUCER_COMPANY_SLICE]: companySlice,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(baseApi.middleware),
});
export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
