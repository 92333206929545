import {
  ArchiveOutlined,
  ArrowBack,
  InsertChartOutlined,
  Menu as MenuIcon,
  SwipeOutlined,
  ViewKanbanOutlined,
  WorkOutline,
} from '@mui/icons-material';
import classNames from 'classnames';
import React, { useState } from 'react';
import SimpleBar from 'simplebar-react';
import { selectCurrentCompany } from '../../app/company/selctors';
import { useAppSelector } from '../../app/hooks';
import { ROUTES } from '../../router/routes';
import Logo from '../logo/Logo';
import Menu from '../menu/Menu';
import Toggle from './Toggle';

const Sidebar = ({ fixed, theme, className, sidebarToggle, mobileView, ...props }) => {
  const currentCompany = useAppSelector(selectCurrentCompany);

  const sidebarMenuItems = [
    ...(currentCompany
      ? [
          { icon: <SwipeOutlined />, text: 'Swiper', link: ROUTES.SWIPER },
          { icon: <ViewKanbanOutlined />, text: 'Kanban', link: ROUTES.KANBAN },
          { icon: <ArchiveOutlined />, text: 'Archive', link: ROUTES.ARCHIVE },
          { icon: <InsertChartOutlined />, text: 'Statistics', link: ROUTES.STATISTICS },
        ]
      : []),
    { icon: <WorkOutline />, text: 'Companies', link: ROUTES.COMPANIES },
  ];

  const [collapseSidebar, setSidebar] = useState(false);
  const [mouseEnter, setMouseEnter] = useState(false);

  const toggleCollapse = () => {
    setSidebar(!collapseSidebar);
  };

  const handleMouseEnter = () => setMouseEnter(true);
  const handleMouseLeave = () => setMouseEnter(false);

  const classes = classNames({
    'nk-sidebar': true,
    'nk-sidebar-fixed': fixed,
    'is-compact': collapseSidebar,
    'has-hover': collapseSidebar && mouseEnter,
    [`is-light`]: theme === 'white',
    [`is-${theme}`]: theme !== 'white' && theme !== 'light',
    [`${className}`]: className,
  });

  return (
    <div className={classes}>
      <div className="nk-sidebar-element nk-sidebar-head">
        <div className="nk-menu-trigger">
          <Toggle
            className="nk-nav-toggle nk-quick-nav-icon d-xl-none me-n2"
            icon={<ArrowBack fontSize="large" />}
            click={sidebarToggle}
          />
          <Toggle
            className={`nk-nav-compact nk-quick-nav-icon d-none d-xl-inline-flex ${
              collapseSidebar ? 'compact-active' : ''
            }`}
            click={toggleCollapse}
            icon={<MenuIcon fontSize="large" />}
          />
        </div>
        <div className="nk-sidebar-brand">
          <Logo className="logo-light" />
        </div>
      </div>
      <div className="nk-sidebar-content" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <SimpleBar className="nk-sidebar-menu">
          <Menu sidebarToggle={sidebarToggle} mobileView={mobileView} items={sidebarMenuItems} />
        </SimpleBar>
      </div>
    </div>
  );
};
export default Sidebar;
