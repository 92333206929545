import { BaseQueryApi, createApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import { Company } from '../types';
import { resetTokens, setTokens } from './auth/slice';
import { REDUCER_AUTH_SLICE, REDUCER_COMPANY_SLICE } from './constants';
import { API_ENDPOINTS } from './endpoints';
import type { RootState } from './store';

const baseUrl = process.env.REACT_APP_API_URL;

const query = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const { access = '' } = (getState() as RootState)[REDUCER_AUTH_SLICE];
    const { currentCompany = {} as Company } = (getState() as RootState)[REDUCER_COMPANY_SLICE];

    if (access) {
      headers.set('authorization', `Bearer ${access}`);
    }

    if (currentCompany) {
      headers.set('Current-Company', `${currentCompany?.id}`);
    }

    return headers;
  },
});

const mutex = new Mutex();

const extendedQuery = async (args: FetchArgs, api: BaseQueryApi, extraOptions: object) => {
  await mutex.waitForUnlock();

  let result = await query(args, api, extraOptions);

  if (
    [API_ENDPOINTS.AUTH.LOGIN, API_ENDPOINTS.AUTH.REGISTER].some((req) => result.meta?.request.url.includes(req)) &&
    !result?.error
  ) {
    // is token retrieve request

    api.dispatch(setTokens(result.data));
  }

  const isLoginRequest = result.meta?.request.url.endsWith(API_ENDPOINTS.AUTH.LOGIN);
  const { refresh = '' } = (api.getState() as RootState)[REDUCER_AUTH_SLICE];

  if (!isLoginRequest && refresh && result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult = await query(
          {
            url: API_ENDPOINTS.AUTH.REFRESH_TOKEN,
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: {
              refresh,
            },
          },
          api,
          extraOptions,
        );

        if (refreshResult.data && !refreshResult?.error) {
          api.dispatch(setTokens(refreshResult.data));
          result = await query(args, api, extraOptions);
        } else {
          api.dispatch(resetTokens());
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();

      result = await query(args, api, extraOptions);
    }
  }
  return result;
};

const companyTagTypes = ['CompanyById', 'CompanyLinks', 'CompaniesList'];
const jobTagTypes = ['JobsList', 'JobsColumn', 'DeadlineDate', 'JobById', 'Comments', 'History'];
const userTagTypes = ['User', 'Users', 'Staff', 'AssigneesList'];

export const baseApi = createApi({
  baseQuery: extendedQuery,
  reducerPath: 'BASE_API',
  tagTypes: [...companyTagTypes, ...jobTagTypes, ...userTagTypes],
  endpoints: () => ({}),
});
