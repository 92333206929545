import { ErrorOutlineOutlined } from '@mui/icons-material';
import { useEffect } from 'react';
import { Alert, ModalBody } from 'reactstrap';
import { useAppSelector } from '../../../../../app/hooks';
import { useGetJobQuery } from '../../../../../app/jobs/api';
import { selectAwaitingCoverLetterJobs } from '../../../../../app/jobs/selectors';
import { Loader } from '../../../../../components';
import { Job, JobStatusEnum } from '../../../../../types';
import { JobModalContent } from './components/JobModalContent/JobModalContent';

type JobModalProps = {
  id: string;
  isArchive?: boolean;
  jobs?: Record<string, Job[] | []>;
  setJobs?: React.Dispatch<React.SetStateAction<Record<string, Job[] | []>>>;
};

export const JobModal = ({ id, jobs, setJobs, isArchive }: JobModalProps) => {
  const {
    data: jobData,
    isLoading: isGetJobLoading,
    isError,
    isSuccess,
    refetch,
    isUninitialized,
  } = useGetJobQuery({ id, status: isArchive ? JobStatusEnum.Lost : undefined }, { refetchOnMountOrArgChange: true });

  const isJobAwaitingForCoverLetter = useAppSelector(selectAwaitingCoverLetterJobs).find((id) => id === jobData?.id);

  useEffect(() => {
    if (!isJobAwaitingForCoverLetter && isSuccess && !isGetJobLoading && !isUninitialized && jobData) {
      refetch();
    }
  }, [isJobAwaitingForCoverLetter, jobData]);

  if (isError) {
    return (
      <Alert color="danger" className="alert-icon">
        <ErrorOutlineOutlined fontSize="inherit" className="me-1 icon" />
        Failed to load job data.
      </Alert>
    );
  }

  if (isGetJobLoading) return <Loader />;

  return (
    <ModalBody>
      {isSuccess && <JobModalContent jobData={jobData} jobs={jobs} setJobs={setJobs} isArchive={isArchive} />}
    </ModalBody>
  );
};
