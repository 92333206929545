import { ArrowForwardIos, ErrorOutlineOutlined, LockOutlined, Menu } from '@mui/icons-material';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { selectCurrentCompany } from '../../../../app/company/selctors';
import { useAppSelector } from '../../../../app/hooks';
import { useGetUserProfileQuery } from '../../../../app/user/api';
import { UserRoleMap } from '../../../../constants';
import Head from '../../../../layout/head/Head';
import { ROUTES } from '../../../../router/routes';
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
} from '../../../../theme-components/Component';
import { cutString } from '../../../../utils/CutString';
import { UserProfileContext } from '../../UserProfileContext';

export const UserPersonalInformation = () => {
  const { toggleUpdateUserModalOpen, isProfileSidebarOpen, toggleProfileSidebarOpen } = useContext(UserProfileContext);

  const { data: userProfileData, isSuccess } = useGetUserProfileQuery();
  const navigate = useNavigate();

  const currentCompany = useAppSelector(selectCurrentCompany);

  if (!isSuccess)
    return (
      <Alert color="danger" className="alert-icon mt-3">
        <ErrorOutlineOutlined fontSize="inherit" className="me-1 icon" /> to load user profile data.
      </Alert>
    );

  return (
    <>
      <Head title="User Profile" />
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Personal Information</BlockTitle>
            <BlockDes>
              <p>Basic info, like your name and email, that you use on our platform.</p>
            </BlockDes>
          </BlockHeadContent>
          <BlockHeadContent className="align-self-start d-lg-none">
            <Button
              className={`toggle btn btn-icon btn-trigger mt-n1 ${isProfileSidebarOpen ? 'active' : ''}`}
              onClick={toggleProfileSidebarOpen}
            >
              <Menu fontSize="small" className="mx-1" />
            </Button>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <Block>
        <div className="nk-data data-list">
          <div className="data-head">
            <h6 className="overline-title">Basics</h6>
          </div>
          <div className="data-item" onClick={toggleUpdateUserModalOpen}>
            <div className="data-col">
              <span className="data-label">First Name</span>
              <span className="data-value">{userProfileData.first_name}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more d-flex align-items-center justify-center">
                <ArrowForwardIos fontSize="inherit" />
              </span>
            </div>
          </div>
          <div className="data-item" onClick={toggleUpdateUserModalOpen}>
            <div className="data-col">
              <span className="data-label">Last Name</span>
              <span className="data-value">{userProfileData.last_name}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more d-flex align-items-center justify-center">
                <ArrowForwardIos fontSize="inherit" />
              </span>
            </div>
          </div>
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">Email</span>
              <span className="data-value">{userProfileData.email}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more disable d-flex align-items-center justify-center">
                <LockOutlined fontSize="inherit" />
              </span>
            </div>
          </div>
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">Role</span>
              <span className="data-value">{UserRoleMap[userProfileData.role]}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more disable d-flex align-items-center justify-center">
                <LockOutlined fontSize="inherit" />
              </span>
            </div>
          </div>
          <div className="data-item" onClick={() => navigate(ROUTES.COMPANIES)}>
            <div className="data-col">
              <span className="data-label">Company</span>
              <span className="data-value">{cutString(45, currentCompany?.title) || 'Without a company'}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more d-flex align-items-center justify-center">
                <ArrowForwardIos fontSize="inherit" />
              </span>
            </div>
          </div>
        </div>
      </Block>
    </>
  );
};
