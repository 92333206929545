import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorOutlineOutlined } from '@mui/icons-material';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Alert, Button, Col, ModalBody } from 'reactstrap';
import * as yup from 'yup';

import { useCreateRssLinkMutation } from '../../../app/company/api';
import { Company, RssLinkInput } from '../../../types';

interface IRssLinksFormProps {
  close(): void;
  company: Company;
}

type RequestError = { data: { details?: { non_field_errors?: string[] } } } | { data: { code?: string } };

const createRssLinkSchema: yup.SchemaOf<RssLinkInput> = yup
  .object()
  .shape({
    name: yup.string().trim().required('Name is required'),
    link: yup.string().trim().required('Link is required'),
    company: yup.string().trim().required('Company is required'),
  })
  .required();

export const RssLinkForm: FC<IRssLinksFormProps> = ({ close, company }) => {
  const [errorMessage, setErrorMessage] = useState('');

  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(createRssLinkSchema),
    defaultValues: {
      name: '',
      link: '',
      company: company.id,
    },
  });

  const [createLink, result] = useCreateRssLinkMutation();

  const sendFormHandler = async (formValues: RssLinkInput) => {
    await createLink(formValues);
  };

  useEffect(() => {
    if (result.isSuccess) {
      close();
    }
  }, [result]);

  useEffect(() => {
    if (result.error) {
      const requestErrors = result.error as RequestError;

      if ('code' in requestErrors.data && requestErrors.data.code !== 'invalid_input') {
        setErrorMessage(requestErrors?.data?.code?.split('_')?.join(' ') || '');
      } else if ('details' in requestErrors.data) {
        setErrorMessage(requestErrors?.data?.details?.non_field_errors?.map((error) => error)?.join(' ') || '');
      } else {
        setErrorMessage('Rss link creation failed, please try again later');
      }
    }
  }, [result.error]);

  return (
    <ModalBody>
      <h5 className="title">Create link</h5>
      {result.isError && (
        <Alert color="danger" className="alert-icon">
          <ErrorOutlineOutlined fontSize="inherit" className="me-1 icon" />
          {errorMessage}
        </Alert>
      )}
      <form className="row gy-4" onSubmit={handleSubmit(sendFormHandler)}>
        <Col sm="12">
          <div className="form-group">
            <input
              type="text"
              disabled={result.isLoading}
              placeholder="Name this rss link"
              className="form-control"
              {...register('name')}
            />
            {errors.name && <span className="invalid">{errors.name?.message as string}</span>}
          </div>
        </Col>

        <Col sm="12">
          <div className="form-group">
            <input
              type="text"
              disabled={result.isLoading}
              placeholder="Paste link here..."
              className="form-control"
              {...register('link')}
            />
            {errors.link && <span className="invalid">{errors.link?.message as string}</span>}
          </div>
        </Col>

        <ul className="d-flex justify-content-between">
          <ul className="align-center flex-wrap flex-sm-nowrap gap-2">
            <li>
              <Button color="primary" type="submit" disabled={result.isLoading}>
                Create
              </Button>
            </li>
            <li>
              <Button type="reset" disabled={result.isLoading} onClick={close} color="secondary">
                Cancel
              </Button>
            </li>
          </ul>
        </ul>
      </form>
    </ModalBody>
  );
};
