import { Menu } from '@mui/icons-material';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { ProButton } from '../../components';
import { ROUTES } from '../../router/routes';
import Logo from '../logo/Logo';
import Toggle from '../sidebar/Toggle';
import { Notifications } from './dropdown/notification/Notifications';

import User from './dropdown/user/User';

const Header = ({ fixed, theme, className = null, setVisibility, ...props }) => {
  const navigate = useNavigate();

  const headerClass = classNames({
    'nk-header': true,
    'nk-header-fixed': fixed,
    [`is-light`]: theme === 'white',
    [`is-${theme}`]: theme !== 'white' && theme !== 'light',
    [`${className}`]: className,
  });
  return (
    <div className={headerClass}>
      <div className="container-fluid">
        <div className="nk-header-wrap">
          <div className="nk-menu-trigger d-xl-none ms-n1">
            <Toggle
              className="nk-nav-toggle nk-quick-nav-icon d-xl-none ms-n1"
              icon={<Menu fontSize="large" />}
              click={props.sidebarToggle}
            />
          </div>

          <div className="nk-header-brand d-xl-none me-2">
            <Logo className="logo-dark" />
          </div>

          <ProButton onClick={() => navigate(ROUTES.PRICING)}>Upgrade to PRO</ProButton>

          <div className="nk-header-tools">
            <ul className="nk-quick-nav">
              <li className="user-dropdown" onClick={() => setVisibility(false)}>
                <User />
              </li>
              <li className="notification-dropdown me-n1" onClick={() => setVisibility(false)}>
                <Notifications />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
