import { Notes, PlaceOutlined, UTurnLeft } from '@mui/icons-material';
import { Typography } from '@mui/material';
import classNames from 'classnames';
import { Draggable } from 'react-beautiful-dnd';
import { CustomBadge } from '../../../../../styles';
import { ColumnEnum, Job } from '../../../../../types';
import { cutString } from '../../../../../utils/CutString';
import { useDeadlineDate, useJobModal } from '../../hooks';
import { JobPrice } from '../JobPrice';
import { JobCardActions, JobCardAssignees, JobCardDeadlineDate } from './components';

type JobCardProps = {
  job: Job;
  index: number;
  jobs?: Record<string, Job[] | []>;
  setJobs?: React.Dispatch<React.SetStateAction<Record<string, Job[] | []>>>;
};

export const JobCard = ({ job, index, jobs, setJobs }: JobCardProps) => {
  const {
    id,
    title,
    description,
    skills,
    category,
    column,
    country,
    dead_line_date,
    is_returned_from_archive,
    assignee,
    assigner,
    fixed_price,
    fixed_price_currency,
    hourly_range_from_currency,
    hourly_range_to_currency,
    hourly_range_from,
    hourly_range_to,
  } = job;

  const { handleOpenJobModal } = useJobModal();

  const { deadlineLabel, deadlineState } = useDeadlineDate({ column, deadlineDate: dead_line_date });

  if (jobs && setJobs && column === ColumnEnum.Like && new Date(dead_line_date).getTime() < new Date().getTime()) {
    setJobs({ ...jobs, [column]: jobs[column].filter((j) => j.id !== id) });
  }

  return (
    <Draggable draggableId={id} key={id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={`${
            column === ColumnEnum.Like && new Date(dead_line_date).getTime() < new Date().getTime() ? 'd-none' : ''
          } ${index > 0 ? 'mt-2' : ''}`}
          onClick={() => handleOpenJobModal(id)}
        >
          <div
            className={classNames('kanban-item', {
              'border-danger': deadlineState.isExpired,
            })}
          >
            <div className="kanban-item-title">
              <h6 className="title">{title}</h6>
              {assignee && <JobCardAssignees assignee={assignee} assigner={assigner} />}
            </div>
            <div className="kanban-item-text">
              <PlaceOutlined fontSize="small" /> {country}
            </div>
            <JobPrice
              fixedPrice={fixed_price}
              fixedPriceCurrency={fixed_price_currency}
              hourlyRangeFrom={hourly_range_from}
              hourlyRangeToCurrency={hourly_range_from_currency}
              hourlyRangeTo={hourly_range_to}
              hourlyRangeFromCurrency={hourly_range_to_currency}
            />
            <div className="kanban-item-text">
              <p style={{ overflowWrap: 'break-word' }}>{cutString(100, description)}</p>
            </div>
            <ul className="kanban-item-tags">
              {skills?.slice(0, 3).map((skill) => (
                <li key={skill}>
                  {!!skill && (
                    <CustomBadge>
                      <Typography fontSize={13} fontWeight={400}>
                        {skill}
                      </Typography>
                    </CustomBadge>
                  )}
                </li>
              ))}
            </ul>
            <div className="kanban-item-meta">
              <ul className="kanban-item-meta-list">
                {is_returned_from_archive && (
                  <li className="d-flex align-center" style={{ fontSize: '13px' }}>
                    <UTurnLeft fontSize="inherit" className="me-1" sx={{ marginBottom: '4px', color: '#e85347' }} />

                    <Typography fontSize={13} fontWeight={400} color="#e85347">
                      Returned from archive
                    </Typography>
                  </li>
                )}

                {dead_line_date && <JobCardDeadlineDate deadlineLabel={deadlineLabel} deadlineState={deadlineState} />}

                <li style={{ fontSize: '13px' }}>
                  <Notes fontSize="inherit" className="me-1" sx={{ marginBottom: '4px' }} />
                  <span>{category}</span>
                </li>
              </ul>
              <JobCardActions id={id} column={column} jobs={jobs} setJobs={setJobs} />
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};
