import Swal from 'sweetalert2';
import { useDeleteRSSLinkMutation } from '../../../../app/company/api';
import { removeRssLink } from '../../../../app/jobs/slice';
import { UUID } from '../../../../types/types/UUID';
import { useAppDispatch } from './../../../../app/hooks';

export const useDeleteRSSLink = () => {
  const dispatch = useAppDispatch();

  const [deleteRSSLink, { isLoading, isError, isSuccess, originalArgs }] = useDeleteRSSLinkMutation();
  const handleDeleteRSSLink = async (id: UUID) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      preConfirm: async () => {
        try {
          return await deleteRSSLink({ id });
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`);
        }
      },
      allowOutsideClick: () => !isLoading,
    });
    if (result.isConfirmed) {
      await Swal.fire({
        title: 'Deleted!',
        text: 'RSS link has been deleted.',
        icon: 'success',
      });

      dispatch(removeRssLink({ id }));
    }
  };

  return {
    handleDeleteRSSLink,
    isDeleteRssLinkLoading: isLoading,
    isDeleteRssLinkError: isError,
    isDeleteRssLinkSuccess: isSuccess,
    deleteRssLinkArgs: originalArgs,
  };
};
