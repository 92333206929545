import { Menu } from '@mui/icons-material';
import { useContext } from 'react';
import { Card } from 'reactstrap';
import Swal from 'sweetalert2';
import { useForgotPasswordMutation } from '../../../../app/auth/api';
import { useAppSelector } from '../../../../app/hooks';
import { userProfileSelector } from '../../../../app/user/selectors';
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
} from '../../../../theme-components/Component';
import { UserProfileContext } from '../../UserProfileContext';

export const SecuritySettings = () => {
  const { isProfileSidebarOpen, toggleProfileSidebarOpen } = useContext(UserProfileContext);
  const userProfile = useAppSelector(userProfileSelector);

  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();

  const handleChangePassword = async () => {
    const result = await Swal.fire({
      title: `Changing password`,
      text: "We'll email you instructions to reset your password.",
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      preConfirm: async () => {
        try {
          return await forgotPassword({ email: userProfile?.email });
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`);
        }
      },
      allowOutsideClick: () => !isLoading,
    });
    if (result.isConfirmed) {
      await Swal.fire({
        title: 'Email has been sent!',
        text: 'Follow instructions in order to proceed.',
        icon: 'success',
      });
    }
  };
  return (
    <>
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Security Settings</BlockTitle>
            <BlockDes>
              <p>These settings will help you to keep your account secure.</p>
            </BlockDes>
          </BlockHeadContent>
          <BlockHeadContent className="align-self-start d-lg-none">
            <Button
              className={`toggle btn btn-icon btn-trigger mt-n1 ${isProfileSidebarOpen ? 'active' : ''}`}
              onClick={toggleProfileSidebarOpen}
            >
              <Menu fontSize="small" className="mx-1" />
            </Button>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <Block>
        <Card className="card-bordered">
          <div className="card-inner-group">
            <div className="card-inner">
              <div className="between-center flex-wrap g-3">
                <div className="nk-block-text">
                  <h6>Change Password</h6>
                  <span></span>
                  <p>Set a unique password to protect your account.</p>
                </div>
                <div className="nk-block-actions flex-shrink-sm-0">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-3 gy-2">
                    <li className="order-md-last">
                      <Button color="primary" onClick={handleChangePassword}>
                        Change Password
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </Block>
    </>
  );
};
