import { ErrorOutlineOutlined } from '@mui/icons-material';
import { useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Spinner, Form, Alert } from 'reactstrap';
import { resetTokens } from '../../../app/auth/slice';
import { useAppDispatch } from '../../../app/hooks';
import { PasswordInput } from '../../../components/PasswordInput';
import { ROUTES } from '../../../router/routes';
import { Button } from '../../../theme-components/Component';
import AuthFormCard from './../layout/AuthFormCard';
import { useResetPasswordForm } from './hooks';

export const ResetPassword = () => {
  const [params] = useSearchParams();
  const dispatch = useAppDispatch();
  const { register, handleSubmit, errors, isResetPasswordLoading, handleResetPassword, errorMessage } =
    useResetPasswordForm();

  useEffect(() => {
    dispatch(resetTokens());
  }, []);

  return (
    <AuthFormCard
      headTitle="Reset-Password"
      title="Reset password"
      description="Please enter your new password in order to proceed."
    >
      {!params.get('token') ? (
        <div className="mb-3">
          <Alert className="alert-icon" color="danger">
            <ErrorOutlineOutlined fontSize="inherit" className="me-1 icon" />
            Invalid token, can not proceed with password reset. Please try to reset your password again.
          </Alert>
        </div>
      ) : (
        <>
          {errorMessage && (
            <div className="mb-3">
              <Alert color="danger" className="alert-icon">
                <ErrorOutlineOutlined fontSize="inherit" className="me-1 icon" /> {errorMessage}
              </Alert>
            </div>
          )}
          <Form className="is-alter" onSubmit={handleSubmit(handleResetPassword)}>
            <PasswordInput register={register} errors={errors.password} label="Password" id="password" />
            <PasswordInput
              register={register}
              errors={errors.confirmPassword}
              label="Confirm Password"
              id="confirmPassword"
            />

            <div className="form-group">
              <Button color="primary" size="lg" className="btn-block" type="submit">
                {isResetPasswordLoading ? <Spinner size="sm" color="light" /> : 'Reset Password'}
              </Button>
            </div>
          </Form>
        </>
      )}
      <div className="form-note-s2 text-center pt-4">
        <Link to={ROUTES.AUTH.LOGIN}>
          <strong>Return to login</strong>
        </Link>
      </div>
    </AuthFormCard>
  );
};
