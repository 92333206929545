import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { User } from './../../types/types/User';

import { REDUCER_AUTH_SLICE, REDUCER_USER_SLICE } from './../constants';
import { userApi } from './api';

type UserSlice = {
  userProfile: User;
  oldUser: User;
};

const userPersistConfig = {
  key: REDUCER_USER_SLICE,
  storage,
};

const initialState: UserSlice = {
  userProfile: {} as User,
  oldUser: {} as User,
};

const userSlice = createSlice({
  name: REDUCER_AUTH_SLICE,
  initialState,
  reducers: {
    setOldUser: (state, action: PayloadAction<User>) => {
      state.oldUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(userApi.endpoints.getUserProfile.matchFulfilled, (state, action) => {
      state.userProfile = action.payload;
    });
  },
});

export const { setOldUser } = userSlice.actions;

export default persistReducer(userPersistConfig, userSlice.reducer);
