import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { Authorisation } from '../../types';
import { REDUCER_AUTH_SLICE } from './../constants';
import { authApi } from './api';

const authPersistConfig = {
  key: REDUCER_AUTH_SLICE,
  storage,
};

const initialState: Authorisation = {
  refresh: '',
  access: '',
};

const authSlice = createSlice({
  name: REDUCER_AUTH_SLICE,
  initialState,
  reducers: {
    setTokens(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    resetTokens(state) {
      state.refresh = '';
      state.access = '';
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.logout.matchFulfilled, (state) => {
      state.refresh = '';
      state.access = '';
    });
  },
});

export const { setTokens, resetTokens } = authSlice.actions;

export default persistReducer(authPersistConfig, authSlice.reducer);
