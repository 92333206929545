import { useState } from 'react';

type CopyFn = (text: string) => Promise<void>;

export const useCopyToClipboard = () => {
  const [isTextCopied, setIsTextCopied] = useState<boolean>(false);

  const copy: CopyFn = async (text) => {
    if (!navigator?.clipboard) {
      console.warn('Clipboard not supported');
      return;
    }

    try {
      await navigator.clipboard.writeText(text);
      setIsTextCopied(true);
    } catch (error) {
      console.warn('Copy failed', error);
      setIsTextCopied(false);
    }

    setTimeout(() => {
      setIsTextCopied(false);
    }, 1000);
  };

  return { isTextCopied, copy };
};
