import { ExpandMore, Lock, Logout, NotificationsOutlined, PersonOutlined } from '@mui/icons-material';
import Gleap from 'gleap';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DropdownToggle, DropdownMenu, Dropdown } from 'reactstrap';
import { useLogoutMutation } from '../../../../app/auth/api';
import { userRefreshTokenSelector } from '../../../../app/auth/selectors';
import { selectCurrentCompany } from '../../../../app/company/selctors';
import { clearCurrentCompany } from '../../../../app/company/slice';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { clearAwaitingCoverLetterJobs, clearRssLinks } from '../../../../app/jobs/slice';
import { useGetUserProfileQuery } from '../../../../app/user/api';
import { setOldUser } from '../../../../app/user/slice';
import { UserRoleMap } from '../../../../constants';
import { useToggle } from '../../../../hooks';
import { ROUTES } from '../../../../router/routes';
import { Button } from '../../../../theme-components/Component';
import { LinkList, LinkItem } from '../../../../theme-components/links/Links';
import UserAvatar from '../../../../theme-components/user/UserAvatar';
import { cutString } from '../../../../utils/CutString';

const User = () => {
  const dispatch = useAppDispatch();

  const [isDropdownOpen, toggleDropdownOpen] = useToggle(false);

  const refreshToken = useSelector(userRefreshTokenSelector);
  const currentCompany = useAppSelector(selectCurrentCompany);

  const [logout, { isSuccess: isLogoutSuccess }] = useLogoutMutation();

  const { data: userProfileData, isSuccess } = useGetUserProfileQuery();

  const handleSignOut = async () => {
    logout({ refresh: refreshToken });
    userProfileData && dispatch(setOldUser(userProfileData));
    dispatch(clearRssLinks());
  };

  useEffect(() => {
    if (isLogoutSuccess) {
      dispatch(clearCurrentCompany());
      dispatch(clearAwaitingCoverLetterJobs());
      Gleap.clearIdentity();
    }
  }, [isLogoutSuccess]);

  if (!isSuccess) return;

  const { first_name, last_name, email, role } = userProfileData;

  return (
    <Dropdown
      isOpen={isDropdownOpen}
      className="user-dropdown"
      toggle={(e: React.KeyboardEvent | React.MouseEvent) => {
        e.stopPropagation();
        toggleDropdownOpen();
      }}
    >
      <DropdownToggle tag="a" href="#toggle" className="dropdown-toggle">
        <div className="user-toggle">
          <UserAvatar icon={<PersonOutlined fontSize="small" />} className="sm" />
          <div className="user-info d-none d-md-block">
            <div className="user-status">{`${UserRoleMap[role]} ${
              !!currentCompany && `in ${cutString(15, currentCompany.title)}`
            }`}</div>
            <div className="user-name">
              {cutString(24, first_name + ' ' + last_name)}
              <ExpandMore fontSize="inherit" />
            </div>
          </div>
        </div>
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-md dropdown-menu-s1">
        <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
          <div className="user-card sm">
            <div className="user-avatar">
              <span>
                {first_name[0]}
                {last_name[0]}
              </span>
            </div>
            <div className="user-info">
              <span className="lead-text">{cutString(24, first_name + ' ' + last_name)}</span>
              <span className="sub-text">{email}</span>
            </div>
          </div>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            <LinkItem
              link={ROUTES.PROFILE.PERSONAL_INFORMATION}
              icon={<PersonOutlined fontSize="small" className="me-1" />}
            >
              View Profile
            </LinkItem>
            <LinkItem
              link={ROUTES.PROFILE.NOTIFICATIONS}
              icon={<NotificationsOutlined fontSize="small" className="me-1" />}
            >
              Notifications
            </LinkItem>
            <LinkItem link={ROUTES.PROFILE.SECURITY_SETTINGS} icon={<Lock fontSize="small" className="me-1" />}>
              Security settings
            </LinkItem>
          </LinkList>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            <Button className="px-0" onClick={handleSignOut}>
              <Logout fontSize="small" className="me-1" />
              <span>Sign Out</span>
            </Button>
          </LinkList>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default User;
