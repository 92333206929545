import Swal from 'sweetalert2';
import { useEditJobMutation } from '../../../../../../app/jobs/api';
import { JobStatusEnum, Job } from '../../../../../../types';
import { useJobModal } from './../../../hooks/useJobModal';

interface IConfirmWonStatusProps {
  job: Job;
  jobs?: Record<string, Job[] | []>;
  setJobs?: React.Dispatch<React.SetStateAction<Record<string, Job[] | []>>>;
}

export const useConfirmWonStatus = ({ job, jobs, setJobs }: IConfirmWonStatusProps) => {
  const { handleCloseJobModal } = useJobModal();
  const [updateJob] = useEditJobMutation();

  const handleWonStatus = async () => {
    const result = await Swal.fire({
      title: 'Are you sure to change status to Won?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, proceed',
      preConfirm: async () => {
        try {
          Swal.showLoading();
          return await updateJob({ id: job.id, status: JobStatusEnum.Won });
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`);
        } finally {
          Swal.hideLoading();
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
    if (result.isConfirmed) {
      handleCloseJobModal();
      await Swal.fire({
        title: 'Done',
        text: 'Job status has been changed to Won.',
        icon: 'success',
      });

      if (jobs && setJobs) {
        setJobs({ ...jobs, [job.column]: jobs[job.column].filter((j) => j.id !== job.id) });
      }
    }
  };

  return { handleWonStatus };
};
