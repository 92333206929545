import Swal from 'sweetalert2';
import { useDeleteJobMutation } from '../../../../app/jobs/api';
import { ColumnEnum, Job } from '../../../../types';
import { UUID } from '../../../../types/types/UUID';
import { useJobModal } from './useJobModal';

type UseDeleteJobParams = {
  id: UUID;
  column: ColumnEnum;
  jobs?: Record<string, Job[] | []>;
  setJobs?: React.Dispatch<React.SetStateAction<Record<string, Job[] | []>>>;
};

export const useDeleteJob = ({ id, column, jobs, setJobs }: UseDeleteJobParams) => {
  const [deleteJob, { isLoading }] = useDeleteJobMutation();
  const { handleCloseJobModal } = useJobModal();

  const handleDeleteJob = async () => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      preConfirm: async () => {
        try {
          return await deleteJob({ id, column });
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`);
        }
      },
      allowOutsideClick: () => !isLoading,
    });
    if (result.isConfirmed) {
      if (jobs && setJobs) {
        setJobs({ ...jobs, [column]: jobs[column].filter((j) => j.id !== id) });
      }

      handleCloseJobModal();
      await Swal.fire({
        title: 'Deleted!',
        text: 'Your job has been deleted.',
        icon: 'success',
      });
    }
  };

  return {
    handleDeleteJob,
  };
};
