import { ColumnEnum, Job } from '../../../../../../../types';
import { UUID } from '../../../../../../../types/types/UUID';
import { JobStage } from './JobStage';
import styles from './styles.module.scss';

type JobStagesProps = {
  id: UUID;
  column: ColumnEnum;
  jobs?: Record<string, Job[] | []>;
  setJobs?: React.Dispatch<React.SetStateAction<Record<string, Job[] | []>>>;
  isArchive?: boolean;
};

export const JobStages = ({ id, column, jobs, setJobs, isArchive }: JobStagesProps) => {
  const stages = [
    ColumnEnum.Like,
    ColumnEnum.Sent,
    ColumnEnum.AnswerReceived,
    ColumnEnum.CallMade,
    ColumnEnum.Contract,
  ];
  return (
    <ul className={styles.stages}>
      {stages.map((stage) => (
        <JobStage
          key={stage}
          id={id}
          currentColumn={column}
          column={stage}
          jobs={jobs}
          setJobs={setJobs}
          isArchive={isArchive}
        />
      ))}
    </ul>
  );
};
