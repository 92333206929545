import { CalendarMonth } from '@mui/icons-material';

type JobCardDeadlineDateProps = {
  deadlineLabel: string;
  deadlineState: {
    type: string;
    isExpired: boolean;
  };
};

export const JobCardDeadlineDate = ({ deadlineLabel, deadlineState }: JobCardDeadlineDateProps) => {
  return (
    <li className={`text-${deadlineState.type}`}>
      <CalendarMonth fontSize="inherit" className="me-1" sx={{ marginBottom: '4px' }} />
      <span>{deadlineLabel}</span>
    </li>
  );
};
