import classNames from 'classnames';
import { MouseEventHandler, useEffect } from 'react';
import { useEditJobMutation } from '../../../../../../../app/jobs/api';
import { ColumnEnum, Job } from '../../../../../../../types';
import { UUID } from '../../../../../../../types/types/UUID';
import { Button } from '../../../../../../Component';
import styles from './styles.module.scss';

type JobStageProps = {
  id: UUID;
  currentColumn: ColumnEnum;
  column: ColumnEnum;
  jobs?: Record<string, Job[] | []>;
  setJobs?: React.Dispatch<React.SetStateAction<Record<string, Job[] | []>>>;
  isArchive?: boolean;
};

export const JobStage = ({ id, currentColumn, column, jobs, setJobs, isArchive }: JobStageProps) => {
  const [editJob, { isSuccess }] = useEditJobMutation();

  const handleColumnChange: MouseEventHandler = (e) => {
    e.preventDefault();
    if (column === currentColumn || isArchive) return;
    editJob({ id, column, destinationColumn: column, sourceColumn: currentColumn });
  };

  useEffect(() => {
    if (isSuccess && setJobs && jobs) {
      const currentJob = jobs[currentColumn].find((j) => j.id === id);

      if (currentJob) {
        setJobs({
          ...jobs,
          [currentColumn]: jobs[currentColumn].filter((j) => j.id !== id),
          [column]: [...jobs[column], currentJob],
        });
      }
    }
  }, [isSuccess]);

  return (
    <li
      className={classNames({
        [`${styles.current}`]: currentColumn === column,
      })}
    >
      <Button onClick={handleColumnChange}>{column}</Button>
    </li>
  );
};
