import { yupResolver } from '@hookform/resolvers/yup';
import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Col, ModalBody } from 'reactstrap';
import Swal from 'sweetalert2';
import * as yup from 'yup';
import { selectCurrentCompany } from '../../../app/company/selctors';
import { useAppSelector } from '../../../app/hooks';

import { useInviteOperatorMutation } from '../../../app/user/api';
import { InviteOperatorFormValues, RoleEnum } from '../../../types';

type InviteOperatorKey = keyof InviteOperatorFormValues;

interface IField {
  name: InviteOperatorKey;
  label: string;
  required?: string;
}

interface IInviteOperatorProps {
  close(): void;
}

const inviteOperatorSchema: yup.SchemaOf<InviteOperatorFormValues> = yup
  .object()
  .shape({
    email: yup.string().trim().email('Please provide a valid email address').required('Email is required'),
    first_name: yup.string().trim().required('First name is required'),
    last_name: yup.string().trim().required('Last name is required'),
    role: yup.mixed<RoleEnum>().oneOf(Object.values(RoleEnum)).required('Role is required'),
  })
  .required();

export const InviteOperatorForm: FC<IInviteOperatorProps> = ({ close }) => {
  const currentCompany = useAppSelector(selectCurrentCompany);

  const {
    formState: { errors, defaultValues },
    register,
    handleSubmit,
    setValue,
  } = useForm({
    resolver: yupResolver(inviteOperatorSchema),
    defaultValues: {
      email: '',
      first_name: '',
      last_name: '',
      role: RoleEnum.Operator,
    },
  });

  const fields: IField[] = [
    {
      name: 'email',
      label: 'Email',
      required: 'This field is required',
    },
    {
      name: 'first_name',
      label: 'First name',
      required: 'This field is required',
    },
    {
      name: 'last_name',
      label: 'Last name',
    },
  ];

  const [inviteOperator, { isLoading, isSuccess, isError, error }] = useInviteOperatorMutation();

  const sendFormHandler = (formValues: InviteOperatorFormValues) => {
    if (!currentCompany) {
      return;
    }
    inviteOperator({ ...formValues, company: currentCompany?.id });
  };

  useEffect(() => {
    if (isSuccess) {
      close();
      if (defaultValues) {
        Object.keys(defaultValues).forEach((key) => setValue(key as InviteOperatorKey, ''));
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError && error && 'data' in error) {
      Swal.fire({
        title: 'User invitation failed!',
        text:
          (error?.data as { code?: string })?.code === 'user_exists'
            ? 'This user is already invited to the company'
            : 'User invitation failed, please try again later',
        icon: 'error',
      });
    }
  }, [isError, error]);

  return (
    <ModalBody>
      <h5 className="title">Invite operator</h5>

      <form className="row gy-4 mt-2" onSubmit={handleSubmit(sendFormHandler)}>
        {fields.map(({ name, label }) => (
          <Col sm="12" key={name}>
            <div className="form-group">
              <label className="form-label">{label}</label>
              <input type="text" disabled={isLoading} className="form-control" {...register(name)} />
              {errors[name] && <span className="invalid">{errors[name]?.message as string}</span>}
            </div>
          </Col>
        ))}

        <Col sm="12">
          <div className="form-group">
            <label className="form-label">Role</label>
            <div className="d-flex">
              <div className="d-flex align-center me-4">
                <input
                  className="me-1"
                  {...register('role')}
                  type="radio"
                  disabled={isLoading}
                  value={RoleEnum.Admin}
                  id={RoleEnum.Admin}
                />
                <label className="" htmlFor={RoleEnum.Admin}>
                  Admin
                </label>
              </div>
              <div className="d-flex align-center">
                <input
                  className="me-1"
                  {...register('role')}
                  type="radio"
                  disabled={isLoading}
                  value={RoleEnum.Operator}
                  id={RoleEnum.Operator}
                />
                <label className="" htmlFor={RoleEnum.Operator}>
                  Operator
                </label>
              </div>
            </div>

            {errors.role && <span className="invalid">{errors.role?.message as string}</span>}
          </div>
        </Col>

        <ul className="d-flex justify-content-between mt-5">
          <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gap-2">
            <li>
              <Button color="primary" type="submit" disabled={isLoading}>
                Invite
              </Button>
            </li>

            <li>
              <Button type="reset" disabled={isLoading} onClick={close} color="secondary">
                Cancel
              </Button>
            </li>
          </ul>
        </ul>
      </form>
    </ModalBody>
  );
};
