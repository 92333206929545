import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import LogoImage from '../../images/logo_transparent.png';

const Logo = ({ className }) => {
  return (
    <Link to={`/`} className="logo-link">
      <img
        className={classNames({
          [`${className}`]: className,
          'logo-img': true,
        })}
        src={LogoImage}
        alt="logo"
      />
    </Link>
  );
};

export default Logo;
