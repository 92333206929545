import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorOutlineOutlined } from '@mui/icons-material';
import { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { Alert, Spinner } from 'reactstrap';
import * as yup from 'yup';
import { useRegisterMutation } from '../../app/auth/api';
import { PasswordInput } from '../../components/PasswordInput';
import { ROUTES } from '../../router/routes';
import { Button } from '../../theme-components/Component';
import AuthFormCard from './layout/AuthFormCard';

const registrationSchema = yup
  .object()
  .shape({
    email: yup.string().trim().email('Please provide a valid email address').required('Email is required'),
    firstName: yup.string().trim().required('First name is required'),
    lastName: yup.string().trim().required('Last name is required'),
    companyTitle: yup.string().trim().required('Company title is required'),
    password: yup
      .string()
      .trim()
      .min(
        8,
        'Password must contain 8 or more characters with at least one of each: uppercase, lowercase, number & symbol',
      )
      .matches(/[0-9]/, 'Password requires a number')
      .matches(/[a-z]/, 'Password requires a lowercase letter')
      .matches(/[A-Z]/, 'Password requires an uppercase letter')
      .matches(/[^\w]/, 'Password requires a symbol')
      .required(),
  })
  .required();

const Register = () => {
  const [errorVal, setErrorVal] = useState('');
  const [registerUser, { isLoading: loading }] = useRegisterMutation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(registrationSchema),
    defaultValues: {
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      companyTitle: '',
    },
  });

  useEffect(() => {
    if (errorVal) {
      const subscription = watch(() => setErrorVal(''));
      return () => subscription.unsubscribe();
    }
  }, [watch, errorVal]);

  const handleFormSubmit = async ({ firstName, lastName, companyTitle, ...formValues }) => {
    try {
      const response = await registerUser({
        ...formValues,
        first_name: firstName,
        last_name: lastName,
        company_title: companyTitle,
      });
      if (response?.error?.status === 400 || response?.error?.status === 500) {
        throw response.error.data.code;
      }
      navigate(ROUTES.AUTH.SUCCESS, {
        state: {
          type: 'register',
        },
      });
    } catch (error) {
      if (error === 'user_exists') {
        setErrorVal('User with provided email is already exist');
      }
    }
  };

  return (
    <AuthFormCard headTitle="Register" description="Create New  Account">
      <Fragment>
        {errorVal && (
          <div className="mb-3">
            <Alert color="danger" className="alert-icon">
              <ErrorOutlineOutlined fontSize="inherit" className="me-1 icon" /> {errorVal}
            </Alert>
          </div>
        )}
        <form className="is-alter" onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="form-group">
            <label className="form-label" htmlFor="firstName">
              First Name
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                id="firstName"
                name="firstName"
                autoComplete="given-name"
                placeholder="Enter your name"
                {...register('firstName')}
                className="form-control-lg form-control"
              />
              {errors?.firstName && <p className="invalid">{errors.firstName.message}</p>}
            </div>
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="lastName">
              Last Name
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                id="lastName"
                name="lastName"
                autoComplete="family-name"
                placeholder="Enter your last name"
                {...register('lastName')}
                className="form-control-lg form-control"
              />
              {errors?.lastName && <p className="invalid">{errors.lastName.message}</p>}
            </div>
          </div>
          <div className="form-group">
            <div className="form-label-group">
              <label className="form-label" htmlFor="email">
                Email
              </label>
            </div>
            <div className="form-control-wrap">
              <input
                type="text"
                id="email"
                name="email"
                autoComplete={'email'}
                {...register('email')}
                className="form-control-lg form-control"
                placeholder="Enter your email address"
              />
              {errors?.email && <p className="invalid">{errors.email.message}</p>}
            </div>
          </div>
          <div className="form-group">
            <div className="form-label-group">
              <label className="form-label" htmlFor="companyTitle">
                Company
              </label>
            </div>
            <div className="form-control-wrap">
              <input
                type="text"
                id="companyTitle"
                name="companyTitle"
                autoComplete={'organization'}
                {...register('companyTitle')}
                className="form-control-lg form-control"
                placeholder="Enter your company name"
              />
              {errors?.companyTitle && <p className="invalid">{errors.companyTitle.message}</p>}
            </div>
          </div>
          <PasswordInput register={register} errors={errors?.password} label="Password" id="password" />

          <div className="form-group">
            <Button type="submit" color="primary" size="lg" className="btn-block">
              {loading ? <Spinner size="sm" color="light" /> : 'Register'}
            </Button>
          </div>
        </form>
        <div className="form-note-s2 text-center pt-4">
          {' '}
          Already have an account?{' '}
          <Link to={ROUTES.AUTH.LOGIN}>
            <strong>Sign in instead</strong>
          </Link>
        </div>
      </Fragment>
    </AuthFormCard>
  );
};
export default Register;
