import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { Job, RssLink, SelectOption, UWJob } from '../../types';
import { REDUCER_JOBS_SLICE } from './../constants';
import { JobsActionsEnum } from './actions';

interface IJobsSliceState {
  deck: (UWJob | Job)[];
  stash: Job[];
  temp: UWJob | Job | null;
  selectedRssLinks: SelectOption[];
  awaitingCoverLetter: string[];
}

const jobsPersistConfig = {
  key: REDUCER_JOBS_SLICE,
  storage,
  blacklist: ['selectedRssLinks'],
};

const initialState: IJobsSliceState = {
  deck: [],
  stash: [],
  temp: null,
  selectedRssLinks: [],
  awaitingCoverLetter: [],
};

const jobsSlice = createSlice({
  name: REDUCER_JOBS_SLICE,
  initialState,
  reducers: {
    [JobsActionsEnum.addJobsToDeck]: (state, action: PayloadAction<{ jobs: (UWJob | Job)[] | [] }>) => {
      state.deck = action.payload.jobs;
    },
    [JobsActionsEnum.removeJobFromDeck]: (state, action: PayloadAction<{ job: UWJob | Job }>) => {
      state.deck = state.deck.filter((job) => job?.upwork_id !== action.payload?.job?.upwork_id);
    },
    [JobsActionsEnum.setTempJob]: (state, action: PayloadAction<{ job: UWJob | Job }>) => {
      state.temp = action.payload.job;
    },
    [JobsActionsEnum.clearTempJob]: (state) => {
      state.temp = null;
    },
    [JobsActionsEnum.clearStash]: (state) => {
      state.stash = [];
    },
    [JobsActionsEnum.addJobToStash]: (state, action: PayloadAction<Job>) => {
      if (state.stash.length < 5) {
        state.stash = [action.payload, ...state.stash];
        return;
      }
      state.stash = [action.payload, ...state.stash.slice(0, -1)];
    },
    [JobsActionsEnum.returnJobFromStash]: (state) => {
      if (state.stash.length > 0) {
        const lastDislikedJob = state.stash[0];

        state.stash = state.stash.filter((job) => job.id !== lastDislikedJob.id);
        state.deck = [...state.deck, lastDislikedJob];
      }
    },
    [JobsActionsEnum.setRssLinks]: (state, action: PayloadAction<SelectOption[]>) => {
      state.selectedRssLinks = action.payload;
    },
    [JobsActionsEnum.editRssLink]: (state, action: PayloadAction<RssLink>) => {
      const editedLinkIndex = state.selectedRssLinks.findIndex(({ value }) => value === action.payload.id);

      if (editedLinkIndex >= 0) {
        state.selectedRssLinks[editedLinkIndex].label = action.payload.name;
      }
    },
    [JobsActionsEnum.removeRssLink]: (state, action: PayloadAction<Pick<RssLink, 'id'>>) => {
      state.selectedRssLinks = state.selectedRssLinks.filter(({ value }) => value !== action.payload.id);
    },
    [JobsActionsEnum.clearRssLinks]: (state) => {
      state.selectedRssLinks = [];
    },
    [JobsActionsEnum.addAwaitingCoverLetterJob]: (state, action: PayloadAction<string>) => {
      state.awaitingCoverLetter = [...state.awaitingCoverLetter, action.payload];
    },
    [JobsActionsEnum.removeAwaitingCoverLetterJob]: (state, action: PayloadAction<string>) => {
      state.awaitingCoverLetter = state.awaitingCoverLetter.filter((id) => id !== action.payload);
    },
    [JobsActionsEnum.clearAwaitingCoverLetterJobs]: (state) => {
      state.awaitingCoverLetter = [];
    },
  },
});

export const {
  addJobsToDeck,
  removeJobFromDeck,
  addJobToStash,
  returnJobFromStash,
  setTempJob,
  clearTempJob,
  clearStash,
  setRssLinks,
  clearRssLinks,
  editRssLink,
  removeRssLink,
  addAwaitingCoverLetterJob,
  removeAwaitingCoverLetterJob,
  clearAwaitingCoverLetterJobs,
} = jobsSlice.actions;

export default persistReducer(jobsPersistConfig, jobsSlice.reducer);
