import { Container } from '@mui/material';
import styled from 'styled-components';

export const DeckContainer = styled(Container)`
  text-align: center;
  margin-top: 74px;
`;

export const DeckGrid = styled.div`
  display: grid;
  grid-template-columns: 60px 1fr 60px;
  gap: 20px;
  align-items: center;
  height: calc(100vh - 114px);
`;

export const ThumbButton = styled.button<{ isLike?: boolean }>`
  font-size: 20px;
  width: 60px;
  height: 60px;
  border: none;
  outline: none;
  border-radius: 50%;
  color: #ffffff;
  background-color: ${({ isLike }) => (isLike ? '#40db50' : '#db4a40')};
  transition: opacity 0.4s;

  &:hover {
    opacity: 0.7;
  }
`;
