import {
  InviteOperatorRequestParams,
  PaginationRequestParams,
  ResponseWithPagination,
  User,
  CreateDeviceRequestParams,
} from '../../types';
import { UUID } from '../../types/types/UUID';
import { API_ENDPOINTS } from '../endpoints';
import { baseApi } from './../baseApi';

export const userApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getCompanyStaff: build.query<ResponseWithPagination<User>, PaginationRequestParams>({
      query: (params) => ({
        url: API_ENDPOINTS.USER.USERS,
        params,
      }),
      providesTags: ['Staff'],
    }),
    inviteOperator: build.mutation<{ msg: string }, InviteOperatorRequestParams>({
      query: (body) => ({
        url: API_ENDPOINTS.USER.INVITE,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Staff'],
    }),
    deleteOperator: build.mutation<void, { id: UUID }>({
      query: ({ id }) => ({
        url: API_ENDPOINTS.USER.DELETE(id),
        method: 'DELETE',
      }),
      invalidatesTags: ['Staff', 'AssigneesList'],
    }),
    getUsers: build.query<User[], { isActive?: boolean }>({
      query: ({ isActive }) => ({
        url: API_ENDPOINTS.USER.LIST,
        params: isActive ? { is_active: true } : {},
      }),
      providesTags: ['Users'],
      transformResponse: (users: User[]) => {
        return users.map((user) => ({
          ...user,
          firstName: user.first_name,
          lastName: user.last_name,
        }));
      },
    }),
    getUserProfile: build.query<User, void>({
      query: () => ({
        url: API_ENDPOINTS.USER.PROFILE,
      }),
      providesTags: ['User'],
      transformResponse: (userProfileData: User) => {
        return {
          ...userProfileData,
          firstName: userProfileData.first_name,
          lastName: userProfileData.last_name,
        };
      },
    }),
    updateUserProfile: build.mutation<User, Partial<User> & { id: UUID }>({
      query: ({ id, ...userData }) => ({
        url: API_ENDPOINTS.USER.UPDATE(id),
        method: 'PATCH',
        body: userData,
      }),
      invalidatesTags: ['User', 'Staff'],
    }),
    createDevice: build.mutation<unknown, CreateDeviceRequestParams>({
      query: (body) => ({
        url: API_ENDPOINTS.NOTIFICATION.CREATE,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetCompanyStaffQuery,
  useGetUserProfileQuery,
  useUpdateUserProfileMutation,
  useInviteOperatorMutation,
  useCreateDeviceMutation,
  useDeleteOperatorMutation,
} = userApi;
