import { UTurnLeft } from '@mui/icons-material';
import { Box } from '@mui/material';
import React, { FC, useState, useEffect } from 'react';

import { useAppDispatch } from '../../app/hooks';

import { clearStash, returnJobFromStash, setRssLinks } from '../../app/jobs/slice';

import { RSelect } from '../../theme-components/Component';

import { RssLink, SelectOption } from '../../types';
import { cutString } from '../../utils/CutString';

import { DeckHeaderContainer, ReturnButton } from './DeckHeader.style';

interface IDeckHeaderProps {
  fetchedLinks: RssLink[] | undefined;
  isLinksLoading: boolean;
  disableReturn: boolean;
  selectedLinks: SelectOption[];
}

export const DeckHeader: FC<IDeckHeaderProps> = ({ fetchedLinks, isLinksLoading, disableReturn, selectedLinks }) => {
  const dispatch = useAppDispatch();

  const [links, setLinks] = useState<SelectOption[]>([]);

  useEffect(() => {
    if (fetchedLinks) {
      const options = (fetchedLinks as RssLink[]).map((link) => ({ value: link.id, label: cutString(25, link.name) }));
      setLinks(options);
    }
  }, [fetchedLinks]);

  return (
    <DeckHeaderContainer isLinksSelectVisible={links.length > 1}>
      {links.length > 1 && (
        <Box sx={{ minWidth: '300px' }}>
          <RSelect
            isClearable
            isMulti
            options={links}
            isDisabled={isLinksLoading}
            defaultValue={selectedLinks}
            value={selectedLinks}
            placeholder="Select rss links"
            onChange={(currentLinks: SelectOption[]) => {
              dispatch(setRssLinks(currentLinks));
              dispatch(clearStash());
            }}
          />
        </Box>
      )}

      <ReturnButton disabled={disableReturn} onClick={() => dispatch(returnJobFromStash())}>
        <UTurnLeft fontSize="inherit" />
      </ReturnButton>
    </DeckHeaderContainer>
  );
};
