import React, { useState } from 'react';
import { OnDragEndResponder, DropResult } from 'react-beautiful-dnd';

import { useChangeJobColumnMutation } from '../../app/jobs/api';

import { Layout } from '../../components/Layout';
import Head from '../../layout/head/Head';

import { Kanban } from '../../theme-components/_complex/kanban';

import { ColumnEnum, Job } from '../../types';

export const KanbanPage = () => {
  const [changeJobColumn] = useChangeJobColumnMutation();

  const [jobs, setJobs] = useState<{ [key: string]: Job[] | [] }>({
    [ColumnEnum.Like]: [],
    [ColumnEnum.Sent]: [],
    [ColumnEnum.AnswerReceived]: [],
    [ColumnEnum.CallMade]: [],
    [ColumnEnum.Contract]: [],
  });

  const columns = [
    {
      id: ColumnEnum.Like,
      text: ColumnEnum.Like,
      theme: 'light',
    },
    {
      id: ColumnEnum.Sent,
      text: ColumnEnum.Sent,
      theme: 'primary',
    },
    {
      id: ColumnEnum.AnswerReceived,
      text: ColumnEnum.AnswerReceived,
      theme: 'warning',
    },
    {
      id: ColumnEnum.CallMade,
      text: ColumnEnum.CallMade,
      theme: 'danger',
    },
    {
      id: ColumnEnum.Contract,
      text: ColumnEnum.Contract,
      theme: 'success',
    },
  ];

  const onDragEnd: OnDragEndResponder = (res: DropResult) => {
    const { source, destination, draggableId } = res;

    if (destination?.droppableId && source.droppableId !== destination?.droppableId) {
      const droppedJob = jobs[source.droppableId]?.find(({ id }) => id === draggableId);

      if (droppedJob) {
        setJobs((state) => ({
          ...state,
          [source.droppableId]: state[source.droppableId]?.filter(({ id }) => id !== draggableId),
          [destination.droppableId]: [...state[destination.droppableId], droppedJob],
        }));

        changeJobColumn({
          id: draggableId,
          sourceColumn: source.droppableId as ColumnEnum,
          destinationColumn: destination.droppableId as ColumnEnum,
        });
      }
    }
  };

  return (
    <Layout>
      <Head title="Kanban" />
      <Kanban columns={columns} dragEndHandler={onDragEnd} jobs={jobs} setJobs={setJobs} />
    </Layout>
  );
};
