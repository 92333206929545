import { useSearchParams } from 'react-router-dom';
import { UUID } from './../../../../types/types/UUID';

export const useJobModal = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleCloseJobModal = () => {
    if (searchParams.has('id')) {
      searchParams.delete('id');
      setSearchParams(searchParams);
    }
  };

  const handleOpenJobModal = (id: UUID) => {
    setSearchParams({ id });
  };

  return {
    searchParams,
    handleCloseJobModal,
    handleOpenJobModal,
  };
};
