export const Colors = {
  white: '#ffffff',
  black: {
    900: '#000000',
    800: '#364a63',
  },
  gold: '#ffe300',
  primary: {
    light: '#798bff',
    dark: '#5664d9',
  },
  green: {
    light: '#1EE0AC',
  },
  red: {
    light: '#E85347',
  },
};
