import { ContentCopyOutlined } from '@mui/icons-material';
import { MouseEventHandler } from 'react';
import { Tooltip } from 'reactstrap';
import { useCopyToClipboard } from '../../../../../../../hooks/useCopyToClipboard';
import { Icon } from '../../../../../../Component';

type JobCopyLinkProps = {
  className: string;
};
export const JobCopyLink = ({ className }: JobCopyLinkProps) => {
  const { isTextCopied, copy } = useCopyToClipboard();

  const handleCopyClick: MouseEventHandler = (e) => {
    e.stopPropagation();
    copy(window.location.href);
  };

  return (
    <>
      <div className={className} onClick={handleCopyClick} id="copyTooltip">
        <ContentCopyOutlined fontSize="inherit" />
      </div>
      <Tooltip placement="top" isOpen={isTextCopied} target="copyTooltip" hideArrow>
        Copied!
      </Tooltip>
    </>
  );
};
