import classNames from 'classnames';

type ButtonProps = {
  color?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'black';
  size?: 'xl' | 'lg' | 'sm' | 'xs';
  outline?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button = ({ type = 'button', color, size, className, outline, disabled, ...props }: ButtonProps) => {
  const buttonClass = classNames({
    btn: true,
    [`btn-${color}`]: !outline,
    [`btn-outline-${color}`]: outline,
    [`btn-${size}`]: size,
    disabled: disabled,
    [`${className}`]: className,
  });
  return (
    <button className={buttonClass} type={type} {...props}>
      {props.children}
    </button>
  );
};
export default Button;
