import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorOutlineOutlined } from '@mui/icons-material';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Alert, Modal, ModalBody, Form, Button } from 'reactstrap';
import * as yup from 'yup';
import { useGetUserProfileQuery, useUpdateUserProfileMutation } from '../../../../app/user/api';
import { Col, Row } from '../../../../theme-components/Component';

const updateUserSchema = yup
  .object({
    firstName: yup.string().trim().min(1, 'First name must contain at least one letter'),
    lastName: yup.string().trim().min(1, 'Last name must contain at least one letter'),
  })
  .required();

type UpdateUserFormType = yup.InferType<typeof updateUserSchema>;

type UpdateUserModalProps = {
  isOpen: boolean;
  toggleOpen: () => void;
};

export const UpdateUserModal = ({ isOpen, toggleOpen }: UpdateUserModalProps) => {
  const { data: userProfileData, isSuccess } = useGetUserProfileQuery();
  const [updateUserProfile, { isError: isUpdateProfileError }] = useUpdateUserProfileMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateUserFormType>({
    resolver: yupResolver(updateUserSchema),
    defaultValues: {
      firstName: userProfileData!.first_name,
      lastName: userProfileData!.last_name,
    },
  });

  const onUpdateProfileSubmit: SubmitHandler<UpdateUserFormType> = async (formData) => {
    await updateUserProfile({
      id: userProfileData!.id,
      first_name: formData.firstName,
      last_name: formData.lastName,
    });
    toggleOpen();
  };

  return (
    <Modal isOpen={isOpen} className="modal-dialog-centered" size="xl" toggle={toggleOpen}>
      <ModalBody>
        <div className="p-2">
          <h5 className="title">Update Profile</h5>

          {isSuccess ? (
            <div className="tab-content">
              {isUpdateProfileError && (
                <Alert color="danger" className="alert-icon">
                  <ErrorOutlineOutlined fontSize="inherit" className="me-1 icon" /> Failed to update profile.
                </Alert>
              )}
              <Form onSubmit={handleSubmit(onUpdateProfileSubmit)}>
                <Row className="gy-4">
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="firstName">
                        First Name
                      </label>
                      <input
                        type="text"
                        id="firstName"
                        className="form-control"
                        placeholder="Enter first name"
                        {...register('firstName')}
                      />
                      {errors?.firstName && <span className="invalid">{errors.firstName.message}</span>}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="lastName">
                        Last Name
                      </label>
                      <input
                        type="text"
                        id="lastName"
                        className="form-control"
                        defaultValue={userProfileData.last_name}
                        {...register('lastName')}
                        placeholder="Enter last name"
                      />
                      {errors?.lastName && <span className="invalid">{errors.lastName.message}</span>}
                    </div>
                  </Col>

                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 mt-2 gap-2">
                      <li>
                        <Button color="primary" type="submit">
                          Update Profile
                        </Button>
                      </li>
                      <li>
                        <Button type="reset" onClick={() => toggleOpen()} color="secondary">
                          Cancel
                        </Button>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Form>
            </div>
          ) : (
            <Alert color="danger" className="alert-icon mt-3">
              <ErrorOutlineOutlined fontSize="inherit" className="me-1 icon" /> Failed to load user profile data.
            </Alert>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};
