import React from 'react';
import { StatisticsInner } from '../../components';
import { Layout } from '../../components/Layout';
import Head from '../../layout/head/Head';

export const Statistics = () => {
  return (
    <Layout>
      <Head title="Statistics" />
      <StatisticsInner />
    </Layout>
  );
};
