import Swal from 'sweetalert2';
import { useDeleteOperatorMutation } from '../../../../app/user/api';
import { User } from './../../../../types/types/User';

export const useDeleteOperator = () => {
  const [deleteOperator, { isLoading }] = useDeleteOperatorMutation();

  const handleDeleteOperator = async ({ id, first_name, last_name }: User) => {
    const result = await Swal.fire({
      title: `Are you sure to delete ${first_name} ${last_name}?`,
      text: "You won't be able to revert this action!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, deactivate user',
      preConfirm: async () => {
        try {
          return await deleteOperator({ id });
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`);
        }
      },
      allowOutsideClick: () => !isLoading,
    });
    if (result.isConfirmed) {
      await Swal.fire({
        title: 'Deleted!',
        text: 'User has been deactivated.',
        icon: 'success',
      });
    }
  };

  return {
    handleDeleteOperator,
  };
};
