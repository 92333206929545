import { Link } from 'react-router-dom';
import Logo from '../../../images/logo_transparent.png';
import Head from '../../../layout/head/Head';
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle, PreviewCard } from '../../../theme-components/Component';

type AuthPageLayoutProps = {
  headTitle: string;
  title?: string;
  description: string;
  children: JSX.Element;
};

export default function AuthFormCard({ headTitle, title = '', description, children }: AuthPageLayoutProps) {
  return (
    <Block size={'middle'} className="nk-auth-body wide-xs my-auto">
      <Head title={headTitle} />
      <div className="brand-logo pb-4 text-center">
        <Link to={'/'} className="logo-link">
          <img className="logo-dark logo-img logo-img-lg logo-img-lg" src={Logo} alt="logo" />
        </Link>
      </div>
      <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
        <BlockHead>
          <BlockContent>
            <BlockTitle tag="h4">{title || headTitle}</BlockTitle>
            <BlockDes>
              <p>{description}</p>
            </BlockDes>
          </BlockContent>
        </BlockHead>
        {children}
      </PreviewCard>
    </Block>
  );
}
