import { Cancel, CheckCircle } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { Col, Card, Badge, Button } from 'reactstrap';
import Swal from 'sweetalert2';
import { baseApi } from '../../app/baseApi';
import { selectCurrentCompany } from '../../app/company/selctors';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useCreateSubscriptionMutation, useGetPricingPlansQuery } from '../../app/subscription/api';
import { userProfileSelector } from '../../app/user/selectors';
import FreePlanIcon from '../../images/icons/plan-s1.svg';
import ProPlanIcon from '../../images/icons/plan-s3.svg';
import { Row } from '../../theme-components/Component';
import { PlanTypeEnum } from '../../types';
import { Loader } from '../Loader';
import { StripeModal } from '../StripeModal';

const FieldsTitles: Record<string, string> = {
  num_of_rss_links: 'Max number of RSS links: ',
  num_of_users: 'Max number of RSS links: ',
  upwork_integration: 'Upwork account connection ',
  openai_features: 'Chat GPT features ',
  swiper_rollback: 'Rollback of disliked jobs ',
};

export const PricingInner = () => {
  const dispatch = useAppDispatch();

  const { data, isLoading, isError } = useGetPricingPlansQuery();
  const [
    createSubscription,
    { data: createSubscriptionData, isLoading: createSubscriptionLoading, isError: createSubscriptionError, error },
  ] = useCreateSubscriptionMutation();

  const currentCompany = useAppSelector(selectCurrentCompany);
  const currentUser = useAppSelector(userProfileSelector);

  const [isStripeModalOpen, setStripeModalOpen] = useState(false);

  const createSubscriptionHandler = (plan: string) => {
    currentCompany && createSubscription({ plan, company: currentCompany.id });
  };

  useEffect(() => {
    if (createSubscriptionData) {
      createSubscriptionData.client_secret
        ? setStripeModalOpen(true)
        : Swal.fire({
            title: 'Plan successfully purchased!',
            text: 'Enjoy your new company pricing plan',
            icon: 'success',
          });

      dispatch(baseApi.util.invalidateTags(['CompaniesList']));
    }
  }, [createSubscriptionData]);

  useEffect(() => {
    if (createSubscriptionError) {
      Swal.fire({
        title: 'Server error!',
        text:
          (error as { data?: { code?: string } })?.data?.code === 'paid_stripe_subscription_already_exists'
            ? 'Current company have been already subscribed to chosen pricing plan. Please select another pricing  plan instead'
            : 'Some error occurred while selecting new pricing plan. Please try again later.',
        icon: 'error',
      });
    }
  }, [createSubscriptionError]);

  useEffect(() => {
    if (isError) {
      Swal.fire({
        title: 'Server error!',
        text: 'Some error occurred while getting data about pricing plans. Please refresh page later.',
        icon: 'error',
      });
    }
  }, [isError]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Row className="g-2 justify-content-around">
      {data?.map(
        ({
          id,
          type,
          name,
          price,
          price_currency,
          num_of_rss_links,
          num_of_users,
          upwork_integration,
          openai_features,
          swiper_rollback,
        }) => (
          <Col lg={6} xxl={4} key={id}>
            <Card className={`card-bordered pricing text-center ${type === PlanTypeEnum.Paid ? 'recommend' : ''}`}>
              {type === PlanTypeEnum.Paid && (
                <Badge color="primary" className="pricing-badge">
                  Recommend
                </Badge>
              )}

              <div className="pricing-body h-100 d-flex flex-column justify-between">
                <div className="d-flex justify-around flex-grow-1">
                  <div className="h-100 w-50">
                    <img src={type === PlanTypeEnum.Paid ? ProPlanIcon : FreePlanIcon} alt="plan logo" />
                  </div>

                  <div className="d-flex flex-column justify-between h-100">
                    <div>
                      <div className="pricing-title w-max-300px mx-auto">
                        <h5 className="title mb-4">{name}</h5>

                        {Object.entries({
                          num_of_rss_links,
                          num_of_users,
                          upwork_integration,
                          openai_features,
                          swiper_rollback,
                        }).map(([featureKey, featureValue]) => (
                          <div className="mt-1 d-flex justify-between align-center" key={featureKey}>
                            <div className="me-2 flex-grow-1" style={{ textAlign: 'left' }}>
                              {FieldsTitles[featureKey]}
                            </div>

                            <div className="flex-shrink-0">
                              {typeof featureValue === 'number' ? (
                                <div>{featureValue}</div>
                              ) : featureValue ? (
                                <CheckCircle color="success" />
                              ) : (
                                <Cancel color="error" />
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div>
                      <div className="pricing-amount">
                        <div className="amount">
                          {`${price} ${price_currency}`} <span>/m</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="pricing-action">
                  {currentUser.is_owner && (
                    <Button
                      color="primary"
                      disabled={
                        createSubscriptionLoading ||
                        (currentCompany?.subscription?.plan?.name === name &&
                          currentCompany?.subscription?.plan?.type === type)
                      }
                      onClick={() => createSubscriptionHandler(id)}
                    >
                      Select Plan
                    </Button>
                  )}
                </div>
              </div>
            </Card>
          </Col>
        ),
      )}

      <StripeModal
        isOpen={isStripeModalOpen}
        close={() => setStripeModalOpen(false)}
        toggle={() => setStripeModalOpen(!isStripeModalOpen)}
        subscription={createSubscriptionData}
      />
    </Row>
  );
};
