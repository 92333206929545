import React from 'react';
import { DragDropContext, OnDragEndResponder } from 'react-beautiful-dnd';

import { Modal } from '../../../components/Modal';
import { useToggle } from '../../../hooks';
import Content from '../../../layout/content/Content';

import { Column, Job } from '../../../types/types';

import { Block } from '../../Component';
import { AddJobModal, JobModal } from './components';
import { JobColumn } from './components/JobColumn';

import { useJobModal } from './hooks';

type KanbanProps = {
  columns: Column[];
  dragEndHandler: OnDragEndResponder;
  jobs: Record<string, Job[] | []>;
  setJobs: React.Dispatch<React.SetStateAction<Record<string, Job[] | []>>>;
};

export const Kanban = ({ columns, dragEndHandler, jobs, setJobs }: KanbanProps) => {
  const { searchParams, handleCloseJobModal } = useJobModal();
  const [isAddJobModalOpen, toggleAddJobModal] = useToggle();

  return (
    <Content noPB>
      <Block>
        <div className="nk-kanban">
          <DragDropContext onDragEnd={dragEndHandler}>
            <div className="kanban-container">
              {columns.map((column) => (
                <JobColumn
                  key={column.id}
                  jobs={jobs}
                  setJobs={setJobs}
                  column={column}
                  toggleAddJobModal={toggleAddJobModal}
                />
              ))}
            </div>
          </DragDropContext>
        </div>
      </Block>

      {searchParams.get('id') && (
        <Modal size="xxl" className="kanban-modal" isOpen={!!searchParams.get('id')} toggle={handleCloseJobModal}>
          <JobModal id={searchParams.get('id')!} jobs={jobs} setJobs={setJobs} />
        </Modal>
      )}

      <Modal size="md" className="kanban-modal" isOpen={isAddJobModalOpen} toggle={toggleAddJobModal}>
        <AddJobModal toggle={toggleAddJobModal} />
      </Modal>
    </Content>
  );
};
