import classNames from 'classnames';
import React, { FC, ReactNode, useEffect, useLayoutEffect, useState } from 'react';
import Header from '../../layout/header/Header';
import Sidebar from '../../layout/sidebar/Sidebar';

interface ILayoutProps {
  children: ReactNode;
}

export const Layout: FC<ILayoutProps> = ({ children }) => {
  // Sidebar
  const [mobileView, setMobileView] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const [themeState] = useState({
    main: 'default',
    sidebar: 'dark',
    header: 'white',
    skin: 'light',
  });

  useEffect(() => {
    viewChange();
  }, []);

  // Stops scrolling on overlay
  useLayoutEffect(() => {
    if (visibility) {
      document.body.style.overflow = 'hidden';
      document.body.style.height = '100%';
    }
    if (!visibility) {
      document.body.style.overflow = 'auto';
      document.body.style.height = 'auto';
    }
  }, [visibility]);

  // function to toggle sidebar
  const toggleSidebar = (e: React.MouseEvent) => {
    e.preventDefault();
    if (visibility === false) {
      setVisibility(true);
    } else {
      setVisibility(false);
    }
  };

  useEffect(() => {
    document.body.className = `nk-body bg-lighter npc-default has-sidebar no-touch nk-nio-theme ${
      themeState.skin === 'dark' ? 'dark-mode' : ''
    }`;
  }, [themeState.skin]);

  // function to change the design view under 1200 px
  const viewChange = () => {
    if (window.innerWidth < 1200) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setVisibility(false);
    }
  };
  window.addEventListener('load', viewChange);
  window.addEventListener('resize', viewChange);

  const sidebarClass = classNames({
    'nk-sidebar-mobile': mobileView,
    'nk-sidebar-active': visibility && mobileView,
  });

  return (
    <div className="nk-app-root">
      <div className="nk-main">
        <Sidebar
          sidebarToggle={toggleSidebar}
          fixed
          mobileView={mobileView}
          theme={themeState.sidebar}
          className={sidebarClass}
        />
        {visibility && mobileView && <div className="nk-sidebar-overlay" onClick={toggleSidebar}></div>}
        <div className="nk-wrap">
          <Header sidebarToggle={toggleSidebar} setVisibility={setVisibility} fixed theme={themeState.header} />
          {children}
        </div>
      </div>
    </div>
  );
};
