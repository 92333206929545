import { yupResolver } from '@hookform/resolvers/yup';
import { Cancel } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Button, Col } from 'reactstrap';
import Swal from 'sweetalert2';
import * as yup from 'yup';

import { useCreateCompanyMutation, useUpdateCompanyFilesMutation } from '../../../app/company/api';
import { selectCurrentCompany } from '../../../app/company/selctors';
import { setCurrentCompany } from '../../../app/company/slice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { userProfileSelector } from '../../../app/user/selectors';
import { CreateCompanyRequestParams, RoleEnum } from '../../../types';

import { ITabContentProps } from '../CompanyInner';

const companySchema: yup.SchemaOf<CreateCompanyRequestParams> = yup
  .object()
  .shape({
    title: yup.string().trim().required('Title is required'),
    cl_name: yup.string().trim().min(0),
    cl_industry: yup.string().trim().min(0),
    cl_experience: yup.string().trim().min(0),
    cl_skills: yup.string().trim().min(0),
    cl_additional: yup.string().trim().min(0),
  })
  .required();

const coverLetterInputs: {
  id: 'cl_name' | 'cl_industry' | 'cl_experience' | 'cl_skills' | 'cl_additional';
  name: string;
  placeholder: string;
}[] = [
  { id: 'cl_name', name: 'Name', placeholder: 'Please enter your name' },
  { id: 'cl_industry', name: 'Industry', placeholder: 'Please enter the industry your company specializes in' },
  {
    id: 'cl_experience',
    name: 'Experience',
    placeholder: 'Please enter info about your company experience in this industry',
  },
  {
    id: 'cl_skills',
    name: 'Skills',
    placeholder: 'Please enter skills, that will help your company to get the job done',
  },
  {
    id: 'cl_additional',
    name: 'Additional',
    placeholder: 'Please enter any additional info, that you would like to share with customer',
  },
];

export const CompanyForm: FC<ITabContentProps & { isCreating?: boolean }> = ({
  company,
  closeModal,
  companyDataLoading,
  isCreating,
}) => {
  const dispatch = useAppDispatch();
  const currentCompany = useAppSelector(selectCurrentCompany);

  const [
    createCompany,
    { isLoading: createLoading, isSuccess: createSuccess, isError: createError, error: createErrorDetails },
  ] = useCreateCompanyMutation();
  const [
    updateCompany,
    { data: updateCompanyData, isLoading: updateLoading, isError: updateError, error: updateErrorDetails },
  ] = useUpdateCompanyFilesMutation();
  const { role } = useSelector(userProfileSelector);

  const [logo, setLogo] = useState<File>();
  const [isLogoDeleted, setLogoDeleted] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, defaultValues },
    reset,
  } = useForm({
    resolver: yupResolver(companySchema),
    defaultValues: {
      title: company ? company.title : '',
      cl_name: company ? company.cl_name : '',
      cl_industry: company ? company.cl_industry : '',
      cl_experience: company ? company.cl_experience : '',
      cl_skills: company ? company.cl_skills : '',
      cl_additional: company ? company.cl_additional : '',
    },
  });

  const setDefaultValues = () => {
    reset(defaultValues);
    setLogoDeleted(false);
    setLogo(undefined);
  };

  const sendFormHandler = (formValues: CreateCompanyRequestParams) => {
    if (!formValues.title) return;
    const companyFormData = new FormData();

    Object.entries(formValues).forEach(([key, value]) => {
      if (!value && key === 'logo') {
        return;
      }

      companyFormData.append(key, value);
    });

    if (logo) {
      companyFormData.append('logo', logo);
    }

    if (isLogoDeleted) {
      companyFormData.append('logo', '');
    }

    company ? updateCompany({ id: company.id, body: companyFormData }) : createCompany(companyFormData);
  };

  const deleteLogoHandler = () => {
    if (logo) {
      setLogo(undefined);
    }

    setLogoDeleted(true);
  };

  useEffect(() => {
    if (company) {
      setLogo(undefined);
      setLogoDeleted(false);
      reset({
        title: company.title,
        cl_name: company.cl_name || '',
        cl_industry: company.cl_industry || '',
        cl_experience: company.cl_experience || '',
        cl_skills: company.cl_skills || '',
        cl_additional: company.cl_additional || '',
      });
    }
  }, [company]);

  useEffect(() => {
    if (updateCompanyData && currentCompany?.id === company?.id) {
      dispatch(setCurrentCompany(updateCompanyData));
    }
  }, [updateCompanyData]);

  useEffect(() => {
    if (createSuccess) {
      closeModal && closeModal();
      Swal.fire({
        title: 'Company successfully created!',
        text: 'Check out your new company in companies list',
        icon: 'success',
      });
    }
  }, [createSuccess]);

  useEffect(() => {
    if (createError && createErrorDetails && 'data' in createErrorDetails) {
      Swal.fire({
        title: 'Company creation failed!',
        text: (createErrorDetails?.data as { details?: string[] })?.details?.[0]
          ? (createErrorDetails?.data as { details?: string[] })?.details?.[0]
          : 'Company creation failed, please try again later',
        icon: 'error',
      });
    }
  }, [createError, createErrorDetails]);

  useEffect(() => {
    if (updateError && updateErrorDetails && 'data' in updateErrorDetails) {
      Swal.fire({
        title: 'Company update failed!',
        text: (updateErrorDetails?.data as { details?: string[] })?.details?.[0]
          ? (updateErrorDetails?.data as { details?: string[] })?.details?.[0]
          : 'Company update failed, please try again later',
        icon: 'error',
      });
    }
  }, [updateError, updateErrorDetails]);

  const loading = createLoading || updateLoading || companyDataLoading;

  const isLogo = (!!logo || !!company?.logo) && !isLogoDeleted;

  const isFormDisabled = loading || (role !== RoleEnum.Admin && !isCreating);

  return (
    <form className="row gy-4" onSubmit={handleSubmit(sendFormHandler)}>
      <Col sm="12" md={closeModal ? '12' : '6'}>
        <label className="form-label">Logo</label>
        <Dropzone
          onDrop={(acceptedFiles) => {
            setLogo(acceptedFiles[0]);
            setLogoDeleted(false);
          }}
          accept={['.jpg', '.png', '.svg']}
          maxFiles={1}
          disabled={isFormDisabled}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()} className="dropzone upload-zone dz-clickable">
                <input {...getInputProps()} />

                {isLogo ? (
                  <div
                    className="dz-preview dz-processing dz-image-preview dz-error dz-complete"
                    style={{ display: 'block' }}
                  >
                    <div className="dz-image" style={{ margin: '0 auto' }}>
                      {!isFormDisabled && (
                        <div className="position-absolute top-0 end-0">
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              deleteLogoHandler();
                            }}
                          >
                            <Cancel color="error" />
                          </IconButton>
                        </div>
                      )}

                      <img src={company && !logo ? company.logo : URL.createObjectURL(logo as File)} alt="preview" />
                    </div>
                  </div>
                ) : (
                  <div className="dz-message">
                    <span className="dz-message-text">Drag and drop file</span>
                    <span className="dz-message-or">or</span>
                    <Button color="primary" type="button" disabled={isFormDisabled}>
                      SELECT
                    </Button>
                  </div>
                )}
              </div>
            </section>
          )}
        </Dropzone>
      </Col>

      <Col sm="12" md={closeModal ? '12' : '7'}>
        <div className="form-group">
          <label className="form-label">Title</label>
          <input
            type="text"
            disabled={isFormDisabled}
            className="form-control"
            placeholder="Please name your company"
            {...register('title')}
          />
          {errors.title && <span className="invalid">{errors.title.message}</span>}
        </div>
      </Col>

      {coverLetterInputs.map(({ id, name, placeholder }) => (
        <Col sm="12" key={id}>
          <div className="form-group">
            <label className="form-label">{name}</label>
            {id !== 'cl_additional' ? (
              <input
                disabled={isFormDisabled}
                className="form-control"
                placeholder={placeholder}
                style={{ resize: closeModal ? 'none' : 'vertical' }}
                {...register(id)}
              />
            ) : (
              <textarea
                disabled={isFormDisabled}
                className="form-control resize"
                placeholder={placeholder}
                style={{ resize: closeModal ? 'none' : 'vertical' }}
                {...register(id)}
              />
            )}
          </div>
        </Col>
      ))}

      {(role === RoleEnum.Admin || isCreating) && (
        <ul className="d-flex align-center flex-wrap flex-sm-nowrap gx-4 gap-2">
          <li>
            <Button color="primary" size="md" type="submit" disabled={loading}>
              {company ? 'Update company' : 'Create company'}
            </Button>
          </li>

          <li>
            <Button
              disabled={loading}
              onClick={(ev) => {
                ev.preventDefault();
                setDefaultValues();
                setLogoDeleted(false);
                if (closeModal) {
                  closeModal();
                }
              }}
              color="secondary"
            >
              Cancel
            </Button>
          </li>
        </ul>
      )}
    </form>
  );
};
