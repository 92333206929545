import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useLoginMutation } from '../../../../app/auth/api';
import { useAppDispatch } from '../../../../app/hooks';
import { clearAwaitingCoverLetterJobs } from '../../../../app/jobs/slice';
import { ROUTES } from '../../../../router/routes';

const loginSchema = yup
  .object()
  .shape({
    email: yup.string().trim().email('Please provide a valid email address').required('Email is required'),
    password: yup
      .string()
      .trim()
      .min(
        8,
        'Password must contain 8 or more characters with at least one of each: uppercase, lowercase, number & symbol',
      )
      .matches(/[0-9]/, 'Password requires a number')
      .matches(/[a-z]/, 'Password requires a lowercase letter')
      .matches(/[A-Z]/, 'Password requires an uppercase letter')
      .matches(/[^\w]/, 'Password requires a symbol')
      .required(),
  })
  .required();

export const useLoginForm = ({ feedbackMessage, setFeedbackMessage }) => {
  const dispatch = useAppDispatch();

  const [loginUser, { isLoading, isSuccess }] = useLoginMutation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  useEffect(() => {
    if (feedbackMessage?.value) {
      const subscription = watch(() => setFeedbackMessage({}));
      return () => subscription.unsubscribe();
    }
  }, [watch, feedbackMessage?.value]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(clearAwaitingCoverLetterJobs());
    }
  }, [isSuccess]);

  const handleFormSubmit = async (formData) => {
    try {
      const response = await loginUser(formData);
      if (response.error.status === 401) {
        throw response.error.data;
      }

      navigate(ROUTES.SWIPER);
    } catch (err) {
      setFeedbackMessage({
        type: 'error',
        value: err.details,
      });
    }
  };

  return {
    register,
    handleSubmit,
    watch,
    errors,
    isLoginLoading: isLoading,
    handleFormSubmit,
  };
};
