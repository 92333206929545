import { baseApi } from '../baseApi';
import { API_ENDPOINTS } from '../endpoints';
import {
  LostReasonReportResponse,
  WonAndLostReportResponse,
  StatusChangesByUserReportResponse,
  MostWonAndLostReportResponse,
  LostReasonByUserReportResponse,
} from './../../types';

export const statsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getLostReasonReport: build.query<LostReasonReportResponse, void>({
      query: () => ({
        url: API_ENDPOINTS.STATS.LOST_REASON,
      }),
      keepUnusedDataFor: 3,
    }),
    getLostReasonByUserReport: build.query<LostReasonByUserReportResponse, void>({
      query: () => ({
        url: API_ENDPOINTS.STATS.LOST_REASON_BY_USER,
      }),
      keepUnusedDataFor: 3,
    }),
    getMostWonAndLostReport: build.query<MostWonAndLostReportResponse, void>({
      query: () => ({
        url: API_ENDPOINTS.STATS.MOST_WON_LOST,
      }),
      keepUnusedDataFor: 3,
    }),
    getStatusChangesByUserReport: build.query<StatusChangesByUserReportResponse, void>({
      query: () => ({
        url: API_ENDPOINTS.STATS.STATUS_BY_USER,
      }),
      keepUnusedDataFor: 3,
    }),
    getWonAndLostReport: build.query<WonAndLostReportResponse, void>({
      query: () => ({
        url: API_ENDPOINTS.STATS.WON_LOST,
      }),
      keepUnusedDataFor: 3,
    }),
  }),
});

export const {
  useGetLostReasonByUserReportQuery,
  useGetWonAndLostReportQuery,
  useGetLostReasonReportQuery,
  useGetMostWonAndLostReportQuery,
  useGetStatusChangesByUserReportQuery,
} = statsApi;
