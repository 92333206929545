import { createBrowserHistory } from 'history';
import { createRoot } from 'react-dom/client';
import App from './App';
import './assets/scss/dashlite.scss';
import './assets/scss/style-email.scss';

const history = createBrowserHistory();
const app = document.getElementById('root') as HTMLElement;

if (app) {
  const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
  if (path) {
    history.replace(path);
  }

  const root = createRoot(app); // createRoot(container!) if you use TypeScript

  root.render(<App />);
}
