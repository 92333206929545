import {
  ExpandMore,
  GroupOutlined,
  InfoOutlined,
  RadioButtonUnchecked,
  SettingsOutlined,
  TaskAlt,
} from '@mui/icons-material';
import { Checkbox, IconButton, Menu, MenuItem } from '@mui/material';
import classnames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Modal, ModalBody, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import Swal from 'sweetalert2';

import { useGetCompaniesQuery } from '../../app/company/api';
import { selectCurrentCompany } from '../../app/company/selctors';
import { setCurrentCompany } from '../../app/company/slice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useDisconnectUpworkMutation, useLazyGetLinkQuery, useLazySetCodeQuery } from '../../app/upwork/api';
import { userProfileSelector } from '../../app/user/selectors';

import Content from '../../layout/content/Content';
import { ROUTES } from '../../router/routes';
import { Colors } from '../../styles/Colors';
import { BlockBetween, BlockHead, BlockHeadContent, BlockTitle } from '../../theme-components/Component';

import { Company } from '../../types';
import { cutString } from '../../utils/CutString';

import { CompanyForm, OperatorsTab, SettingsTab } from './Content';

export interface ITabContentProps {
  company?: Company;
  closeModal?(): void;
  companyDataLoading: boolean;
}

export const CompanyInner = () => {
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);

  const { data: companies, isLoading: isCompaniesLoading, refetch } = useGetCompaniesQuery();
  const { state } = useLocation();
  const [search] = useSearchParams();

  const currentCompany = useAppSelector(selectCurrentCompany);
  const currentUser = useAppSelector(userProfileSelector);

  const code = search.get('code');

  const navigate = useNavigate();

  const [getLink, { data: linkData, isError: linkError, isLoading: linkLoading }] = useLazyGetLinkQuery();
  const [setCode, { isSuccess: codeSuccess, isError: codeError, isLoading: codeLoading }] = useLazySetCodeQuery();
  const [disconnectUpwork, { isSuccess: disconnectSuccess, isError: disconnectError, isLoading: disconnectLoading }] =
    useDisconnectUpworkMutation();

  useEffect(() => {
    if (companies?.length) {
      if (!currentCompany) {
        dispatch(setCurrentCompany(companies[0]));
        return;
      }

      const updatedCompany = companies.find(({ id }) => id === currentCompany.id);
      dispatch(setCurrentCompany(updatedCompany ? updatedCompany : companies[0]));
    }
  }, [companies]);

  useEffect(() => {
    if (linkData) {
      window.location.replace(linkData.link);
    }
  }, [linkData]);

  useEffect(() => {
    if (code && !currentCompany?.upwork_connected) {
      setCode({ code });
    }
  }, [code]);

  useEffect(() => {
    if (codeSuccess) {
      Swal.fire({
        title: 'Account connection success!',
        text: 'Your upwork account was connected successfully',
        icon: 'success',
      });
      refetch();
      navigate(ROUTES.COMPANIES);
    }
  }, [codeSuccess]);

  useEffect(() => {
    if (linkError || codeError) {
      Swal.fire({
        title: 'Account connection failed!',
        text: 'Some error occurred while connecting your Upwork account. Please try again later.',
        icon: 'error',
      });
      navigate(ROUTES.COMPANIES);
    }
  }, [linkError, codeError]);

  useEffect(() => {
    if (disconnectSuccess) {
      Swal.fire({
        title: 'Account disconnection success!',
        text: 'Upwork account was disconnected successfully',
        icon: 'success',
      });
    }
  }, [disconnectSuccess]);

  useEffect(() => {
    if (disconnectError) {
      Swal.fire({
        title: 'Account disconnection failed!',
        text: 'Some error occurred while disconnecting your Upwork account. Please try again later.',
        icon: 'error',
      });
    }
  }, [disconnectError]);

  const tabs = [
    {
      name: 'Info',
      icon: <InfoOutlined fontSize="small" className="me-1" />,
      content: <CompanyForm company={currentCompany as Company} companyDataLoading={isCompaniesLoading} />,
      disabled: false,
    },
    {
      name: 'Staff',
      icon: <GroupOutlined fontSize="small" className="me-1" />,
      content: <OperatorsTab />,
      disabled: !currentCompany,
    },
    {
      name: 'Settings',
      icon: <SettingsOutlined fontSize="small" className="me-1" />,
      content: <SettingsTab company={currentCompany as Company} companyDataLoading={isCompaniesLoading} />,
      disabled: !currentCompany,
    },
  ];

  const preselectedTab = useMemo(() => tabs.find((tab) => tab.name === state?.tab) || tabs[0], [state?.tab, tabs]);

  const [activeTab, setActiveTab] = useState(preselectedTab);

  return (
    <Content>
      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle page>
              {currentCompany ? cutString(25, currentCompany.title) : 'Company'}

              {companies?.length && companies?.length > 1 && (
                <IconButton onClick={({ currentTarget }) => setAnchorEl(currentTarget)}>
                  <ExpandMore />
                </IconButton>
              )}
            </BlockTitle>
            <Menu
              elevation={0}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              anchorEl={anchorEl}
              open={!!anchorEl}
              onClose={() => setAnchorEl(null)}
            >
              {companies?.map((company) => (
                <MenuItem
                  key={company?.id}
                  onClick={() => {
                    if (currentCompany?.id !== company?.id) {
                      dispatch(setCurrentCompany(company));
                      setAnchorEl(null);
                    }
                  }}
                >
                  <Checkbox
                    checked={currentCompany?.id === company?.id}
                    icon={<RadioButtonUnchecked />}
                    checkedIcon={<TaskAlt />}
                    sx={{ color: Colors.primary.light }}
                  />

                  {cutString(25, company?.title)}
                </MenuItem>
              ))}
            </Menu>
          </BlockHeadContent>

          <div className="d-flex justify-end gap-1 h-auto">
            <Button
              color="primary"
              size="md"
              disabled={linkLoading || codeLoading || disconnectLoading}
              onClick={() => setCreateModalOpen(true)}
            >
              Create Company
            </Button>

            {currentUser?.is_owner && (
              <Button
                color={currentCompany?.upwork_connected ? 'danger' : 'primary'}
                size="md"
                disabled={
                  linkLoading || codeLoading || (!!code && !currentCompany?.upwork_connected) || disconnectLoading
                }
                onClick={() => {
                  currentCompany?.upwork_connected ? disconnectUpwork() : getLink();
                }}
              >
                {currentCompany?.upwork_connected ? 'Disconnect Upwork account' : 'Connect Upwork account'}
              </Button>
            )}
          </div>
        </BlockBetween>
      </BlockHead>

      <Nav tabs>
        {currentCompany &&
          tabs.map((tab) => (
            <NavItem key={tab.name}>
              <NavLink
                tag="a"
                href={`#${tab.name}`}
                disabled={tab.disabled}
                className={classnames({
                  active: activeTab.name === tab.name,
                })}
                onClick={(ev) => {
                  ev.preventDefault();
                  setActiveTab(tab);
                }}
              >
                {tab.icon}
                <span>{tab.name}</span>
              </NavLink>
            </NavItem>
          ))}
      </Nav>
      <div className="card card-bordered card-preview mt-2">
        <div className="card-inner">
          <TabContent activeTab={activeTab.name}>
            {tabs.map(({ name, content, disabled }) => (
              <TabPane key={name} tabId={name}>
                {!disabled && content}
              </TabPane>
            ))}
          </TabContent>
        </div>
      </div>

      <Modal size="md" isOpen={isCreateModalOpen} toggle={() => setCreateModalOpen(false)}>
        <ModalBody>
          <h5 className="title">Create company</h5>

          <CompanyForm
            companyDataLoading={isCompaniesLoading}
            closeModal={() => setCreateModalOpen(false)}
            isCreating
          />
        </ModalBody>
      </Modal>
    </Content>
  );
};
