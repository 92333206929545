import styled from 'styled-components';

export const JobDescription = styled.p`
  margin: 0;
  text-align: start;
  font-size: 16px;
  white-space: pre-wrap;
`;

export const JobSkills = styled.ul`
  max-height: 68px;
  overflow-y: auto;
`;

export const CardHeader = styled.div`
  position: sticky;
  top: -1px;
  padding-top: 24px;
  background-color: #ffffff;
`;

export const CardDivider = styled.hr`
  margin: 16px 0 0 0;
  padding-bottom: 16px;
  color: #444444;
`;

export const CardInfo = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;

export const CardInfoItem = styled.div`
  padding: 0 10px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);

  &:first-of-type {
    padding-left: 0;
    border-left: none;
  }

  &:last-of-type {
    padding-right: 0;
  }
`;

export const CardDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  font-size: 16px;
`;

export const CardContainer = styled.div`
  will-change: transform;
  position: absolute;
  margin-top: 0 !important;
  max-height: calc(100vh - 162px);
  overflow-y: auto;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 0;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #dddddd;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(101, 118, 255, 0.7);
    border-radius: 4px;
    opacity: 0.8;
  }
`;
