import {
  UWJob,
  Job,
  GetJobsRequestParams,
  ChangeColumnRequestParams,
  PaginationRequestParams,
  ResponseWithPagination,
  GetJobRequestParams,
  CreateLostReasonRequest,
  DeleteJobParams,
  LostReason,
  UpdateJob,
  UpdateLostReasonRequest,
  CreateCommentRequest,
  Comment,
  JobHistory,
  UUID,
  ColumnEnum,
} from '../../types';

import { baseApi } from '../baseApi';
import { API_ENDPOINTS } from '../endpoints';

export const jobsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getJobsList: build.query<UWJob[], string>({
      query: (links) => ({
        url: API_ENDPOINTS.JOBS.LIST(links),
      }),
      providesTags: ['JobsList'],
    }),
    getJobs: build.query<ResponseWithPagination<Job>, Partial<GetJobsRequestParams> & PaginationRequestParams>({
      query: (params) => ({
        url: API_ENDPOINTS.JOBS.JOBS,
        params: { ...params },
      }),
      providesTags: (res, e, arg) => [
        'DeadlineDate',
        'AssigneesList',
        {
          type: 'JobsColumn',
          id: arg.column,
        },
      ],
    }),
    getDislikedJobs: build.query<ResponseWithPagination<Job>, PaginationRequestParams>({
      query: (params) => ({
        url: API_ENDPOINTS.JOBS.JOBS,
        params: { ...params, column: ColumnEnum.Dislike },
      }),
    }),
    getJob: build.query<Job, GetJobRequestParams>({
      query: ({ id, status }) => ({
        url: API_ENDPOINTS.JOBS.ID(id),
        params: { status },
      }),
      providesTags: (res) => [
        'AssigneesList',
        {
          type: 'JobById',
          id: res?.id,
        },
      ],
    }),
    addJob: build.mutation<Job, { url: string }>({
      query: (body) => ({
        url: API_ENDPOINTS.JOBS.ADD_JOB,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'JobsColumn', id: ColumnEnum.Like }],
    }),
    postJob: build.mutation<Job, Partial<Job>>({
      query: (body) => ({
        url: API_ENDPOINTS.JOBS.JOBS,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'JobsColumn', id: ColumnEnum.Like }],
    }),
    changeJobColumn: build.mutation<Job, ChangeColumnRequestParams & Pick<Job, 'id'>>({
      query: ({ id, ...patch }) => ({
        url: API_ENDPOINTS.JOBS.ID(id),
        method: 'PATCH',
        body: { column: patch.destinationColumn },
      }),
      invalidatesTags: (_, __, args) => [
        { type: 'JobById', id: args.id },
        { type: 'History', id: args.id },
        { type: 'JobsColumn', id: args.sourceColumn },
        { type: 'JobsColumn', id: args.destinationColumn },
      ],
    }),
    editJob: build.mutation<Job, Partial<ChangeColumnRequestParams> & Partial<UpdateJob> & { id: UUID }>({
      query: ({ id, ...patch }) => ({
        url: API_ENDPOINTS.JOBS.ID(id),
        method: 'PATCH',
        body: { ...patch, destinationColumn: undefined, sourceColumn: undefined },
        params: { status: patch.status === null ? 'Lost' : undefined },
      }),
      invalidatesTags: (_, __, args) => [
        { type: 'JobById', id: args.id },
        { type: 'History', id: args.id },
        { type: 'JobsColumn', id: args.sourceColumn },
        { type: 'JobsColumn', id: args.destinationColumn },
      ],
    }),
    deleteJob: build.mutation<void, DeleteJobParams>({
      query: ({ id }) => ({
        url: API_ENDPOINTS.JOBS.ID(id),
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, { column }) => [{ type: 'JobsColumn', id: column }],
    }),
    addLostReason: build.mutation<LostReason, CreateLostReasonRequest>({
      query: (body) => ({
        url: API_ENDPOINTS.LOST_REASON.CREATE,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_, __, args) => [
        { type: 'JobById', id: args.job },
        { type: 'History', id: args.job },
      ],
    }),
    updateLostReason: build.mutation<LostReason, UpdateLostReasonRequest>({
      query: (lostReasonData) => ({
        url: API_ENDPOINTS.LOST_REASON.UPDATE(lostReasonData.id),
        method: 'POST',
        body: lostReasonData,
      }),
      invalidatesTags: (_, __, args) => [
        { type: 'JobById', id: args.job },
        { type: 'History', id: args.job },
      ],
    }),
    addComment: build.mutation<Comment, CreateCommentRequest>({
      query: (commentData) => ({
        url: API_ENDPOINTS.COMMENT.CREATE,
        method: 'POST',
        body: commentData,
      }),
      invalidatesTags: (_, __, args) => ['Comments', { type: 'History', id: args.job }],
    }),
    getComments: build.query<ResponseWithPagination<Comment>, Partial<PaginationRequestParams> & { id: UUID }>({
      query: ({ id, ...params }) => ({
        url: API_ENDPOINTS.COMMENT.ID(id),
        method: 'GET',
        params,
      }),
      providesTags: ['Comments'],
    }),
    getHistory: build.query<ResponseWithPagination<JobHistory>, Partial<PaginationRequestParams> & { id: UUID }>({
      query: ({ id, ...params }) => ({
        url: API_ENDPOINTS.HISTORY.ID(id),
        method: 'GET',
        params,
      }),
      providesTags: (_, __, arg) => [
        {
          type: 'History',
          id: arg.id,
        },
      ],
    }),
  }),
});

export const {
  useGetJobsListQuery,
  useGetJobsQuery,
  useGetDislikedJobsQuery,
  useGetJobQuery,
  usePostJobMutation,
  useChangeJobColumnMutation,
  useEditJobMutation,
  useDeleteJobMutation,
  useAddLostReasonMutation,
  useUpdateLostReasonMutation,
  useGetCommentsQuery,
  useAddCommentMutation,
  useAddJobMutation,
  useGetHistoryQuery,
} = jobsApi;
