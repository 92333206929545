import { onMessage } from 'firebase/messaging';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { baseApi } from '../app/baseApi';
import { useAppSelector } from '../app/hooks';
import { removeAwaitingCoverLetterJob } from '../app/jobs/slice';
import { addNotification, readAllNotifications } from '../app/notifications/slice';
import { useCreateDeviceMutation, useGetUserProfileQuery } from '../app/user/api';
import { oldUserSelector } from '../app/user/selectors';
import { setOldUser } from '../app/user/slice';
import { getFirebaseToken, messaging } from '../firebase';
import { NotificationTypeEnum } from '../types';
import { Notification } from '../types/types/Notification';

export const useFirebaseMessaging = () => {
  const {
    data: profileData,
    isLoading: isUserProfileLoading,
    isSuccess: isUserProfileSuccess,
  } = useGetUserProfileQuery();

  const [createDevice] = useCreateDeviceMutation();

  const dispatch = useDispatch();

  const oldUser = useAppSelector(oldUserSelector);

  const handleAddNotification = (notification: Notification) => {
    dispatch(addNotification({ notification }));
  };

  useEffect(() => {
    const getNotificationData = ({ data }: { data: Notification }) => {
      handleAddNotification(data);
    };

    const channel = new BroadcastChannel('sw-messages');
    channel.addEventListener('message', getNotificationData);

    return () => {
      channel.removeEventListener('message', getNotificationData);
    };
  }, []);

  useEffect(() => {
    if (profileData && oldUser?.id !== profileData?.id) {
      dispatch(readAllNotifications());
      dispatch(setOldUser(profileData));
    }
  }, [profileData]);

  useEffect(() => {
    if (profileData && !isUserProfileLoading && isUserProfileSuccess) {
      getFirebaseToken()
        .then((firebaseToken) => {
          createDevice({
            registration_id: firebaseToken,
            name: profileData.email,
            active: true,
            device_id: profileData.id,
            type: 'web',
            user: profileData.id,
          });
        })
        .catch((err) => console.error('An error occured while retrieving firebase token. ', err));
    }
  }, [profileData, isUserProfileSuccess, isUserProfileLoading]);

  onMessage(messaging, ({ notification, data }) => {
    if (notification && data?.user_email === profileData?.email) {
      toast.info(`${notification?.title}: ${notification?.body}`);
      handleAddNotification({ ...notification, ...data });
      if (data?.notification_type === NotificationTypeEnum.Letter) {
        baseApi.util.invalidateTags([{ type: 'JobById', id: data?.job_id }]);
        dispatch(removeAwaitingCoverLetterJob(data?.job_id));
      }
    }
  });
};
