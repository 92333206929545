type Select = 'none';

export const useCustomTableStyle = () => {
  const customStyles = {
    header: {
      style: {
        color: '#8094ae',
        fontSize: '13px',
      },
    },
    headCells: {
      style: {
        color: '#8094ae !important',
        fontSize: '13px !important',
        fontWeight: '400 !important',
      },
    },
    rows: {
      style: {
        fontSize: '13px !important',
        color: '#8094ae !important',
      },
    },
    cells: {
      style: {
        userSelect: 'none' as Select,
      },
    },
  };

  return { customStyles };
};
