import React from 'react';
import { Deck } from '../../components/Deck';
import { Layout } from '../../components/Layout';
import Head from '../../layout/head/Head';

export const Swiper = () => {
  return (
    <Layout>
      <Head title="Swiper" />
      <Deck />
    </Layout>
  );
};
