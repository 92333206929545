import { Link, useLocation } from 'react-router-dom';
import Logo from '../../images/logo_transparent.png';
import Head from '../../layout/head/Head';
import { ROUTES } from '../../router/routes';
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle } from '../../theme-components/Component';

const Success = () => {
  const { state } = useLocation();

  const pageMessage =
    {
      'forgot-password': 'If such an email exists in the database, you will receive a password reset link shortly.',
      'reset-password': 'Your password has been reset successfully.',
      register: 'Check your email to continue registration',
    }?.[state?.type] || 'Check your email to continue';

  return (
    <Block className="nk-block-middle nk-auth-body my-auto">
      <Head title="Success" />
      <div className="brand-logo pb-5">
        <Link to={'/'} className="logo-link">
          <img className="logo-dark logo-img logo-img-lg" src={Logo} alt="logo" />
        </Link>
      </div>
      <BlockHead>
        <BlockContent>
          <BlockTitle tag="h4">Thank you for submitting form</BlockTitle>
          <BlockDes className="text-success">
            <p>{pageMessage}</p>
          </BlockDes>
          {state.type === 'reset-password' && (
            <div className="text-center">
              <Link to={ROUTES.AUTH.LOGIN}>
                <strong> Return to login</strong>
              </Link>
            </div>
          )}
        </BlockContent>
      </BlockHead>
    </Block>
  );
};
export default Success;
