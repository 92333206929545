import { Add, InfoOutlined, Loop, Remove } from '@mui/icons-material';
import { CircularProgress, IconButton, Typography } from '@mui/material';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Collapse, Tooltip } from 'reactstrap';
import Swal from 'sweetalert2';
import { baseApi } from '../../../../../../../app/baseApi';
import { useAppDispatch, useAppSelector } from '../../../../../../../app/hooks';
import { selectAwaitingCoverLetterJobs } from '../../../../../../../app/jobs/selectors';
import { addAwaitingCoverLetterJob } from '../../../../../../../app/jobs/slice';

import { useLazyGenerateLetterQuery } from '../../../../../../../app/openai/api';

import { useToggle } from '../../../../../../../hooks';

import { CustomBadge } from '../../../../../../../styles';

import { Job } from '../../../../../../../types';

import { Col, Row } from '../../../../../../Component';

type UpdateJobFormProps = {
  jobData: Job;
  isLoading: boolean;
  isArchive?: boolean;
};

export const JobGeneralInfo = ({ jobData, isLoading, isArchive }: UpdateJobFormProps) => {
  const dispatch = useAppDispatch();

  const { description, skills, suggestion, id, lost_reason } = jobData;
  const { register } = useFormContext();

  const isJobAwaitingForCoverLetter = useAppSelector(selectAwaitingCoverLetterJobs).find((id) => id === jobData.id);

  const [isDescriptionOpen, toggleDescriptionOpen] = useToggle(false);
  const [isSkillsOpen, toggleSkillsOpen] = useToggle(false);
  const [isInfoVisible, setInfoVisible] = useToggle(false);
  const [isLostReasonOpen, toggleLostReasonOpen] = useToggle(!!isArchive);

  const [openSuggestions, setOpenSuggestions] = useState<number[]>([...Array(suggestion.length).keys()]);

  const [generateLetter, { isFetching, isError, isSuccess }] = useLazyGenerateLetterQuery();

  useEffect(() => {
    if (isError) {
      Swal.fire({
        title: 'Letter generation fails!',
        text: 'Some error occurred while generating new cover letter. Please try again later',
        icon: 'error',
      });
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        title: 'Letter generation started!',
        text: 'The generation of the letter will take a short time. Upon completion, you will receive a notification in the notification center',
        icon: 'success',
      });
      dispatch(addAwaitingCoverLetterJob(jobData.id));
    }
  }, [isSuccess]);

  return (
    <Row className="gy-4">
      <Col sm="12">
        <div className="accordion accordion-s3">
          <div className="accordion-item">
            <div
              className={classNames('accordion-head', {
                collapsed: !isDescriptionOpen,
              })}
              onClick={toggleDescriptionOpen}
            >
              <h6 className="title">Description</h6>
              <span className="accordion-icon" style={{ cursor: 'pointer' }}>
                {isDescriptionOpen ? <Remove /> : <Add />}
              </span>
            </div>
            <Collapse className="accordion-body" isOpen={isDescriptionOpen}>
              <div className="accordion-inner">
                <p className="fs-16px" style={{ whiteSpace: 'pre-wrap' }}>
                  {description}
                </p>
              </div>
            </Collapse>
          </div>
          <div className="accordion-item">
            <div
              className={classNames({
                'accordion-head': true,
                collapsed: !isSkillsOpen,
              })}
              onClick={toggleSkillsOpen}
            >
              <h6 className="title">Skills</h6>
              <span className="accordion-icon" style={{ cursor: 'pointer' }}>
                {isSkillsOpen ? <Remove /> : <Add />}
              </span>
            </div>
            <Collapse className="accordion-body" isOpen={isSkillsOpen}>
              <div className="accordion-inner">
                <ul className="kanban-item-tags">
                  {skills.map((skill: string) => (
                    <li key={skill}>
                      {!!skill && (
                        <CustomBadge>
                          <Typography fontSize={13} fontWeight={400}>
                            {skill}
                          </Typography>
                        </CustomBadge>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </Collapse>
          </div>

          {isArchive && (
            <div className="accordion-item">
              <div
                className={classNames({
                  'accordion-head': true,
                  collapsed: !isLostReasonOpen,
                })}
                onClick={toggleLostReasonOpen}
              >
                <h6 className="title">Lost reason</h6>
                <span className="accordion-icon" style={{ cursor: 'pointer' }}>
                  {isLostReasonOpen ? <Remove /> : <Add />}
                </span>
              </div>
              <Collapse className="accordion-body" isOpen={isLostReasonOpen}>
                <div className="accordion-inner">
                  <p className="fs-16px" style={{ whiteSpace: 'pre-wrap' }}>
                    {lost_reason?.comment}
                  </p>
                </div>
              </Collapse>
            </div>
          )}
        </div>
      </Col>
      <Col sm="5">
        <div className="form-group">
          <div className="form-label fs-4 mb-0">
            Cover letter
            <IconButton
              onClick={() => generateLetter({ id })}
              disabled={isFetching || isArchive || !!isJobAwaitingForCoverLetter}
              sx={{ color: '#6576ff' }}
            >
              {isFetching || isJobAwaitingForCoverLetter ? <CircularProgress size={24} color="inherit" /> : <Loop />}
            </IconButton>
            <IconButton id="info" className="ms-1">
              <InfoOutlined color="primary" />
            </IconButton>
            <Tooltip placement="bottom" isOpen={isInfoVisible} target="info" toggle={() => setInfoVisible()}>
              If there is no sign of cover letter or suggestions may be they are still generating. Please reload page to
              see them!
            </Tooltip>
          </div>
          <textarea
            rows={30}
            disabled={isLoading || isFetching || isArchive || !!isJobAwaitingForCoverLetter}
            className="form-control resize"
            {...register('cover_letter')}
          />
        </div>
      </Col>
      <Col sm="7" className="mt-5">
        {suggestion.map(({ proposal }, index) => (
          <div className="accordion accordion-s3" key={index}>
            <div className="accordion-item">
              <div
                className={classNames('accordion-head', {
                  collapsed: !openSuggestions.includes(index),
                })}
                onClick={() =>
                  setOpenSuggestions((prev) => {
                    if (openSuggestions.includes(index)) {
                      return prev.filter((item) => item !== index);
                    }
                    return [...prev, index];
                  })
                }
              >
                <h6 className="title">Suggestion {index + 1}</h6>
                <span className="accordion-icon" style={{ cursor: 'pointer' }}>
                  {openSuggestions.includes(index) ? <Remove /> : <Add />}
                </span>
              </div>
              <Collapse className="accordion-body" isOpen={openSuggestions.includes(index)}>
                <div className="accordion-inner">
                  <p>{proposal}</p>
                </div>
              </Collapse>
            </div>
          </div>
        ))}
      </Col>
    </Row>
  );
};
