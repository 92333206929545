import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import { useResetPasswordMutation } from '../../../../app/auth/api';
import { ROUTES } from '../../../../router/routes';

const resetPasswordSchema = yup
  .object()
  .shape({
    password: yup
      .string()
      .trim()
      .min(
        8,
        'Password must contain 8 or more characters with at least one of each: uppercase, lowercase, number & symbol',
      )
      .matches(/[0-9]/, 'Password requires a number')
      .matches(/[a-z]/, 'Password requires a lowercase letter')
      .matches(/[A-Z]/, 'Password requires an uppercase letter')
      .matches(/[^\w]/, 'Password requires a symbol')
      .required(),
    confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
  })
  .required();

export const useResetPasswordForm = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [resetPassword, { isLoading }] = useResetPasswordMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(resetPasswordSchema),
  });

  const [errorMessage, setErrorMessage] = useState('');

  const handleResetPassword = async ({ password }) => {
    try {
      const response = await resetPassword({
        token: params.get('token'),
        new_password: password,
      });

      if (response?.error?.status === 400) {
        throw response.error.data.code;
      }

      navigate(ROUTES.AUTH.SUCCESS, {
        state: {
          type: 'reset-password',
        },
      });
    } catch (error) {
      if (error === 'invalid_input') {
        setErrorMessage('Password reset is failed. Please try to reset your password again.');
      }
    }
  };

  return {
    register,
    handleSubmit,
    errors,
    isResetPasswordLoading: isLoading,
    handleResetPassword,
    errorMessage,
  };
};
