import React from 'react';
import { ArchiveInner } from '../../components/ArchiveInner';
import { Layout } from '../../components/Layout';
import Head from '../../layout/head/Head';

export const Archive = () => {
  return (
    <Layout>
      <Head title="Archive" />
      <ArchiveInner />
    </Layout>
  );
};
