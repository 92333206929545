import React from 'react';
import { Col, Row } from 'reactstrap';
import Content from '../../layout/content/Content';
import { BlockHead, BlockHeadContent, BlockTitle } from '../../theme-components/Component';
import {
  JobsStatusChangeReport,
  LostReasonReport,
  LostByCountryReport,
  WonLostReport,
  WonByCountryReport,
  LostByUserReport,
} from './Reports';

export const StatisticsInner = () => {
  const reports = [
    {
      id: 'WonLostReport',
      component: <WonLostReport />,
      isAvailable: true,
      cols: {
        sm: '12',
        md: '6',
        lg: '6',
        xl: '6',
      },
    },
    {
      id: 'LostReasonReport',
      component: <LostReasonReport />,
      isAvailable: true,
      cols: {
        sm: '12',
        md: '6',
        lg: '6',
        xl: '6',
      },
    },
    {
      id: 'JobsStatusChangeReport',
      component: <JobsStatusChangeReport />,
      isAvailable: true,
      cols: {
        sm: '12',
        md: '6',
        lg: '6',
        xl: '6',
      },
    },
    {
      id: 'WonByCountryReport',
      component: <WonByCountryReport />,
      isAvailable: true,
      cols: {
        sm: '12',
        md: '6',
        lg: '6',
        xl: '6',
      },
    },
    {
      id: 'LostByCountryReport',
      component: <LostByCountryReport />,
      isAvailable: true,
      cols: {
        sm: '12',
        md: '6',
        lg: '6',
        xl: '6',
      },
    },
    {
      id: 'LostByUserReport',
      component: <LostByUserReport />,
      isAvailable: true,
      cols: {
        sm: '12',
        md: '6',
        lg: '6',
        xl: '6',
      },
    },
  ];

  return (
    <Content>
      <BlockHead size="sm">
        <BlockHeadContent>
          <BlockTitle page>Statistics</BlockTitle>
        </BlockHeadContent>
      </BlockHead>

      <Row>
        {reports.map(({ id, component, isAvailable, cols: { sm, md, lg, xl } }) => {
          return isAvailable ? (
            <Col key={id} sm={sm} md={md} lg={lg} xl={xl}>
              {component}
            </Col>
          ) : null;
        })}
      </Row>
    </Content>
  );
};
