import { SettingsOutlined } from '@mui/icons-material';
import classNames from 'classnames';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

type PaginationSettingsProps = {
  itemsCountOptions: number[];
  itemsPerPage: number;
  setItemsPerPage: React.Dispatch<React.SetStateAction<number>>;
};
export const PaginationSettings = ({ itemsCountOptions, itemsPerPage, setItemsPerPage }: PaginationSettingsProps) => {
  return (
    <UncontrolledDropdown className="pr-4">
      <DropdownToggle color="tranparent" className="btn btn-trigger btn-icon dropdown-toggle">
        <SettingsOutlined fontSize="small" className="mx-1" />
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-xs">
        <ul className="link-check">
          <li>
            <span>Show</span>
          </li>
          {itemsCountOptions.map((itemsCount) => (
            <li
              key={itemsCount}
              className={classNames({
                active: itemsCount === itemsPerPage,
              })}
            >
              <DropdownItem
                tag="a"
                href="#dropdownitem"
                onClick={(ev) => {
                  ev.preventDefault();
                  setItemsPerPage(itemsCount);
                }}
              >
                {itemsCount}
              </DropdownItem>
            </li>
          ))}
        </ul>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
