import { Controller } from 'react-hook-form';
import { Form, ModalBody, Button } from 'reactstrap';
import { LostReasonOptions } from '../../../../../../../constants';
import { Job, SelectOption } from '../../../../../../../types/types';
import { Col, Row, RSelect } from '../../../../../../Component';
import { useLostReasonForm } from '../../hooks';

type JobLostReasonModalProps = {
  job: Job;
  toggle: () => void;
  jobs?: Record<string, Job[] | []>;
  setJobs?: React.Dispatch<React.SetStateAction<Record<string, Job[] | []>>>;
};

export const JobLostReasonModal = ({ job, toggle, jobs, setJobs }: JobLostReasonModalProps) => {
  const { register, control, errors, handleSubmit, handleAddLostReason } = useLostReasonForm({ job, jobs, setJobs });
  return (
    <ModalBody>
      <Form onSubmit={handleSubmit(handleAddLostReason)}>
        <Row className="gy-2">
          <Col sm="12">
            <h5 className="title">Lost reason</h5>
          </Col>

          <Col sm="12">
            <div className="form-group">
              <Controller
                control={control}
                name="lostReason"
                render={({ field: { onChange, value, name, ref } }) => {
                  return (
                    <RSelect
                      inputRef={ref}
                      options={LostReasonOptions}
                      value={LostReasonOptions.find((lostReason) => lostReason.value === value)}
                      name={name}
                      placeholder="Select lost reason"
                      onChange={(selectedLostReason: SelectOption) => {
                        onChange(selectedLostReason.value);
                      }}
                    />
                  );
                }}
              />
              {errors.lostReason && <span className="invalid">{errors.lostReason.message}</span>}
            </div>
          </Col>

          <Col sm="12">
            <div className="form-group">
              <textarea
                className="form-control no-resize"
                {...register('lostReasonComment')}
                rows={15}
                placeholder="Provide more detailed information here..."
              />
              {errors.lostReasonComment && <span className="invalid">{errors.lostReasonComment.message}</span>}
            </div>
          </Col>

          <Col sm="12">
            <div className="d-flex gap-2 h-100">
              <Button color="primary" onClick={handleSubmit(handleAddLostReason)}>
                Confirm
              </Button>
              <Button type="reset" onClick={() => toggle()} color="secondary">
                Cancel
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </ModalBody>
  );
};
