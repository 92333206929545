export enum LostReasonEnum {
  TooExpensive = 'Too expensive',
  WrongTechStack = 'Wrong tech stack',
  WrongPerson = 'Wrong person',
  WasteOfTime = 'Waste of time',
  AnotherProvider = 'Another provider',
  NotResponding = 'Not responding',
  NoNeed = 'No need',
  InternalTeam = 'Internal team',
  NoDevelopers = 'No developers',
  OutOfTime = 'Out of time',
}
