import React, { forwardRef } from 'react';
import styled from 'styled-components';

interface ICustomScrollProps extends React.ComponentPropsWithRef<'div'> {
  scrollWidth?: number;
  trackColor?: string;
  thumbColor?: string;
  thumbHoverColor?: string;
}

type Ref = HTMLDivElement;

const WithCustomScroll = styled.div<ICustomScrollProps>`
  //TO DO: make it works with animated components from react springs
  position: relative;

  &::-webkit-scrollbar {
    width: ${({ scrollWidth }) => (scrollWidth ? `${scrollWidth}px` : '10px')};
  }

  &::-webkit-scrollbar-track {
    border-radius: 4px;
    background: ${({ trackColor }) => (trackColor ? trackColor : '#dddddd')};
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ thumbColor }) => (thumbColor ? thumbColor : 'rgba(101, 118, 255, 0.7)')};
    border-radius: 4px;
    opacity: 0.8;
  }
`;

// export const DivWithCustomScroll: FC<ICustomScrollProps> = (props) => {
//   return <WithCustomScroll {...props} />;
// };

export const DivWithCustomScroll = forwardRef<Ref, ICustomScrollProps>((props, ref) => {
  return <WithCustomScroll ref={ref} {...props} />;
});
