import classnames from 'classnames';

import React from 'react';

type ColProps = {
  sm?: string;
  lg?: string;
  md?: string;
  xxl?: string;
  size?: string;
  className?: string;
  children: React.ReactNode;
};

export const Col = ({ sm, lg, md, xxl, size, className, ...props }: ColProps) => {
  const classNames = classnames({
    [`col-sm-${sm}`]: sm,
    [`col-lg-${lg}`]: lg,
    [`col-md-${md}`]: md,
    [`col-xxl-${xxl}`]: xxl,
    [`col-${size}`]: size,
    [`${className}`]: className,
  });
  return <div className={classNames}>{props.children}</div>;
};

type RowProps = {
  className?: string;
  children: React.ReactNode;
};

export const Row = ({ className, ...props }: RowProps) => {
  const rowClass = classnames({
    row: true,
    [`${className}`]: className,
  });
  return <div className={rowClass}>{props.children}</div>;
};
