import { Job, UWJob } from '../types';

export const convertUWJobToJob = (rawJob: UWJob): Partial<Job> => {
  const { hourly_range_from, hourly_range_to, raw_data, details } = rawJob;

  const result = {
    ...rawJob,
    hourly_range_from: hourly_range_from?.toString() || undefined,
    hourly_range_to: hourly_range_to?.toString() || undefined,
    details: details?.id || undefined,
    raw_data: raw_data ? JSON.parse(raw_data) : {},
    currency_symbol: undefined,
  };

  return result;
};
