import { createContext, ReactNode } from 'react';
import { useToggle } from '../../hooks';

interface IUserProfileContextProps {
  isProfileSidebarOpen: boolean;
  toggleProfileSidebarOpen(): void;
  isUpdateUserModalOpen: boolean;
  toggleUpdateUserModalOpen(): void;
}

interface IUserProfileProviderProps {
  children: ReactNode;
}

export const UserProfileContext = createContext({
  isProfileSidebarOpen: false,
  toggleProfileSidebarOpen: () => {
    return;
  },
  isUpdateUserModalOpen: false,
  toggleUpdateUserModalOpen: () => {
    return;
  },
} as IUserProfileContextProps);

export const UserProfileProvider = ({ children }: IUserProfileProviderProps) => {
  const [isUpdateUserModalOpen, toggleUpdateUserModalOpen] = useToggle(false);
  const [isProfileSidebarOpen, toggleProfileSidebarOpen] = useToggle(false);

  const contextValue = {
    isUpdateUserModalOpen,
    toggleUpdateUserModalOpen,
    isProfileSidebarOpen,
    toggleProfileSidebarOpen,
  };

  return <UserProfileContext.Provider value={contextValue}>{children}</UserProfileContext.Provider>;
};
