import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe, StripeElementLocale } from '@stripe/stripe-js';
import { FC, useEffect, useState } from 'react';
import { ModalBody, ModalProps } from 'reactstrap';

import { Subscription } from '../../types';

import { Modal } from '../Modal';
import { StripeForm } from './StripeForm';

export interface IStripeModalProps extends ModalProps {
  close(): void;
  subscription?: Subscription;
}

export const StripeModal: FC<IStripeModalProps> = ({ isOpen, toggle, subscription, close }) => {
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);

  useEffect(() => {
    setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_API_KEY as string));
  }, []);

  const options = {
    locale: 'en' as StripeElementLocale,
    clientSecret: subscription?.client_secret,
  };

  return (
    <Modal size="xxl" className="kanban-modal" isOpen={isOpen} toggle={toggle}>
      <ModalBody>
        {stripePromise && subscription?.client_secret && (
          <Elements stripe={stripePromise} options={options}>
            <StripeForm close={close} />
          </Elements>
        )}
      </ModalBody>
    </Modal>
  );
};
