import React, { FC, useEffect, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { Col, Row } from 'reactstrap';
import Swal from 'sweetalert2';

import { useUpdateCompanyMutation } from '../../../app/company/api';
import { selectCurrentCompany } from '../../../app/company/selctors';
import { setCurrentCompany } from '../../../app/company/slice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';

import { RSelect, Button } from '../../../theme-components/Component';

import { Company, SelectOption } from '../../../types';
import { getNormalizedCountriesList } from '../../../utils/GetNormalizedCountriesList';

interface IIgnoredCountriesSettingsProps {
  company?: Company;
}

export const IgnoredCountriesSettings: FC<IIgnoredCountriesSettingsProps> = ({ company }) => {
  const dispatch = useAppDispatch();
  const options = getNormalizedCountriesList().getData();

  const filterOptions = (candidate: { data: { label: string; value: string } }, input: string) => {
    if (input) {
      return candidate.data.label.toLowerCase().includes(input.toLowerCase());
    }
    return true;
  };

  const currentCompany = useAppSelector(selectCurrentCompany);

  const [ignoredCountries, setIgnoredCountries] = useState<SelectOption[]>([]);

  const [updateCompany, { data, isLoading, isError, error }] = useUpdateCompanyMutation();

  const updateIgnoredCountriesListHandler = () => {
    if (!company || !ignoredCountries) {
      return;
    }
    updateCompany({ id: company.id, ignored_countries: ignoredCountries.map(({ label }) => label) });
  };

  useEffect(() => {
    if (company) {
      setIgnoredCountries(
        company?.ignored_countries.map((country) => options.find(({ label }) => label === country)) as SelectOption[],
      );
    }
  }, [company]);

  useEffect(() => {
    if (data && currentCompany?.id === company?.id) {
      dispatch(setCurrentCompany(data));
    }
  }, [data]);

  useEffect(() => {
    if (isError && error) {
      Swal.fire({
        title: 'Server error!',
        text: 'Ignored countries list update failed, please try again later',
        icon: 'error',
      });
    }
  }, [isError, error]);

  return (
    <div className="mt-4">
      <div className="d-flex align-center justify-between">
        <h5 className="title mt-0 mb-0">Ignored countries</h5>
      </div>

      <div className="mt-2">
        <Row>
          <Col sm="9">
            <RSelect
              isClearable
              isMulti
              isDisabled={isLoading}
              options={options}
              getOptionLabel={(option: SelectOption) => {
                return (
                  <div className="d-flex align-center">
                    {option.value && <ReactCountryFlag countryCode={option.value} svg />}
                    <div className="ms-1">{option.label}</div>
                  </div>
                );
              }}
              value={ignoredCountries}
              placeholder="Select countries jobs from which you want to ignore"
              onChange={(currentList: SelectOption[]) => {
                setIgnoredCountries(currentList);
              }}
              filterOption={filterOptions}
            />
          </Col>

          <Col className="justify-end align-center">
            <Button color="primary" type="button" disabled={isLoading} onClick={updateIgnoredCountriesListHandler}>
              Confirm
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};
