import { CalendarMonth, Notes } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { Controller, FormProvider } from 'react-hook-form';
import { Button, Form } from 'reactstrap';
import Swal from 'sweetalert2';

import {
  JobCopyLink,
  JobModalActions,
  JobHistory,
  JobComments,
  JobModalTabs,
  JobGeneralInfo,
  JobStatus,
  JobStages,
} from '..';

import { useEditJobMutation } from '../../../../../../../app/jobs/api';

import { formatDate } from '../../../../../../../helpers/date';

import { UpworkButton } from '../../../../../../../styles';

import { Job, JobModalTabEnum } from '../../../../../../../types';
import { SelectOption } from '../../../../../../../types/types';

import { getNormalizedCountriesList } from '../../../../../../../utils/GetNormalizedCountriesList';

import { Row, Col, RSelect } from '../../../../../../Component';

import { useDeadlineDate, useJobModal } from '../../../../hooks';
import { JobPrice } from '../../../JobPrice';
import { useAssigneeOptions, useKanbanTaskForm } from '../../hooks';

import styles from './styles.module.scss';

type JobModalContentProps = {
  jobData: Job;
  isArchive?: boolean;
  jobs?: Record<string, Job[] | []>;
  setJobs?: React.Dispatch<React.SetStateAction<Record<string, Job[] | []>>>;
};

export const JobModalContent = ({ jobData, jobs, setJobs, isArchive }: JobModalContentProps) => {
  const normalizedCountriesList = getNormalizedCountriesList();
  const [modalTab, setModalTab] = useState<JobModalTabEnum>(JobModalTabEnum.General);

  const { handleCloseJobModal } = useJobModal();

  const { handleJobUpdate, formMethods, isLoading } = useKanbanTaskForm({
    jobData: jobData,
  });

  const [updateJob, { isLoading: isUpdateLoading, isSuccess, isError, error }] = useEditJobMutation();

  const resurrectJobHandler = () => {
    updateJob({ id: jobData.id, status: null });
  };

  const { handleSubmit, control, reset } = formMethods;

  const { assigneeOptions } = useAssigneeOptions();

  const { deadlineLabel, deadlineState } = useDeadlineDate({
    column: jobData.column,
    deadlineDate: jobData.dead_line_date,
  });

  const countryCode = jobData?.country ? normalizedCountriesList.getValue(jobData.country) : '';

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        title: 'Sucess',
        text: 'Job was moved back to Kanban successfully',
        icon: 'success',
      });
      handleCloseJobModal();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError && error) {
      Swal.fire({
        title: 'Error',
        text: 'Something went wrong during moving job back to Kanban',
        icon: 'error',
      });
    }
  }, [isError, error]);

  useEffect(() => {
    if (jobData) {
      reset({
        column: jobData.column,
        cover_letter: jobData?.cover_letter || '',
        assignee: jobData?.assignee?.id || '',
      });
    }
  }, [jobData]);

  return (
    <FormProvider {...formMethods}>
      <Form className="p-2" onSubmit={handleSubmit(handleJobUpdate)}>
        <Row className="gy-2 gx-sm-0 gx-sm-2">
          <Col sm="12" md="12" lg="10">
            <div className={styles.titleContainer}>
              <h4 className="title">{jobData.title}</h4>
              <JobCopyLink className={styles.icon} />
            </div>

            <div className={styles.infoContainer}>
              {jobData.dead_line_date && (
                <div className={styles.infoItem}>
                  <p className="text-date">
                    <CalendarMonth fontSize="inherit" className="me-1" sx={{ marginBottom: '4px' }} />
                    <span>
                      {formatDate({ date: new Date(jobData.dead_line_date), dateFormat: 'MMMM dd, yyyy, H:mm' })}{' '}
                      <span className={`text-${deadlineState.type}`}>({deadlineLabel})</span>
                    </span>
                  </p>
                </div>
              )}

              <div className={styles.infoItem}>
                <div className="d-flex align-center">
                  <ReactCountryFlag countryCode={countryCode} svg />
                  <Typography ml={1} fontSize={14}>
                    {jobData.country}
                  </Typography>
                </div>
              </div>

              <div className={styles.infoItem}>
                <JobPrice
                  fixedPrice={jobData.fixed_price}
                  fixedPriceCurrency={jobData.fixed_price_currency}
                  hourlyRangeFrom={jobData.hourly_range_from}
                  hourlyRangeToCurrency={jobData.hourly_range_from_currency}
                  hourlyRangeTo={jobData.hourly_range_to}
                  hourlyRangeFromCurrency={jobData.hourly_range_to_currency}
                />
              </div>

              <div className={styles.infoItem}>
                <div className="kanban-item-text">
                  <Notes fontSize="small" sx={{ marginBottom: '2px' }} /> {jobData.category}
                </div>
              </div>
            </div>
          </Col>

          <Col sm="8" md="6" lg="5" className="d-flex align-start">
            <UpworkButton link={jobData.link} />
            {isArchive ? (
              <Button color="primary" className="mx-1 mx-lg-2" type="button" onClick={resurrectJobHandler}>
                Move job to kanban
              </Button>
            ) : (
              <JobStatus job={jobData} jobs={jobs} setJobs={setJobs} />
            )}
          </Col>

          <Col sm="4" md="3" lg="3" className={isArchive ? 'offset-md-3 offset-lg-4' : ''}>
            <div className="form-group">
              <div className="form-control-wrap">
                <Controller
                  control={control}
                  name="assignee"
                  render={({ field: { onChange, value, name, ref } }) => {
                    return (
                      <RSelect
                        inputRef={ref}
                        options={assigneeOptions}
                        isDisabled={isLoading || isArchive}
                        value={assigneeOptions.find((assignee: SelectOption) => assignee.value === value)}
                        name={name}
                        placeholder="Assign"
                        onChange={(selectedColumn: SelectOption) => {
                          onChange(selectedColumn.value);
                        }}
                      />
                    );
                  }}
                />
              </div>
            </div>
          </Col>

          <Col sm="12">
            <JobStages id={jobData.id} column={jobData.column} jobs={jobs} setJobs={setJobs} isArchive={isArchive} />
          </Col>
        </Row>

        <JobModalTabs modalTab={modalTab} setModalTab={setModalTab} />
        <div className="tab-content">
          <div className={`tab-pane ${modalTab === JobModalTabEnum.General && 'active'}`} id={JobModalTabEnum.General}>
            <JobGeneralInfo jobData={jobData} isLoading={isLoading || isUpdateLoading} isArchive={isArchive} />
          </div>
          <div
            className={`tab-pane ${modalTab === JobModalTabEnum.Comments && 'active'}`}
            id={JobModalTabEnum.Comments}
          >
            <JobComments id={jobData.id} />
          </div>
          <div className={`tab-pane ${modalTab === JobModalTabEnum.History && 'active'}`} id={JobModalTabEnum.History}>
            <JobHistory id={jobData.id} isFromExtension={jobData.from_extension} />
          </div>
        </div>

        <JobModalActions
          id={jobData.id}
          column={jobData.column}
          isLoading={isLoading || isUpdateLoading}
          jobs={jobs}
          setJobs={setJobs}
          isArchive={isArchive}
        />
      </Form>
    </FormProvider>
  );
};
