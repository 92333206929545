import { ArrowForward, ErrorOutlineOutlined } from '@mui/icons-material';
import React, { FC } from 'react';
import { Alert } from 'reactstrap';
import { useGetHistoryQuery } from '../../../../../../../app/jobs/api';
import { Loader, PaginationSettings } from '../../../../../../../components';
import { getNameInitials } from '../../../../../../../helpers';
import { formatDate } from '../../../../../../../helpers/date';
import { usePagination } from '../../../../../../../hooks';
import { UUID } from '../../../../../../../types/types/UUID';
import { PaginationComponent, UserAvatar } from '../../../../../../Component';

const FieldNameMap: Record<string, string> = {
  title: 'Title',
  dead_line_date: 'Deadline Date',
  assignee: 'Assignee',
  column: 'Column',
  status: 'Status',
  category: 'Category',
  skills: 'Skills',
  cover_letter: 'Cover Letter',
  lost_reason: 'Lost Reason',
  suggestion: 'Suggestions',
};

interface IJobHistoryProps {
  id: UUID;
  isFromExtension: boolean;
}

export const JobHistory: FC<IJobHistoryProps> = ({ id, isFromExtension }) => {
  const {
    itemsPerPage: actionsPerPage,
    setItemsPerPage: setActionsPerPage,
    currentPage,
    paginate,
  } = usePagination({
    initialItemsCountPerPage: 10,
  });

  const {
    data: history,
    isLoading,
    isSuccess,
  } = useGetHistoryQuery({
    limit: actionsPerPage,
    offset: (currentPage - 1) * actionsPerPage,
    id,
  });

  if (isLoading) return <Loader />;

  if (!isSuccess) {
    return (
      <Alert color="danger" className="alert-icon">
        <ErrorOutlineOutlined fontSize="inherit" className="me-1 icon" />
        Failed to load history.
      </Alert>
    );
  }

  const parseValue = (value: string) => {
    const parsedDate = Date.parse(value);
    if (parsedDate) {
      return formatDate({ date: parsedDate, dateFormat: 'MMM dd, yyyy, H:mm' });
    }
    return value;
  };

  return (
    <>
      <ul className="nk-support">
        {history.results.map(
          ({ user: { first_name, last_name }, id, action, field_name, prev_value, new_value, created_at }) =>
            action !== 'closed_with_lost' && (
              <li key={id} className="nk-support-item">
                <UserAvatar text={getNameInitials(first_name, last_name)} />
                <div className="nk-support-content">
                  <div className="title">
                    <span>
                      {first_name} {last_name}
                    </span>
                  </div>
                  {action === 'update' && (
                    <>
                      <span>Updated </span> <span className="fw-bold">{FieldNameMap[field_name]}, </span>
                      <span className="text-date">
                        {formatDate({
                          date: new Date(created_at),
                          dateFormat: 'MMM dd, yyyy, H:mm',
                        })}
                      </span>
                      {field_name === 'status' ? (
                        <p>
                          Job was{' '}
                          <span className="fw-bold text-dark">
                            {new_value ? parseValue(new_value)?.toLowerCase() : 'moved back to kanban'}
                          </span>
                        </p>
                      ) : (
                        <p>
                          <span className="text-gray">{parseValue(prev_value) || 'None'}</span>
                          <ArrowForward fontSize="inherit" className="mx-1" sx={{ marginBottom: '4px' }} />
                          <span className="text-dark">{parseValue(new_value) || 'None'}</span>
                        </p>
                      )}
                    </>
                  )}
                  {action === 'add' && (
                    <>
                      <span className="fw-bold">{`Added the job ${
                        isFromExtension ? 'from extension' : 'from swiper'
                      }`}</span>
                      <ArrowForward fontSize="inherit" className="mx-1" sx={{ marginBottom: '4px' }} />
                      <span className="text-date">
                        {formatDate({
                          date: new Date(created_at),
                          dateFormat: 'MMM dd, yyyy, H:mm',
                        })}
                      </span>
                    </>
                  )}
                  {action === 'commented' && (
                    <>
                      <span className="fw-bold">Left a comment, </span>
                      <span className="text-date">
                        {formatDate({
                          date: new Date(created_at),
                          dateFormat: 'MMM dd, yyyy, H:mm',
                        })}
                      </span>
                    </>
                  )}
                </div>
              </li>
            ),
        )}
      </ul>
      {history.count > 0 && (
        <div className="card-inner d-flex flex-row ">
          <PaginationSettings
            itemsCountOptions={[10, 20, 30]}
            itemsPerPage={actionsPerPage}
            setItemsPerPage={setActionsPerPage}
          />
          <PaginationComponent
            itemPerPage={actionsPerPage}
            totalItems={history.count}
            paginate={paginate}
            currentPage={currentPage}
          />
        </div>
      )}
    </>
  );
};
