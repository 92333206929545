import { baseApi } from '../baseApi';
import { API_ENDPOINTS } from '../endpoints';

export const upworkApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getLink: build.query<{ link: string }, void>({
      query: () => ({
        url: API_ENDPOINTS.UPWORK.LINK,
      }),
    }),
    setCode: build.query<any, { code: string }>({
      query: (params) => ({
        url: API_ENDPOINTS.UPWORK.CALLBACK,
        params,
      }),
    }),
    disconnectUpwork: build.mutation<any, void>({
      query: () => ({
        url: API_ENDPOINTS.UPWORK.DISCONNECT,
        method: 'DELETE',
      }),
      invalidatesTags: ['CompaniesList'],
    }),
  }),
});

export const { useLazyGetLinkQuery, useLazySetCodeQuery, useDisconnectUpworkMutation } = upworkApi;
