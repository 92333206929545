import {
  ArrowForwardIos,
  BorderColorOutlined,
  ErrorOutlineOutlined,
  Lock,
  MoreVert,
  NotificationsOutlined,
  PersonOutlineOutlined,
} from '@mui/icons-material';
import classNames from 'classnames';
import { useContext } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { Alert, Card, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

import { useGetUserProfileQuery } from '../../app/user/api';

import { Layout } from '../../components/Layout/Layout';

import { getNameInitials } from '../../helpers';
import { useLayoutView } from '../../hooks/useLayoutView';

import Content from '../../layout/content/Content';
import { UserAvatar } from '../../theme-components/Component';

import { UserProfileTabEnum } from '../../types';
import { cutString } from '../../utils/CutString';

import { UpdateUserModal } from './components';

import { UserProfileContext, UserProfileProvider } from './UserProfileContext';

const UserProfileInner = () => {
  const { isUpdateUserModalOpen, toggleUpdateUserModalOpen, isProfileSidebarOpen, toggleProfileSidebarOpen } =
    useContext(UserProfileContext);

  const location = useLocation();

  const { data: userProfileData, isSuccess, isLoading } = useGetUserProfileQuery();

  const isMobileView = useLayoutView({
    onMobileView: toggleProfileSidebarOpen,
  });

  const menuItems = [
    {
      title: 'Personal Information',
      name: UserProfileTabEnum.PersonalInformation,
      icon: <PersonOutlineOutlined fontSize="small" className="me-1" />,
    },
    {
      title: 'Notifications',
      name: UserProfileTabEnum.Notifications,
      icon: <NotificationsOutlined fontSize="small" className="me-1" />,
    },
    {
      title: 'Security settings',
      name: UserProfileTabEnum.SecuritySettings,
      icon: <Lock fontSize="small" className="me-1" />,
    },
  ];

  return (
    <Layout>
      <Content>
        {isSuccess && !isLoading ? (
          <Card className="card-bordered">
            <div className="card-aside-wrap">
              <div
                className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
                  isProfileSidebarOpen ? 'content-active' : ''
                }`}
                style={{ minHeight: 'unset' }}
              >
                <div className="card-inner-group">
                  <div className="card-inner">
                    <div className="user-card">
                      <UserAvatar
                        text={getNameInitials(userProfileData.first_name, userProfileData.last_name)}
                        theme="primary"
                      />
                      <div className="user-info">
                        <span className="lead-text">
                          {cutString(24, userProfileData.first_name + ' ' + userProfileData.last_name)}
                        </span>
                        <span className="sub-text">{userProfileData.email}</span>
                      </div>
                      <div className="user-action">
                        <UncontrolledDropdown>
                          <DropdownToggle tag="a" className="btn btn-icon btn-trigger me-n2">
                            <MoreVert fontSize="small" sx={{ margin: '0 8px' }} />
                          </DropdownToggle>
                          <DropdownMenu end>
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <DropdownItem
                                  tag="a"
                                  href="#dropdownitem"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    toggleUpdateUserModalOpen();
                                  }}
                                >
                                  <BorderColorOutlined fontSize="inherit" className="me-1" />
                                  <span>Update Profile</span>
                                </DropdownItem>
                              </li>
                            </ul>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </div>
                  </div>
                  <div className="card-inner p-0">
                    <ul className="link-list-menu">
                      {menuItems.map(({ name, title, icon }) => (
                        <li key={name} onClick={toggleProfileSidebarOpen}>
                          <Link
                            to={name}
                            className={classNames({
                              active: location.pathname.includes(name),
                            })}
                          >
                            <div className="w-100 d-flex justify-between align-center" style={{ color: 'inherit' }}>
                              <span className="d-flex align-center">
                                {icon}
                                {title}
                              </span>

                              <ArrowForwardIos fontSize="inherit" color="inherit" />
                            </div>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="card-inner card-inner-lg">
                {isProfileSidebarOpen && isMobileView && (
                  <div className="toggle-overlay" onClick={toggleProfileSidebarOpen}></div>
                )}

                <Outlet />
              </div>
            </div>
          </Card>
        ) : (
          <Alert color="danger" className="alert-icon mt-3">
            <ErrorOutlineOutlined fontSize="inherit" className="me-1 icon" />
            Failed to load user profile data.
          </Alert>
        )}
      </Content>
      {isUpdateUserModalOpen && (
        <UpdateUserModal isOpen={isUpdateUserModalOpen} toggleOpen={toggleUpdateUserModalOpen} />
      )}
    </Layout>
  );
};

export const UserProfile = () => {
  return (
    <UserProfileProvider>
      <UserProfileInner />
    </UserProfileProvider>
  );
};
