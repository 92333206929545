import { JobModalTabEnum } from '../../../../../../../types';

type JobModalTabsProps = {
  modalTab: JobModalTabEnum;
  setModalTab: React.Dispatch<React.SetStateAction<JobModalTabEnum>>;
};

export const JobModalTabs = ({ modalTab, setModalTab }: JobModalTabsProps) => {
  return (
    <ul className="nk-nav nav nav-tabs">
      <li className="nav-item">
        <a
          className={`nav-link ${modalTab === JobModalTabEnum.General && 'active'}`}
          onClick={(ev) => {
            ev.preventDefault();
            setModalTab(JobModalTabEnum.General);
          }}
          href={`#${JobModalTabEnum.General}`}
        >
          General
        </a>
      </li>
      <li className="nav-item">
        <a
          className={`nav-link ${modalTab === JobModalTabEnum.Comments && 'active'}`}
          onClick={(ev) => {
            ev.preventDefault();
            setModalTab(JobModalTabEnum.Comments);
          }}
          href={`#${JobModalTabEnum.Comments}`}
        >
          Comments
        </a>
      </li>
      <li className="nav-item">
        <a
          className={`nav-link ${modalTab === JobModalTabEnum.History && 'active'}`}
          onClick={(ev) => {
            ev.preventDefault();
            setModalTab(JobModalTabEnum.History);
          }}
          href={`#${JobModalTabEnum.History}`}
        >
          History
        </a>
      </li>
    </ul>
  );
};
