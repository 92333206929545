import { ArrowForwardIos } from '@mui/icons-material';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

type LinkItemProps = {
  tag?: string;
  link: string;
  icon?: JSX.Element;
  text?: string;
  onClick?: () => void;
  children?: React.ReactNode;
  state?: any;
  replace?: boolean;
};

export const LinkItem = ({ tag, link, icon, text, replace, children, state, ...props }: LinkItemProps) => {
  return (
    <li>
      {tag !== 'a' ? (
        <Link to={link} state={state} replace={replace} {...props}>
          <div className="w-100 d-flex justify-between align-center">
            <span>
              {icon ? icon : null}
              {text || children}
            </span>

            <ArrowForwardIos fontSize="inherit" color="inherit" />
          </div>
        </Link>
      ) : (
        <a href={link} target="_blank">
          <div className="w-100 d-flex justify-between align-center">
            <span>
              {icon ? icon : null}
              {text || children}
            </span>

            <ArrowForwardIos fontSize="inherit" color="inherit" />
          </div>
        </a>
      )}
    </li>
  );
};

export const LinkList = ({ ...props }) => {
  const listClasses = classNames({
    'link-list': !props.opt,
    'link-list-opt': props.opt,
    [`${props.className}`]: props.className,
  });
  return <ul className={listClasses}>{props.children}</ul>;
};
