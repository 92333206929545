import {
  ActivateRequest,
  Authorisation,
  ForgotPasswordRequest,
  LoginRequest,
  RegisterRequest,
  ResetPasswordRequest,
} from '../../types';
import { baseApi } from '../baseApi';
import { API_ENDPOINTS } from '../endpoints';
import { LogoutRequest } from './../../types/types/LogoutRequest';

export const authApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<Authorisation, LoginRequest>({
      query: (body: LoginRequest) => ({
        url: API_ENDPOINTS.AUTH.LOGIN,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['User', 'CompaniesList'],
    }),
    register: build.mutation<
      {
        msg: string;
      },
      RegisterRequest
    >({
      query: (body: RegisterRequest) => ({
        url: API_ENDPOINTS.AUTH.REGISTER,
        method: 'POST',
        body,
      }),
    }),
    forgotPassword: build.mutation<string, ForgotPasswordRequest>({
      query: (body: ForgotPasswordRequest) => ({
        url: API_ENDPOINTS.AUTH.FORGOT_PASSWORD,
        method: 'POST',
        body,
      }),
    }),
    resetPassword: build.mutation<string, ResetPasswordRequest>({
      query: (body: ResetPasswordRequest) => ({
        url: API_ENDPOINTS.AUTH.RESET_PASSWORD,
        method: 'POST',
        body,
      }),
    }),
    activateProfile: build.mutation<string, ActivateRequest>({
      query: (body: ActivateRequest) => ({
        url: API_ENDPOINTS.AUTH.ACTIVE,
        method: 'POST',
        body,
      }),
    }),
    logout: build.mutation<void, LogoutRequest>({
      query: (body: LogoutRequest) => ({
        url: API_ENDPOINTS.AUTH.LOGOUT,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useActivateProfileMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useLogoutMutation,
} = authApi;
