import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: 'upwork-crm-170f8.firebaseapp.com',
  projectId: 'upwork-crm-170f8',
  storageBucket: 'upwork-crm-170f8.appspot.com',
  messagingSenderId: '101289319056',
  appId: '1:101289319056:web:c19ef9e52a9a55faf0d615',
};

export const firebaseApp = initializeApp(firebaseConfig);

export const messaging = getMessaging(firebaseApp);

const getOrRegisterServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    return window.navigator.serviceWorker.getRegistration('/firebase-push-notification-scope').then((serviceWorker) => {
      if (serviceWorker) return serviceWorker;
      return window.navigator.serviceWorker.register(
        `/firebase-messaging-sw.js?firebaseConfig=${encodeURIComponent(JSON.stringify(firebaseConfig))}`,
        {
          scope: '/firebase-push-notification-scope',
        },
      );
    });
  }
  throw new Error('The browser doesn`t support service worker.');
};

export const getFirebaseToken = () =>
  getOrRegisterServiceWorker().then((serviceWorkerRegistration) =>
    getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY, serviceWorkerRegistration }),
  );
