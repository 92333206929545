import classNames from 'classnames';
import React from 'react';

// TODO: recheck props usage and types of these components/split into separate files

type BlockProps = {
  className?: string;
  size?: string; // TODO: check acceptable sizes for the template  e.g. 'sm' | 'md' | 'lg';
  children?: React.ReactNode;
};
export const Block = ({ className, size, ...props }: BlockProps) => {
  const blockClass = classNames({
    'nk-block': true,
    [`nk-block-${size}`]: size,
    [`${className}`]: className,
  });
  return <div className={blockClass}>{props.children}</div>;
};

type BlockContentProps = {
  className?: string;
  children?: React.ReactNode;
};
export const BlockContent = ({ className, ...props }: BlockContentProps) => {
  const blockContentClass = classNames({
    'nk-block-content': true,
    [`${className}`]: className,
  });
  return <div className={blockContentClass}>{props.children}</div>;
};

type BlockBetweenProps = {
  className?: string;
  children?: React.ReactNode;
};
export const BlockBetween = ({ className, ...props }: BlockBetweenProps) => {
  return <div className={`nk-block-between ${className ? className : ''}`}>{props.children}</div>;
};

type BlockHeadProps = {
  className?: string;
  size?: string;
  wide?: string;
  children?: React.ReactNode;
};
export const BlockHead = ({ className, size, wide, ...props }: BlockHeadProps) => {
  const blockHeadClass = classNames({
    'nk-block-head': true,
    [`nk-block-head-${size}`]: size,
    [`wide-${wide}`]: wide,
    [`${className}`]: className,
  });
  return <div className={blockHeadClass}>{props.children}</div>;
};

type BlockHeadContentProps = {
  className?: string;
  children?: React.ReactNode;
};
export const BlockHeadContent = ({ className, ...props }: BlockHeadContentProps) => {
  return <div className={`nk-block-head-content${className ? ' ' + className : ''}`}>{props.children}</div>;
};

type BlockTitleProps = {
  className?: string;
  page?: boolean;
  children?: React.ReactNode;
  tag?: keyof JSX.IntrinsicElements;
};
export const BlockTitle = ({ className, page = false, ...props }: BlockTitleProps) => {
  const classes = `nk-block-title ${page ? 'page-title' : 'title'}${className ? ' ' + className : ''}`;
  return (
    <React.Fragment>
      {!props.tag ? (
        <h3 className={classes}>{props.children}</h3>
      ) : (
        <props.tag className={classes}>{props.children}</props.tag>
      )}
    </React.Fragment>
  );
};

type BlockDesProps = {
  className?: string;
  children?: React.ReactNode;
};
export const BlockDes = ({ className, ...props }: BlockDesProps) => {
  const classes = `nk-block-des${className ? ' ' + className : ''}`;
  return <div className={classes}>{props.children}</div>;
};

type BackToProps = {
  className?: string;
  link?: string;
  icon?: string;
  children?: React.ReactNode;
};
export const BackTo = ({ className, link, icon, ...props }: BackToProps) => {
  const classes = [`back-to${className ? ' ' + className : ''}`];
  return (
    <div className="nk-block-head-sub">
      {/* <Link className={classes} to={process.env.PUBLIC_URL + link}>
        <Icon name={icon} />
        <span>{props.children}</span>
      </Link> */}
    </div>
  );
};
