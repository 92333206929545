import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import Swal from 'sweetalert2';
import * as yup from 'yup';
import { useAddLostReasonMutation, useEditJobMutation } from '../../../../../../app/jobs/api';
import { LostReasonEnum, Job, JobStatusEnum } from '../../../../../../types';
import { useJobModal } from './../../../hooks/useJobModal';

const lostReasonSchema = yup
  .object({
    lostReason: yup.mixed<LostReasonEnum>().oneOf(Object.values(LostReasonEnum)).required('Lost reason is required'),
    lostReasonComment: yup.string().trim().required('Lost reason comment is required'),
  })
  .required();

type LostReasonSchema = yup.InferType<typeof lostReasonSchema>;

interface ILostReasonFormProps {
  job: Job;
  jobs?: Record<string, Job[] | []>;
  setJobs?: React.Dispatch<React.SetStateAction<Record<string, Job[] | []>>>;
}

export const useLostReasonForm = ({ job, jobs, setJobs }: ILostReasonFormProps) => {
  const { handleCloseJobModal } = useJobModal();
  const [addLostReason, { isLoading: isAddingLostReason }] = useAddLostReasonMutation();
  const [updateJob] = useEditJobMutation();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LostReasonSchema>({
    resolver: yupResolver(lostReasonSchema),
  });

  const handleAddLostReason: SubmitHandler<LostReasonSchema> = async ({ lostReason, lostReasonComment }) => {
    const result = await Swal.fire({
      title: 'Are you sure to change status to Lost?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, proceed',
      preConfirm: async () => {
        try {
          await updateJob({ id: job.id, status: JobStatusEnum.Lost });
          return await addLostReason({
            job: job.id,
            reason: lostReason,
            comment: lostReasonComment,
          });
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`);
        }
      },
      allowOutsideClick: () => !isAddingLostReason,
    });
    if (result.isConfirmed) {
      handleCloseJobModal();
      await Swal.fire({
        title: 'Done',
        text: 'Job status has been changed to Lost.',
        icon: 'success',
      });

      if (jobs && setJobs) {
        setJobs({ ...jobs, [job.column]: jobs[job.column].filter((j) => j.id !== job.id) });
      }
    }
  };

  return {
    register,
    control,
    handleSubmit,
    errors,
    handleAddLostReason,
  };
};
