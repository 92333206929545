import { FC } from 'react';
import { Spinner } from 'reactstrap';
import styles from './styles.module.scss';

interface ILoaderProps {
  className?: string;
}

export const Loader: FC<ILoaderProps> = ({ className }) => {
  return (
    <div className={styles.loaderContainer + ` ${className}`}>
      <Spinner
        type="grow"
        style={{
          height: '100px',
          width: '100px',
        }}
      />
    </div>
  );
};
