import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

import { isUserAuthenticatedSelector } from '../app/auth/selectors';
import { ROUTES, ROUTE_CONSTRUCTOR } from './routes';

const PublicOnlyRoute = ({ element }: { element: JSX.Element }) => {
  const isUserAuthenticated = useSelector(isUserAuthenticatedSelector);
  const location = useLocation();

  return !isUserAuthenticated || location.pathname.includes(ROUTE_CONSTRUCTOR.AUTH.RESET_PASSWORD) ? (
    element
  ) : (
    <Navigate to={ROUTES.SWIPER} />
  );
};

export default PublicOnlyRoute;
