import { Box } from '@mui/material';
import React, { useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import DataTable from 'react-data-table-component';

import { useGetJobsQuery } from '../../app/jobs/api';
import { LostReasonOptions } from '../../constants';

import { formatDate } from '../../helpers/date';

import Content from '../../layout/content/Content';
import { useCustomTableStyle } from '../../styles/hooks';

import { JobModal } from '../../theme-components/_complex/kanban/components';
import { useJobModal } from '../../theme-components/_complex/kanban/hooks';
import { BlockHead, BlockBetween, BlockHeadContent, BlockTitle, RSelect } from '../../theme-components/Component';

import { Job, JobStatusEnum, LostReasonEnum } from '../../types';
import { SelectOption } from '../../types/types';

import { getNormalizedCountriesList } from '../../utils/GetNormalizedCountriesList';
import { Modal } from '../Modal';

export const ArchiveInner = () => {
  const { customStyles } = useCustomTableStyle();
  const normalizedCountriesList = getNormalizedCountriesList();

  const [page, setPage] = useState(1);
  const [rowPerPage, setRowsPerPage] = useState(10);

  const [lostReason, setLostReason] = useState<SelectOption | null>(null);

  const { searchParams, handleCloseJobModal, handleOpenJobModal } = useJobModal();

  const { data, isLoading, isFetching } = useGetJobsQuery({
    limit: rowPerPage,
    offset: rowPerPage * (page - 1),
    status: JobStatusEnum.Lost,
    lost_reason__reason: lostReason?.value as LostReasonEnum,
  });

  const columns = [
    { name: 'Title', selector: (job: Job) => job.title },
    { name: 'Lost reason', selector: (job: Job) => job.lost_reason.reason },
    {
      name: 'Country',
      cell: (job: Job) => {
        const countryCode = job?.country ? normalizedCountriesList.getValue(job.country) : '';

        return (
          <div className="d-flex align-center gap-1">
            <ReactCountryFlag countryCode={countryCode} svg />
            <div className="ml-2">{job.country}</div>
          </div>
        );
      },
    },
    { name: 'Category', selector: (job: Job) => job.category },
    {
      name: 'Lost date',
      selector: (job: Job) =>
        job?.lost_reason
          ? formatDate({ date: new Date(job.lost_reason?.created_at), dateFormat: 'MMM dd, yyyy, H:mm' })
          : '',
    },
  ];

  return (
    <Content>
      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle page>Archive</BlockTitle>
          </BlockHeadContent>

          {(!!data?.count || (!data?.count && lostReason)) && (
            <Box minWidth={300}>
              {' '}
              <RSelect
                hideSelectedOptions
                isClearable
                options={LostReasonOptions}
                value={lostReason}
                getOptionLabel={(value: SelectOption) => value?.label || ''}
                placeholder="Select lost reason"
                onChange={(selectedLostReason: SelectOption, action: string) => {
                  if (action === 'clear') {
                    setLostReason(null);
                    return;
                  }
                  setLostReason(selectedLostReason);
                }}
              />
            </Box>
          )}
        </BlockBetween>
      </BlockHead>

      <div className="mt-2">
        <DataTable
          columns={columns}
          data={data?.results || []}
          progressPending={isLoading || isFetching}
          pagination
          paginationServer
          paginationTotalRows={data?.count || 0}
          onChangeRowsPerPage={setRowsPerPage}
          onChangePage={setPage}
          onRowClicked={(job: Job) => handleOpenJobModal(job.id)}
          customStyles={customStyles}
        />
      </div>

      {searchParams.get('id') && (
        <Modal size="xxl" className="kanban-modal" isOpen={!!searchParams.get('id')} toggle={handleCloseJobModal}>
          <JobModal id={searchParams.get('id')!} isArchive={true} />
        </Modal>
      )}
    </Content>
  );
};
