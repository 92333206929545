import { useState } from 'react';

type UsePaginationParams = {
  initialItemsCountPerPage: number;
};

export const usePagination = ({ initialItemsCountPerPage }: UsePaginationParams) => {
  const [itemsPerPage, setItemsPerPage] = useState(initialItemsCountPerPage);
  const [currentPage, setCurrentPage] = useState(1);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return {
    itemsPerPage,
    setItemsPerPage,
    currentPage,
    paginate,
  };
};
