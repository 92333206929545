import React from 'react';
import { Bar } from 'react-chartjs-2';
import { useGetLostReasonByUserReportQuery } from '../../../app/stats/api';
import { BlockTitle } from '../../../theme-components/Component';
import { cutString } from '../../../utils/CutString';
import { Loader } from '../../Loader';

export const LostByUserReport = () => {
  const { data, isLoading, isError } = useGetLostReasonByUserReportQuery();

  const preparedData = {
    labels: data?.by_user?.map(({ first_name, last_name }) => cutString(20, `${first_name} ${last_name}`)),
    datasets: [
      {
        label: 'Jobs lost by',
        data: data?.by_user?.map(({ count }) => count),
        backgroundColor: '#E85347',
      },
    ],
  };

  let content;

  if (data && preparedData) {
    content = data.by_user.some(({ count }) => !!count) ? (
      <Bar
        data={preparedData}
        options={{
          legend: {
            display: false,
          },
          tooltips: {
            enabled: true,
            backgroundColor: '#eff6ff',
            titleFontSize: 13,
            titleFontColor: '#6783b8',
            titleMarginBottom: 6,
            bodyFontColor: '#9eaecf',
            bodyFontSize: 12,
            bodySpacing: 4,
            yPadding: 10,
            xPadding: 10,
            footerMarginTop: 0,
            displayColors: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  reverse: false,
                  stepSize: 1,
                  min: 0,
                },
              },
            ],
          },
        }}
      />
    ) : (
      <div className="text-center my-2 mx-4">Sorry, there is not enough data for current company to report</div>
    );
  }

  if (isError) {
    content = (
      <div className="text-center mt-2 mb-2">The error occurred while loading report. Please refresh page later</div>
    );
  }

  if (isLoading) {
    content = (
      <div className="mt-2 mb-2">
        <Loader className="h-100" />;
      </div>
    );
  }

  return (
    <div className="card card-bordered card-preview mt-2">
      <div className="card-inner">
        <BlockTitle>Jobs lost by user report</BlockTitle>
      </div>

      {content}
    </div>
  );
};
