import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'reactstrap';
import { Col } from 'reactstrap';
import Swal from 'sweetalert2';
import * as yup from 'yup';
import { useUpdateCompanyMutation } from '../../../app/company/api';
import { selectCurrentCompany } from '../../../app/company/selctors';
import { setCurrentCompany } from '../../../app/company/slice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { userProfileSelector } from '../../../app/user/selectors';
import { Button } from '../../../theme-components/Component';

import { ColumnEnum, RoleEnum, UpdateExpireColumnParam } from '../../../types';
import { ITabContentProps } from '../CompanyInner';
import { IgnoredCountriesSettings } from './IgnoredCountriesSettings';
import { RssLinksSettings } from './RssLinksSettings';

const expireTimeSchema: yup.SchemaOf<UpdateExpireColumnParam> = yup
  .object()
  .shape({
    deadline_like: yup
      .number()
      .min(1, 'Minimum amount is 1 hour')
      .max(32767, 'Maximum amount is 32767 hours')
      .integer('Must be an integer')
      .typeError('Must be a number')
      .required('Deadline is required'),
    deadline_sent: yup
      .number()
      .min(1, 'Minimum amount is 1 hour')
      .max(32767, 'Maximum amount is 32767 hours')
      .integer('Must be an integer')
      .typeError('Must be a number')
      .required('Deadline is required'),
    deadline_answer_received: yup
      .number()
      .min(1, 'Minimum amount is 1 hour')
      .max(32767, 'Maximum amount is 32767 hours')
      .integer('Must be an integer')
      .typeError('Must be a number')
      .required('Deadline is required'),
    deadline_call_made: yup
      .number()
      .min(1, 'Minimum amount is 1 hour')
      .max(32767, 'Maximum amount is 32767 hours')
      .integer('Must be an integer')
      .typeError('Must be a number')
      .required('Deadline is required'),
  })
  .required();

interface IExpireTimeInput {
  name: ColumnEnum;
  input: 'deadline_like' | 'deadline_sent' | 'deadline_answer_received' | 'deadline_call_made';
}

export const SettingsTab: FC<ITabContentProps> = ({ company, companyDataLoading }) => {
  const dispatch = useAppDispatch();

  const currentCompany = useAppSelector(selectCurrentCompany);
  const currentUser = useAppSelector(userProfileSelector);

  const [updateCompany, { data: updateCompanyData, isLoading: updateLoading, isError, error }] =
    useUpdateCompanyMutation();

  const {
    formState: { errors },
    register,
    reset,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(expireTimeSchema),
    defaultValues: {
      deadline_like: company?.deadline_like,
      deadline_sent: company?.deadline_sent,
      deadline_answer_received: company?.deadline_answer_received,
      deadline_call_made: company?.deadline_call_made,
    },
  });

  const expireTimeInputs: IExpireTimeInput[] = [
    { name: ColumnEnum.Like, input: 'deadline_like' },
    { name: ColumnEnum.Sent, input: 'deadline_sent' },
    { name: ColumnEnum.AnswerReceived, input: 'deadline_answer_received' },
    { name: ColumnEnum.CallMade, input: 'deadline_call_made' },
  ];

  const sendFormHandler = (formValues: UpdateExpireColumnParam) => {
    const updatedDeadlines = {} as Record<string, number>;

    Object.entries(formValues).forEach(([key, value]) => {
      if (
        value ===
        company?.[key as 'deadline_like' | 'deadline_sent' | 'deadline_answer_received' | 'deadline_call_made']
      ) {
        return;
      }

      updatedDeadlines[key] = value;
    });

    company && updateCompany({ id: company.id, ...updatedDeadlines });
  };

  useEffect(() => {
    if (company) {
      reset({
        deadline_like: company?.deadline_like,
        deadline_sent: company?.deadline_sent,
        deadline_answer_received: company?.deadline_answer_received,
        deadline_call_made: company?.deadline_call_made,
      });
    }
  }, [company]);

  useEffect(() => {
    if (updateCompanyData && currentCompany?.id === company?.id) {
      dispatch(setCurrentCompany(updateCompanyData));
    }
  }, [updateCompanyData]);

  useEffect(() => {
    if (isError && error && 'data' in error) {
      Swal.fire({
        title: 'Server error!',
        text: (error?.data as { details: Record<string, string[]> })?.details
          ? Object.values((error?.data as { details: Record<string, string[]> })?.details)?.join(' ')
          : 'Deadline update failed, please try again later',
        icon: 'error',
      });
    }
  }, [isError, error]);

  const isLoading = updateLoading || companyDataLoading;

  return (
    <div>
      <h5 className="title">Columns expire time (in hours)</h5>

      <Form className="row gy-4 mt-2" noValidate onSubmit={handleSubmit(sendFormHandler)}>
        {expireTimeInputs.map(({ name, input }) => (
          <Col sm="6" md="3" lg="2" key={name}>
            <div className="form-group">
              <label className="form-label">{name}</label>
              <div>
                <input
                  type="number"
                  disabled={isLoading || currentUser.role !== RoleEnum.Admin}
                  className="form-control"
                  {...register(input, {
                    valueAsNumber: true,
                  })}
                />
                {errors[input] && <span className="invalid">{errors[input]?.message as string}</span>}
              </div>
            </div>
          </Col>
        ))}
        <Col
          sm="12"
          lg="4"
          className={classNames('d-flex justify-end', {
            'align-items-end': !Object.keys(errors).length,
            'align-items-center pt-1': Object.keys(errors).length,
          })}
        >
          {currentUser.role === RoleEnum.Admin && (
            <Button color="primary" type="submit">
              Confirm
            </Button>
          )}
        </Col>
      </Form>

      <IgnoredCountriesSettings company={company} />

      <RssLinksSettings company={company} />
    </div>
  );
};
