import React, { FC } from 'react';
import { useSpring } from 'react-spring';
import { ButtonProps } from 'reactstrap';
import { StyledProButton, Blink } from './ProButton.style';

export const ProButton: FC<ButtonProps> = ({ children, ...props }) => {
  const [springProps] = useSpring(() => ({
    from: {
      left: '-20%',
    },
    to: {
      left: '120%',
    },
    loop: true,
    config: {
      duration: 1000,
    },
    delay: 3000,
  }));

  return (
    <StyledProButton {...props}>
      {children}
      <Blink style={springProps} />
    </StyledProButton>
  );
};
