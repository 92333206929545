import React from 'react';
import { Job } from '../../../../../types';
import { JobCard } from '../JobCard';

type JobCardListProps = {
  displayedJobs: Job[];
  jobs: Record<string, Job[] | []>;
  setJobs: React.Dispatch<React.SetStateAction<Record<string, Job[] | []>>>;
};

export const JobCardList = ({ displayedJobs, jobs, setJobs }: JobCardListProps) => {
  return (
    <>
      {displayedJobs.length > 0 ? (
        displayedJobs.map((job, index) => (
          <JobCard key={job.id} job={job} index={index} jobs={jobs} setJobs={setJobs} />
        ))
      ) : (
        <div className="kanban-drag" />
      )}
    </>
  );
};
