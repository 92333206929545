import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../router/routes';

// TODO: JS -> TS, use future-created custom Input component with additional functionality of hide/show

export const PasswordInput = ({ register, errors, label, id, withForgotPasswordLink }) => {
  const [isPasswordShown, setIsPasswordShown] = useState(false);

  return (
    <div className="form-group">
      <div className="form-label-group">
        <label className="form-label" htmlFor={id}>
          {label}
        </label>
        {withForgotPasswordLink && (
          <Link className="link link-primary link-sm" to={ROUTES.AUTH.FORGOT_PASSWORD}>
            Forgot Password?
          </Link>
        )}
      </div>
      <div className="form-control-wrap">
        <a
          href={`#${id}`}
          onClick={(ev) => {
            ev.preventDefault();
            setIsPasswordShown(!isPasswordShown);
          }}
          className={`form-icon lg form-icon-right passcode-switch ${isPasswordShown ? 'is-hidden' : 'is-shown'}`}
        >
          <VisibilityOutlined fontSize="small" className="passcode-icon icon-show" />
          <VisibilityOffOutlined fontSize="small" className="passcode-icon icon-hide" />
        </a>
        <input
          type={isPasswordShown ? 'text' : 'password'}
          id={id}
          {...register(id)}
          placeholder="Enter your password"
          className={`form-control-lg form-control ${isPasswordShown ? 'is-hidden' : 'is-shown'}`}
        />
        {errors && <p className="invalid">{errors.message}</p>}
      </div>
    </div>
  );
};
