import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { isUserAuthenticatedSelector } from '../../app/auth/selectors';
import { useGetCompaniesQuery } from '../../app/company/api';
import { selectCurrentCompany } from '../../app/company/selctors';
import { setCurrentCompany } from '../../app/company/slice';
import { useAppDispatch } from '../../app/hooks';
import { Loader } from '../../components';
import { ROUTES } from '../routes';

export const CompanyOnlyRoute = ({ element }: { element: JSX.Element }) => {
  const dispatch = useAppDispatch();
  const { data, isLoading } = useGetCompaniesQuery();
  const currentCompany = useSelector(selectCurrentCompany);

  const currentCompanyFromServer = data?.find((company) => company.id === currentCompany?.id);

  const isUserAuthenticated = useSelector(isUserAuthenticatedSelector);

  if (data?.length && (!currentCompany || !currentCompanyFromServer)) {
    dispatch(setCurrentCompany(data[0]));
  }

  if (currentCompanyFromServer) {
    dispatch(setCurrentCompany(currentCompanyFromServer));
  }

  if (isLoading) return <Loader />;

  if (!isUserAuthenticated) return <Navigate to={ROUTES.AUTH.LOGIN} />;

  return currentCompany ? element : <Navigate to={ROUTES.COMPANIES} />;
};
