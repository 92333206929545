import { yupResolver } from '@hookform/resolvers/yup';
import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Col, ModalBody } from 'reactstrap';
import Swal from 'sweetalert2';
import * as yup from 'yup';

import { useAddJobMutation } from '../../../../../app/jobs/api';

interface IAddJobModalProps {
  toggle(): void;
}
interface IAddJobSchema {
  url: string;
}

const addJobSchema: yup.SchemaOf<IAddJobSchema> = yup
  .object()
  .shape({
    url: yup.string().trim().required('Link is required'),
  })
  .required();

export const AddJobModal: FC<IAddJobModalProps> = ({ toggle }) => {
  const {
    formState: { errors },
    register,
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(addJobSchema),
    defaultValues: {
      url: '',
    },
  });

  const [addJob, { isLoading, isSuccess, isError, error }] = useAddJobMutation();

  const closeModalHandler = () => {
    reset();
    toggle();
  };

  const sendFormHandler = (values: IAddJobSchema) => {
    if (!values.url) {
      return;
    }

    addJob(values);
  };

  useEffect(() => {
    if (isSuccess) {
      closeModalHandler();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError && error) {
      const {
        data: { details },
      } = error as { data: { details: Record<string, string[]> } };

      const errorsList = Object.values(details).reduce(
        (errorsArray, currentError) => [...errorsArray, ...currentError],
        [],
      );

      Swal.fire({
        title: 'Job creating failed!',
        text: errorsList.join(' '),
        icon: 'error',
      });
    }
  }, [isError, error]);

  return (
    <ModalBody>
      <h5 className="title">Add job</h5>

      <form className="row gy-4 mt-0" onSubmit={handleSubmit(sendFormHandler)}>
        <Col sm="12" className="my-3">
          <div className="form-group">
            <input
              type="text"
              disabled={isLoading}
              placeholder="Enter url of job on Upwork"
              className="form-control"
              {...register('url')}
            />
            {errors.url && <span className="invalid">{errors.url?.message as string}</span>}
          </div>
        </Col>

        <ul className="d-flex justify-content-between">
          <ul className="align-center flex-wrap flex-sm-nowrap gap-2">
            <li>
              <Button color="primary" type="submit" disabled={isLoading}>
                Add job
              </Button>
            </li>
            <li>
              <Button type="reset" disabled={isLoading} onClick={() => closeModalHandler()} color="secondary">
                Cancel
              </Button>
            </li>
          </ul>
        </ul>
      </form>
    </ModalBody>
  );
};
