import { Add } from '@mui/icons-material';
import { Backdrop, CircularProgress } from '@mui/material';
import classNames from 'classnames';
import React, { useEffect, useState, useRef } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';
import { Badge } from 'reactstrap';
import { selectCurrentCompany } from '../../../../../app/company/selctors';
import { useAppSelector } from '../../../../../app/hooks';

import { useGetJobsQuery } from '../../../../../app/jobs/api';

import { DivWithCustomScroll } from '../../../../../components/shared/DivWithCustomScroll';

import { ROUTES } from '../../../../../router/routes';

import { ColumnEnum, Job } from '../../../../../types';
import { Column } from '../../../../../types/types';

import { JobCardList } from '../JobCardList';

type JobColumnProps = {
  column: Column;
  jobs: Record<string, Job[] | []>;
  setJobs: React.Dispatch<React.SetStateAction<Record<string, Job[] | []>>>;
  toggleAddJobModal(): void;
};

export const JobColumn = ({ column, jobs, setJobs, toggleAddJobModal }: JobColumnProps) => {
  const navigate = useNavigate();

  const currentCompany = useAppSelector(selectCurrentCompany);

  const columnEl = useRef<HTMLDivElement>(null) as React.MutableRefObject<HTMLDivElement>;

  const [triggered, setTriggered] = useState(false);
  const [offset, setOffset] = useState(0);
  const { data, isLoading, isSuccess, isError, isFetching } = useGetJobsQuery(
    {
      column: column.id,
      limit: 10,
      offset: offset * 10,
    },
    {
      pollingInterval: 10000,
    },
  );

  const concatJobsHandler = (currentJobs: Job[], newJobs: Job[]): Job[] => {
    const result = [...currentJobs];
    let temp = [...newJobs];

    result.forEach((job, i) => {
      const repeat = temp.find((newJob) => newJob.id === job.id);
      if (repeat) {
        result[i] = repeat;
        temp = temp.filter((newJob) => newJob.id !== repeat.id);
      }
    });

    return [...result, ...temp];
  };

  useEffect(() => {
    if (triggered && !isFetching && !((offset + 1) * 10 > (data?.count || 0))) {
      setOffset(offset + 1);
    }

    if (columnEl?.current) {
      columnEl.current.style.overflowY = 'auto';
    }
  }, [triggered]);

  useEffect(() => {
    if ((isSuccess || isError) && !isFetching) {
      setTriggered(false);
    }
  }, [isSuccess, isError, isFetching]);

  useEffect(() => {
    if (data?.results?.length && !triggered && isSuccess && !isFetching) {
      setJobs((state) => ({
        ...state,
        [column.id]: state[column.id].length ? concatJobsHandler(state[column.id], data.results) : data.results,
      }));
    }
  }, [data, triggered, isSuccess, isFetching]);

  return (
    <React.Fragment>
      <div className="kanban-board">
        <div className={`kanban-board-header kanban-${column.theme}`}>
          <div className="kanban-title-board">
            <div className="kanban-title-content">
              <h6 className="title">{column.text}</h6>
              <Badge color="outline-light" pill className="text-dark">
                {jobs[column.id].length || 0}
              </Badge>
            </div>
          </div>
        </div>

        {column.id === ColumnEnum.Like && (
          <button
            className="kanban-add-task mb-2 btn btn-block"
            onClick={() => (currentCompany?.upwork_connected ? toggleAddJobModal() : navigate(ROUTES.SWIPER))}
          >
            <Add fontSize="inherit" className="me-1" sx={{ marginBottom: '2px' }} />
            <span>Add job</span>
          </button>
        )}

        <Droppable droppableId={column.id}>
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <DivWithCustomScroll
                className={classNames('kanban-drag', { 'kanban-drag-like': column.id === ColumnEnum.Like })}
                ref={columnEl}
                onScroll={() => {
                  if (columnEl?.current?.scrollTop > columnEl?.current?.scrollHeight * 0.7) {
                    if (!triggered && data && data?.count > jobs[column.id].length) {
                      columnEl.current.style.overflowY = 'hidden';
                      setTriggered(true);
                    }
                  }
                }}
              >
                <Backdrop open={isLoading} style={{ position: 'absolute' }}>
                  <CircularProgress />
                </Backdrop>
                <JobCardList displayedJobs={jobs[column.id]} jobs={jobs} setJobs={setJobs} />
                {provided.placeholder}
              </DivWithCustomScroll>
            </div>
          )}
        </Droppable>
      </div>
    </React.Fragment>
  );
};
