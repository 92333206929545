import { FC } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { getNameInitials } from '../../../../../../../helpers';
import { useToggle } from '../../../../../../../hooks';
import { AssignedUser, User } from '../../../../../../../types';
import { UserAvatar } from '../../../../../../Component';

// TODO: ^ Configure absolute paths

interface IJobCardAssigneesProps {
  assignee: AssignedUser;
  assigner: AssignedUser;
}

export const JobCardAssignees: FC<IJobCardAssigneesProps> = ({ assignee, assigner }) => {
  const [isAssigneesDropDownOpen, toggleAssigneesDropDownOpen] = useToggle(false);

  return (
    <Dropdown isOpen={isAssigneesDropDownOpen} toggle={toggleAssigneesDropDownOpen}>
      <DropdownToggle
        tag="a"
        href="#toggle"
        className="dropdown-toggle"
        onClick={(ev) => {
          ev.stopPropagation();
          ev.preventDefault();
        }}
      >
        <div className="user-avatar-group">
          <UserAvatar className="xs" theme="purple" text={getNameInitials(assignee.first_name, assignee.last_name)} />
        </div>
      </DropdownToggle>
      <DropdownMenu end>
        <ul className="link-list-opt no-bdr p-3 g-2">
          <li>
            <div className="user-card" onClick={toggleAssigneesDropDownOpen}>
              <div className="d-flex align-center justify-start mb-1">
                <div style={{ paddingRight: '4px' }}>Assignee:</div>
                <UserAvatar
                  className="sm"
                  theme="purple"
                  text={getNameInitials(assignee.first_name, assignee.last_name)}
                />
                <div className="user-name">
                  <span className="tb-lead">
                    {assignee.first_name} {assignee.last_name}
                  </span>
                </div>
              </div>
            </div>

            {assigner && (
              <div className="d-flex align-center justify-start">
                <div style={{ paddingRight: '4px' }}>Assigner:</div>
                <UserAvatar
                  className="sm"
                  theme="purple"
                  text={getNameInitials(assigner.first_name, assigner.last_name)}
                />
                <div className="user-name">
                  <span className="tb-lead">
                    {assigner.first_name} {assigner.last_name}
                  </span>
                </div>
              </div>
            )}
          </li>
        </ul>
      </DropdownMenu>
    </Dropdown>
  );
};
