import { CircularProgress } from '@mui/material';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { FC, useState } from 'react';
import { Button } from 'reactstrap';
import Swal from 'sweetalert2';
import { baseApi } from '../../app/baseApi';
import { useAppDispatch } from '../../app/hooks';
import { IStripeModalProps } from './StripeModal';

export const StripeForm: FC<IStripeModalProps> = ({ close }) => {
  const dispatch = useAppDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const [isProcessing, setProcessing] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setProcessing(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: window.location.origin,
      },
    });

    if (error) {
      Swal.fire({
        title: 'Plan purchase failed!',
        text: `Plan purchase failed, please try again later. Error: ${error.message}`,
        icon: 'error',
      });
    } else if (paymentIntent && paymentIntent.status === 'succeeded' && paymentIntent) {
      Swal.fire({
        title: 'Plan successfully purchased!',
        text: 'Enjoy your new company pricing plan',
        icon: 'success',
      });

      setTimeout(() => dispatch(baseApi.util.invalidateTags(['CompaniesList'])), 2000);
      close();
    }
    setProcessing(false);
  };
  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />

      <ul className="d-flex align-center flex-wrap flex-sm-nowrap gap-2 mt-4">
        <li>
          <Button color="primary" disabled={isProcessing} type="submit">
            {isProcessing ? <CircularProgress color="inherit" size={20} /> : 'Submit'}
          </Button>
        </li>
        <li>
          <Button type="reset" disabled={isProcessing} onClick={() => close()} color="secondary">
            Cancel
          </Button>
        </li>
      </ul>
    </form>
  );
};
