import { Delete, DriveFileRenameOutline, MoreVert } from '@mui/icons-material';
import React from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { ColumnEnum, Job } from '../../../../../../../types';
import { UUID } from '../../../../../../../types/types/UUID';
import { useDeleteJob, useJobModal } from '../../../../hooks';

type JobCardActionsProps = {
  id: UUID;
  column: ColumnEnum;
  jobs?: Record<string, Job[] | []>;
  setJobs?: React.Dispatch<React.SetStateAction<Record<string, Job[] | []>>>;
};

export const JobCardActions = ({ id, column, jobs, setJobs }: JobCardActionsProps) => {
  const { handleDeleteJob } = useDeleteJob({ id, column, jobs, setJobs });

  const { handleOpenJobModal } = useJobModal();
  return (
    <ul className="kanban-item-meta-list" style={{ minHeight: '20px', minWidth: '25px' }}>
      <UncontrolledDropdown>
        <DropdownToggle
          tag="a"
          href="toggle"
          onClick={(ev) => {
            ev.stopPropagation();
            ev.preventDefault();
          }}
          className="dropdown-toggle btn btn-xs btn-icon btn-trigger me-n1"
        >
          <MoreVert fontSize="small" />
        </DropdownToggle>
        <DropdownMenu end>
          <ul className="link-list-opt no-bdr">
            <li>
              <DropdownItem
                tag="button"
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenJobModal(id);
                }}
              >
                <DriveFileRenameOutline fontSize="inherit" className="me-1" sx={{ marginBottom: '2px' }} />
                <span>Open Job</span>
              </DropdownItem>
            </li>
            <li>
              <DropdownItem
                tag="button"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteJob();
                }}
              >
                <Delete fontSize="inherit" className="me-1" sx={{ marginBottom: '2px' }} />
                <span>Delete Job</span>
              </DropdownItem>
            </li>
          </ul>
        </DropdownMenu>
      </UncontrolledDropdown>
    </ul>
  );
};
