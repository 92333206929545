import { Company, RssLinkInput, RssLink, ResponseWithPagination, PaginationRequestParams, UUID } from '../../types';
import { baseApi } from '../baseApi';
import { API_ENDPOINTS } from '../endpoints';

export const companyApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getCompanies: build.query<Company[], void>({
      query: () => ({
        url: API_ENDPOINTS.COMPANIES.COMPANIES,
      }),
      providesTags: () => ['CompaniesList'],
    }),
    getCompanyById: build.query<Company, { id: string }>({
      query: ({ id }) => ({
        url: API_ENDPOINTS.COMPANIES.ID(id),
      }),
      providesTags: (_, __, arg) => [
        {
          type: 'CompanyById',
          id: arg.id,
        },
      ],
    }),
    getCompanyRssLinks: build.query<ResponseWithPagination<RssLink> | RssLink[], PaginationRequestParams>({
      query: (params) => ({
        url: API_ENDPOINTS.COMPANIES.LINKS,
        params,
      }),
      providesTags: ['CompanyLinks'],
    }),
    createCompany: build.mutation<Company, FormData>({
      query: (body) => ({
        url: API_ENDPOINTS.COMPANIES.COMPANIES,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['CompaniesList'],
    }),
    createRssLink: build.mutation<RssLink, RssLinkInput>({
      query: (body) => ({
        url: API_ENDPOINTS.COMPANIES.LINKS,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['CompanyLinks'],
    }),
    updateCompanyFiles: build.mutation<Company, { id: string; body: FormData }>({
      query: ({ id, body }) => ({
        url: API_ENDPOINTS.COMPANIES.ID(id),
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (_, __, args) => [{ type: 'CompanyById', id: args.id }, 'CompaniesList'],
    }),
    updateCompany: build.mutation<Company, Pick<Company, 'id'> & Partial<Company>>({
      query: ({ id, ...body }) => ({
        url: API_ENDPOINTS.COMPANIES.ID(id),
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (_, __, args) => [
        { type: 'CompanyById', id: args.id },
        'CompaniesList',
        'DeadlineDate',
        'JobById',
      ],
    }),
    updateRssLink: build.mutation<RssLink, RssLinkInput & Pick<RssLink, 'id'>>({
      query: ({ id, ...body }) => ({
        url: API_ENDPOINTS.COMPANIES.LINKS_ID(id),
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['CompanyLinks'],
    }),
    deleteRSSLink: build.mutation<void, { id: UUID }>({
      query: ({ id }) => ({
        url: API_ENDPOINTS.COMPANIES.LINKS_ID(id),
        method: 'DELETE',
      }),
      invalidatesTags: ['CompanyLinks'],
    }),
  }),
});

export const {
  useGetCompaniesQuery,
  useLazyGetCompaniesQuery,
  useGetCompanyByIdQuery,
  useLazyGetCompanyByIdQuery,
  useGetCompanyRssLinksQuery,
  useCreateCompanyMutation,
  useUpdateCompanyMutation,
  useUpdateCompanyFilesMutation,
  useCreateRssLinkMutation,
  useUpdateRssLinkMutation,
  useDeleteRSSLinkMutation,
} = companyApi;
