import { useMemo } from 'react';
import { useGetUsersQuery } from '../../../../../../app/user/api';
import { SelectOption } from '../../../../../../types';

export const useAssigneeOptions = () => {
  const { data: users, isSuccess: isGetUsersSuccess } = useGetUsersQuery({ isActive: true });

  const assigneeOptions = useMemo<SelectOption[]>(
    () =>
      isGetUsersSuccess && users
        ? [
            { label: 'None', value: null },
            ...users.map((user) => ({
              label: `${user.first_name} ${user.last_name}`,
              value: user.id,
            })),
          ]
        : [],
    [isGetUsersSuccess, users],
  );

  return { assigneeOptions };
};
