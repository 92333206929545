import { Delete } from '@mui/icons-material';
import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Button, Modal } from 'reactstrap';

import { selectCurrentCompany } from '../../../app/company/selctors';
import { useAppSelector } from '../../../app/hooks';
import { useGetCompanyStaffQuery } from '../../../app/user/api';
import { userProfileSelector } from '../../../app/user/selectors';

import { useToggle } from '../../../hooks';
import { Colors } from '../../../styles/Colors';
import { useCustomTableStyle } from '../../../styles/hooks';

import { RoleEnum, User } from '../../../types';

import { useDeleteOperator } from './hooks';

import { InviteOperatorForm } from './InviteOperatorForm';

export const OperatorsTab = () => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isOpenInviteModal, toggleInviteModalOpen] = useToggle(false);

  const currentCompany = useAppSelector(selectCurrentCompany);
  const currentUser = useAppSelector(userProfileSelector);

  const { customStyles } = useCustomTableStyle();

  const { data, isLoading, isFetching } = useGetCompanyStaffQuery({
    limit: rowsPerPage,
    offset: (page - 1) * rowsPerPage,
  });

  useEffect(() => {
    if (currentCompany) {
      setPage(1);
      setRowsPerPage(10);
    }
  }, [currentCompany]);

  const { handleDeleteOperator } = useDeleteOperator();

  const columns = [
    {
      name: 'Name',
      selector: (operator: User) => `${operator.last_name} ${operator.first_name}`,
      maxWidth: '50%',
    },
    {
      name: 'Email',
      selector: (operator: User) => operator.email,
      maxWidth: '25%',
    },
    {
      name: 'Role',
      maxWidth: '10%',
      minWidth: '90px',
      cell: (operator: User) => {
        return (
          <Typography fontSize={13} fontWeight={operator.role === RoleEnum.Admin ? 500 : 400}>
            {operator.role}
          </Typography>
        );
      },
    },
    {
      name: 'Status',
      maxWidth: '10%',
      minWidth: '90px',
      cell: (operator: User) => {
        return (
          <Typography fontSize={13} fontWeight={500} color={operator.is_active ? Colors.green.light : Colors.red.light}>
            {operator.is_active ? 'Active' : 'Inactive'}
          </Typography>
        );
      },
    },
    {
      maxWidth: '5%',
      minWidth: '60px',
      cell: (operator: User) => {
        if (currentUser?.id === operator.id || currentUser?.role !== RoleEnum.Admin || operator.is_owner) {
          return '';
        }

        return (
          <Button
            color="danger"
            size="sm"
            className="btn-icon btn-white btn-dim"
            onClick={() => handleDeleteOperator(operator)}
          >
            <Delete sx={{ minWidth: '30px', height: '24px', padding: '0 6px' }} />
          </Button>
        );
      },
      center: true,
    },
  ];

  return (
    <div>
      <div className="justify-end">
        {currentUser?.role === RoleEnum.Admin && (
          <Button color="primary" size="md" disabled={isLoading} onClick={toggleInviteModalOpen}>
            Invite operator
          </Button>
        )}
      </div>

      <div className="mt-2">
        <DataTable
          columns={columns}
          data={data?.results || []}
          progressPending={isLoading || isFetching}
          pagination
          paginationServer
          paginationTotalRows={data?.count || 0}
          onChangeRowsPerPage={setRowsPerPage}
          onChangePage={setPage}
          customStyles={customStyles}
        />
      </div>

      <Modal size="md" isOpen={isOpenInviteModal} toggle={toggleInviteModalOpen}>
        <InviteOperatorForm close={toggleInviteModalOpen} />
      </Modal>
    </div>
  );
};
