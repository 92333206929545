import { Button, Col } from 'reactstrap';
import { ColumnEnum, Job } from '../../../../../../../types';
import { UUID } from '../../../../../../../types/types/UUID';
import { useDeleteJob, useJobModal } from '../../../../hooks';

type JobModalActionsProps = {
  id: UUID;
  column: ColumnEnum;
  isLoading?: boolean;
  isArchive?: boolean;
  jobs?: Record<string, Job[] | []>;
  setJobs?: React.Dispatch<React.SetStateAction<Record<string, Job[] | []>>>;
};
export const JobModalActions = ({ isLoading, id, column, jobs, setJobs, isArchive }: JobModalActionsProps) => {
  const { handleCloseJobModal } = useJobModal();
  const { handleDeleteJob } = useDeleteJob({
    id,
    column,
    jobs,
    setJobs,
  });

  return (
    <Col className="col-12">
      <ul className="d-flex align-center justify-content-between mt-3">
        <ul className="d-flex align-center flex-wrap flex-sm-nowrap gap-2">
          {!isArchive && (
            <li>
              <Button color="primary" size="md" type="submit" disabled={isLoading}>
                Update Job
              </Button>
            </li>
          )}

          <li>
            <Button color="secondary" type="button" onClick={handleCloseJobModal} disabled={isLoading}>
              Cancel
            </Button>
          </li>
        </ul>
        <ul>
          <li>
            {!isLoading && !isArchive && (
              <Button color="danger" type="button" disabled={isLoading} onClick={handleDeleteJob}>
                Delete Job
              </Button>
            )}
          </li>
        </ul>
      </ul>
    </Col>
  );
};
