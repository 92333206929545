import { CheckCircleOutlined, ErrorOutlineOutlined } from '@mui/icons-material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Form, Spinner } from 'reactstrap';
import { PasswordInput } from '../../../components/PasswordInput';
import { ROUTES } from '../../../router/routes';
import { Button } from '../../../theme-components/Component';
import AuthFormCard from '../layout/AuthFormCard';
import { useProfileActivation, useLoginForm } from './hooks';

// TODO: JS to TS, extract inputs, alerts into separate custom components

export const Login = () => {
  const [feedbackMessage, setFeedbackMessage] = useState({});
  const { isLoadingProfileActivation } = useProfileActivation({ setFeedbackMessage });
  const { register, handleSubmit, errors, isLoginLoading, handleFormSubmit } = useLoginForm({
    feedbackMessage,
    setFeedbackMessage,
  });
  const loading = isLoginLoading || isLoadingProfileActivation;

  return (
    <AuthFormCard headTitle="Login" title="Sign-In" description="Access website using your email and passcode.">
      <>
        {feedbackMessage?.type === 'error' && (
          <div className="mb-3">
            <Alert color="danger" className="alert-icon">
              <ErrorOutlineOutlined fontSize="inherit" className="me-1 icon" /> {feedbackMessage.value}
            </Alert>
          </div>
        )}
        {feedbackMessage?.type === 'success' && (
          <div className="mb-3">
            <Alert className="alert-icon" color="success">
              <CheckCircleOutlined fontSize="inherit" className="me-1 icon" sx={{ marginBottom: '5px' }} />
              {feedbackMessage.value}
            </Alert>
          </div>
        )}

        <Form className="is-alter" onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="form-group">
            <div className="form-label-group">
              <label className="form-label" htmlFor="email">
                Email
              </label>
            </div>
            <div className="form-control-wrap">
              <input
                type="text"
                id="email"
                name="email"
                autoComplete={'email'}
                {...register('email')}
                placeholder="Enter your email address"
                className="form-control-lg form-control"
              />
              {errors?.email && <span className="invalid">{errors.email.message}</span>}
            </div>
          </div>
          <PasswordInput
            register={register}
            errors={errors.password}
            label="Password"
            id="password"
            withForgotPasswordLink
          />
          <div className="form-group">
            <Button size="lg" className="btn-block" type="submit" color="primary">
              {loading ? <Spinner size="sm" color="light" /> : 'Sign in'}
            </Button>
          </div>
        </Form>
        <div className="form-note-s2 text-center pt-4">
          {' '}
          New on our platform? <Link to={ROUTES.AUTH.REGISTER}>Create an account</Link>
        </div>
      </>
    </AuthFormCard>
  );
};
