import { useMemo } from 'react';
import { selectCurrentCompany } from '../../../../app/company/selctors';
import { useAppSelector } from '../../../../app/hooks';
import { getDeadlineDate } from '../../../../helpers';
import { ColumnEnum } from './../../../../types/enums/ColumnEnum';

type UseDeadlineDateParams = {
  column: ColumnEnum;
  deadlineDate: string;
};

export const useDeadlineDate = ({ column, deadlineDate }: UseDeadlineDateParams) => {
  const currentCompany = useAppSelector(selectCurrentCompany);

  const expireTime: Record<string, number> = {
    [ColumnEnum.Like]: currentCompany?.deadline_like || 0,
    [ColumnEnum.Sent]: currentCompany?.deadline_sent || 0,
    [ColumnEnum.AnswerReceived]: currentCompany?.deadline_answer_received || 0,
    [ColumnEnum.CallMade]: currentCompany?.deadline_call_made || 0,
  };
  const { deadlineLabel, deadlineState } = useMemo(
    () => getDeadlineDate(deadlineDate, expireTime[column]),
    [deadlineDate, currentCompany],
  );

  // const { deadlineLabel, deadlineState } = getDeadlineDate(deadlineDate, expireTime[column]);

  return { deadlineLabel, deadlineState };
};
