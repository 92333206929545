import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { Spinner, Form } from 'reactstrap';
import * as yup from 'yup';
import { useForgotPasswordMutation } from '../../app/auth/api';
import { ROUTES } from '../../router/routes';
import { Button } from '../../theme-components/Component';
import AuthFormCard from './layout/AuthFormCard';

const forgotPasswordSchema = yup
  .object()
  .shape({
    email: yup.string().trim().email('Please provide a valid email address').required('Email is required'),
  })
  .required();

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [forgotPassword, { isLoading: loading }] = useForgotPasswordMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(forgotPasswordSchema),
    defaultValues: {
      email: '',
    },
  });

  const onFormSubmit = async (formData) => {
    await forgotPassword(formData);
    navigate(ROUTES.AUTH.SUCCESS, {
      state: {
        type: 'forgot-password',
      },
    });
  };

  return (
    <AuthFormCard
      headTitle="Forgot-Password"
      title="Forgot password"
      description="If you forgot your password, well, then we’ll email you instructions to reset your password."
    >
      <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
        <div className="form-group">
          <div className="form-label-group">
            <label className="form-label" htmlFor="email">
              Email
            </label>
          </div>
          <div className="form-control-wrap">
            <input
              type="text"
              id="email"
              name="email"
              autoComplete={'email'}
              {...register('email')}
              placeholder="Enter your email address"
              className="form-control-lg form-control"
            />
            {errors?.email && <span className="invalid">{errors.email.message}</span>}
          </div>
        </div>
        <div className="form-group">
          <Button color="primary" size="lg" className="btn-block" type="submit">
            {loading ? <Spinner size="sm" color="light" /> : 'Send Reset Link'}
          </Button>
        </div>
      </Form>
      <div className="form-note-s2 text-center pt-4">
        <Link to={ROUTES.AUTH.LOGIN}>
          <strong>Return to login</strong>
        </Link>
      </div>
    </AuthFormCard>
  );
};
export default ForgotPassword;
