import { differenceInDays, differenceInHours, differenceInMinutes } from 'date-fns';

export const getDeadlineDate = (date: string, expireTime: number) => {
  const dueDate = new Date(date);

  return {
    deadlineLabel: getDeadlineLabel(dueDate),
    deadlineState: getDeadlineState(dueDate, expireTime),
  };
};

const getDeadlineState = (dueDate: Date, expireTime: number) => {
  const differenceBetweenDatesInHours = differenceInHours(dueDate, new Date());
  const timeLeftInPercentage = Math.floor((differenceBetweenDatesInHours * 100) / expireTime);

  if (timeLeftInPercentage < 10) {
    return {
      type: 'danger',
      isExpired: timeLeftInPercentage <= 0,
    };
  }

  if (timeLeftInPercentage < 60) {
    return {
      type: 'warning',
      isExpired: false,
    };
  }

  return {
    type: 'date',
    isExpired: false,
  };
};

const getDeadlineLabel = (dueDate: Date) => {
  const differenceBetweenDates = differenceInDays(dueDate, new Date());

  if (differenceBetweenDates > 0) {
    return `${differenceBetweenDates}d Due`;
  }
  if (differenceBetweenDates === 0) {
    return getDueDateInHours(dueDate);
  }
  return `${Math.abs(differenceBetweenDates)}d Expired`;
};

const getDueDateInHours = (dueDate: Date) => {
  const differenceBetweenHours = differenceInHours(dueDate, new Date());
  const differenceBetweenMinutes = differenceInMinutes(dueDate, new Date());

  if (differenceBetweenHours > 0) {
    return `${differenceBetweenHours}h Due`;
  }

  if (differenceBetweenHours === 0) {
    return differenceBetweenMinutes > 0 ? `Expires in less than 1h` : 'Expired less 1h ago';
  }

  return `${Math.abs(differenceBetweenHours)}h Expired`;
};
