import React from 'react';
import { Bar } from 'react-chartjs-2';
import { useGetStatusChangesByUserReportQuery } from '../../../app/stats/api';
import { BlockTitle } from '../../../theme-components/Component';
import { cutString } from '../../../utils/CutString';
import { Loader } from '../../Loader';

export const JobsStatusChangeReport = () => {
  const { data, isLoading, isError } = useGetStatusChangesByUserReportQuery();

  const preparedData = {
    labels: data?.by_user?.map(({ first_name, last_name }) => cutString(20, `${first_name} ${last_name}`)),
    datasets: [
      {
        label: 'Sent',
        data: data?.by_user?.map(({ sent }) => sent),
        backgroundColor: '#7f8dff',
      },
      {
        label: 'Answer received',
        data: data?.by_user?.map(({ answer }) => answer),
        backgroundColor: '#f4bd0e',
      },
      {
        label: 'Contract',
        data: data?.by_user?.map(({ contract }) => contract),
        backgroundColor: '#1ee0ac',
      },
    ],
  };

  let content;

  if (data && preparedData) {
    content = data.by_user.some(({ sent, answer, contract }) => !!sent || !!answer || !!contract) ? (
      <Bar
        data={preparedData}
        options={{
          legend: {
            display: false,
          },
          tooltips: {
            enabled: true,
            backgroundColor: '#eff6ff',
            titleFontSize: 13,
            titleFontColor: '#6783b8',
            titleMarginBottom: 6,
            bodyFontColor: '#9eaecf',
            bodyFontSize: 12,
            bodySpacing: 4,
            yPadding: 10,
            xPadding: 10,
            footerMarginTop: 0,
            displayColors: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  reverse: false,
                  stepSize: 1,
                  min: 0,
                },
              },
            ],
          },
        }}
      />
    ) : (
      <div className="text-center my-2 mx-4">Sorry, there is not enough data for current company to report</div>
    );
  }

  if (isError) {
    content = (
      <div className="text-center mt-2 mb-2">The error occurred while loading report. Please refresh page later</div>
    );
  }

  if (isLoading) {
    content = (
      <div className="mt-2 mb-2">
        <Loader className="h-100" />;
      </div>
    );
  }

  return (
    <div className="card card-bordered card-preview mt-2">
      <div className="card-inner">
        <BlockTitle>Jobs status change report</BlockTitle>
      </div>

      {content}
    </div>
  );
};
