import React from 'react';
import { PricingInner } from '../../components';
import { Layout } from '../../components/Layout';
import Head from '../../layout/head/Head';
import { PricingContainer } from './Pricing.style';

export const Pricing = () => {
  return (
    <Layout>
      <Head title="Pricing" />
      <PricingContainer size={'middle'} className="w-80 m-auto">
        <PricingInner />
      </PricingContainer>
    </Layout>
  );
};
