import { ErrorOutlineOutlined } from '@mui/icons-material';
import { Alert } from 'reactstrap';
import { useGetCommentsQuery } from '../../../../../../../../../app/jobs/api';
import { Loader, PaginationSettings } from '../../../../../../../../../components';
import { getNameInitials } from '../../../../../../../../../helpers';
import { formatDate } from '../../../../../../../../../helpers/date';
import { usePagination } from '../../../../../../../../../hooks';
import { UUID } from '../../../../../../../../../types/types/UUID';
import { PaginationComponent, UserAvatar } from '../../../../../../../../Component';

export const JobCommentsList = ({ id }: { id: UUID }) => {
  const {
    itemsPerPage: commentsPerPage,
    setItemsPerPage: setCommentsPerPage,
    currentPage,
    paginate,
  } = usePagination({
    initialItemsCountPerPage: 10,
  });

  const {
    data: comments,
    isLoading,
    isSuccess,
  } = useGetCommentsQuery({
    limit: commentsPerPage,
    offset: (currentPage - 1) * commentsPerPage,
    id,
  });

  if (isLoading) return <Loader />;

  if (!isSuccess) {
    return (
      <Alert color="danger" className="alert-icon">
        <ErrorOutlineOutlined fontSize="inherit" className="me-1 icon" />
        Failed to load comments.
      </Alert>
    );
  }

  return (
    <>
      <ul className="nk-support">
        {comments.results.map(({ user: { first_name, last_name }, id, comment, created_at }) => (
          <li key={id} className="nk-support-item">
            <UserAvatar text={getNameInitials(first_name, last_name)} />
            <div className="nk-support-content">
              <div className="title">
                <span>
                  {first_name} {last_name}
                </span>
              </div>
              <p style={{ whiteSpace: 'pre-wrap' }}>{comment}</p>
              <span className="time">
                {formatDate({
                  date: new Date(created_at),
                  dateFormat: 'MMM dd, yyyy, H:mm',
                })}
              </span>
            </div>
          </li>
        ))}
      </ul>
      {comments.count > 0 && (
        <div className="card-inner d-flex flex-row ">
          <PaginationSettings
            itemsCountOptions={[10, 20, 30]}
            itemsPerPage={commentsPerPage}
            setItemsPerPage={setCommentsPerPage}
          />
          <PaginationComponent
            itemPerPage={commentsPerPage}
            totalItems={comments.count}
            paginate={paginate}
            currentPage={currentPage}
          />
        </div>
      )}
    </>
  );
};
