import { baseApi } from '../baseApi';
import { API_ENDPOINTS } from '../endpoints';
import { Job } from './../../types';

export const openApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    generateLetter: build.query<{ status: number }, Pick<Job, 'id'>>({
      query: ({ id }) => ({
        url: API_ENDPOINTS.OPENAI.LETTER(id),
      }),
    }),
  }),
});

export const { useLazyGenerateLetterQuery } = openApi;
